@import '../../../variables';

.search-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 52px;
  max-width: 402px;
  width: 100%;
  background-color: $bg_clr2;
  border-radius: 12px;
  padding-left: 16px;
  padding-right: 8px;
  margin-left: 40px;

  &.active-search {
    background-color: $bg_clr0;
    border: 2px solid $brdr_clr3;

    .default-label .input-container input {
      opacity: 1;
      color: $txt_clr4;
      background-color: $bg_clr0;
    }
  }

  .default-label {
    width: 100%;

    &:after {
      background-color: transparent;
    }

    .input-container {
      display: flex;
      align-items: center;
      width: 100%;

      input {
        width: 100%;
        @include setFont('Montserrat', 400, 0.9375rem, 1.0625rem);
        background-color: $bg_clr2;
        color: $txt_clr3;
        opacity: 0.5;

        //&:focus {
        //  opacity: 1;
        //  color: $txt_clr4;
        //  background-color: $bg_clr0;
        //}
      }
    }
  }

  > svg,
  .clear-icon {
    cursor: pointer;
  }
}
