@import '../../../variables';

.hide-placeholder {
  .input-container {
    input {
      &:focus {
        text-align: left;
        &::placeholder {
          opacity: 0;
        }
      }
    }
  }
}

.default-label {
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: rgba(117, 133, 150, 0.25);
  }

  &.filled-in.show-status-border.error-state {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97);

    &:after {
      background-color: red;
    }
  }

  &.password-field {
    display: flex;
    align-items: center;
    background: $bg_clr2;
    border-radius: 8px;
    height: 64px;
    width: 100%;

    .input-container {
      width: 100%;
      padding: 19px 16px;

      input {
        @include setFont('IBM Plex Sans', 400, 1.0625rem, 1.5625rem);
        background-color: $bg_clr2;
        color: $txt_clr4;
        opacity: 0.5;
      }
    }
  }

  &.filled-in {
    &.show-status-border {
      &:after {
        background-color: $bg_clr6;
      }
    }
  }

  .default-input {
    width: 100%;
  }

  .default-input:focus {
    background-color: white;
  }
}
