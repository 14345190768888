@import '../../../variables';

.balance-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  white-space: pre-wrap;
  @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
  color: $txt_clr3;
  .count-token {
    padding-right: 4px;
  }

  .count-token .count-row.digits {
    color: $txt_clr3;
  }
}
