@import '../../../variables';

.wallet-untb {
  .wallet-untb-block {
    .msg-printer-view {
      padding-right: 30px;
      padding-left: 30px;
      text-align: center;
      margin-bottom: 10px;
    }

    .wallet-content {
      padding: 0;

      .top-content {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        border-bottom: 1px solid $brdr_clr1;
        padding: 25px 30px 13px 30px;
      }

      .default-label.filled-in {
        padding-top: 9px;
        padding-bottom: 9px;
      }

      .bottom-content {
        display: flex;
        flex-direction: row;
        padding: 28px 30px;

        .balance-row {
          &.empty-margin {
            margin-bottom: 0;
          }

          .count-token,
          .token-name {
            text-decoration: underline;
          }
        }

        .stake-block {
          min-width: 328px;

          .balance-wrapper {
            display: flex;
            flex-direction: column;
            margin-bottom: 5px;
            min-height: 66px;
          }

          &.unstake {
            margin-left: 66px;

            .untb-input {
              margin-bottom: 22px;
            }
          }

          .with-bg {
            min-height: 63px;
            width: 100%;
          }

          .untb-input {
            margin-bottom: 11px;
          }

          .input-row-block.input-with-bg .input-block .balance-row {
            margin-bottom: initial;
          }
        }
      }
    }
  }

  @media only screen and (max-width: $mobileScreen) {
    .wallet-untb-block .wallet-content .top-content {
      flex-direction: column;
      width: 100%;
      border-bottom: initial;
      padding: 15px 17px;

      .token-info-block {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid $brdr_clr1;
        padding-bottom: 8px;
        padding-top: 5px;

        &:last-of-type {
          border: initial;
          padding-bottom: 0;
        }

        .info-block {
          @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
        }
      }
    }
    .wallet-untb-block .wallet-content .bottom-content .stake-block.unstake.hide-mobile-form {
      display: none;
    }
    .wallet-untb-block .wallet-content .bottom-content .stake-block.hide-mobile-form {
      display: none;
    }
    .wallet-untb-block .wallet-content .bottom-content {
      padding-left: 17px;
      padding-right: 17px;
      padding-top: 40px;

      .stake-block {
        width: 100%;

        &.unstake {
          margin-left: 0;
        }

        .title-page {
          display: none;
        }
        .balance-wrapper {
          min-height: initial;
          .balance-row {
            margin-bottom: 7px;
          }
        }

        &.unstake.hide-mobile-form {
          display: none;
        }

        &.hide-mobile-form {
          display: none;
        }
      }
    }
  }
}
