@import '../../../variables';

.progress-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 0;
  margin-bottom: 5px;

  .progress-line {
    background-color: #0054a2;
    border-radius: 0 10px 10px 0;
    height: 12px;
    transition: width 1s;
    -webkit-transition: width 1s;
  }

  .lines-counts {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .count-line {
      @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
      margin-left: 12px;
    }

    .amount-line {
      @include setFont('IBM Plex Sans', 400, 0.8125rem, 1.1875rem);
      color: $txt_clr3;
    }
  }
}
