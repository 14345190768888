@import '../../../variables';

@media only screen and (min-width: $desktopScreen) {
  .wallet-dividends .wallet-content .top-content .with-bg.red-bg:hover {
    background-color: #e84040;
    transition: background-color 200ms;
    -webkit-transition: background-color 200ms;
  }
  .wallet-dividends .wallet-content .top-content .with-bg.green-bg:hover {
    background-color: #4a9953;
    transition: background-color 200ms;
    -webkit-transition: background-color 200ms;
  }
  .wallet-dividends .wallet-content .center-content .with-bg.blue:hover {
    background-color: #1a4ec5;
    transition: background-color 200ms;
    -webkit-transition: background-color 200ms;
  }
}

.wallet-dividends {
  .wallet-content {
    padding: 0;

    .top-content {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid $brdr_clr1;
      padding: 30px 30px 35px;

      .available-title {
        @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
        color: $txt_clr3;
      }

      .count-token {
        @include setFont('IBM Plex Sans', 500, 1.75rem, 2.9375rem);
        color: $txt_clr4;
      }

      .available-usdu {
        padding-bottom: 34px;
      }
    }

    .click-btn.with-bg {
      min-height: 64px;
      max-width: 173px;
      width: 100%;
      margin-left: 12px;
      text-transform: initial;
      @include setFont('IBM Plex Sans', 500, 1.0625rem, 1.5625rem);
      transition: background-color 200ms;
      -webkit-transition: background-color 200ms;

      &.red-bg {
        background-color: #eb5757;
      }

      &.green-bg {
        background-color: $bg_clr10;
      }
    }

    .input-with-btn {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;

      &.mint {
        margin-bottom: 25px;
      }
    }

    .input-row-block {
      display: flex;
      align-items: center;
      flex-direction: row;

      .label-block {
        display: flex;
        flex-direction: column;
        margin-right: 30px;
        min-width: 140px;

        .title-row {
          @include setFont('IBM Plex Sans', 500, 1.0625rem, 1.5625rem);
          margin-bottom: 4px;
        }

        .subtitle-row {
          @include setFont('IBM Plex Sans', 400, 0.8125rem, 1.1875rem);
          color: $txt_clr3;
        }
      }

      .input-block {
        width: 100%;

        .default-label {
          display: flex;
          align-items: center;
          flex-direction: row-reverse;
          justify-content: space-between;

          .label-input {
            @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
            color: $txt_clr3;
            opacity: 0.7;
          }
        }
      }
    }

    .center-content {
      padding: 26px 26px 40px;
      border-bottom: 1px solid $brdr_clr1;

      .input-with-btn {
        margin-top: 30px;

        .input-row-block .input-block .default-label {
          flex-direction: initial;
        }
      }
    }

    .bottom-content {
      padding: 26px;

      .table-wrapper {
        margin-top: 30px;

        th:nth-child(1),
        td:nth-child(1) {
          width: 5% !important;
        }

        .active-row {
          background-color: #d3f265;
        }
      }

      .header-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        @include setFont('IBM Plex Sans', 500, 1.0625rem, 1.5625rem);
        color: $txt_clr4;
        margin-bottom: 25px;
        margin-top: 26px;

        .slice-block {
          display: flex;
          align-items: center;
          flex-direction: row;
        }

        .slice-title {
          color: $txt_clr5;
          cursor: pointer;
          transition: color 200ms;
          -webkit-transition: color 200ms;
          margin-right: 27px;

          &:hover {
            color: #1a4ec5;
            transition: color 200ms;
            -webkit-transition: color 200ms;
          }
        }

        .select-year {
          max-height: 36px;
          min-width: 68px;

          .react-select__control {
            border: 1px solid $brdr_clr3;
            border-radius: 9px;
            cursor: pointer;
            transition: border-color 200ms;
            -webkit-transition: border-color 200ms;

            &:focus {
              outline: initial !important;
              box-shadow: initial;
              --swiper-theme-color: none;
            }

            &:hover {
              border-color: #bfc6cf;
              transition: border-color 200ms;
              -webkit-transition: border-color 200ms;
            }

            .react-select__value-container {
              min-width: 42px;

              .select-row {
                @include setFont('IBM Plex Sans', 500, 0.8125rem, 1.1875rem);
                color: $txt_clr3;
              }
            }

            .react-select__indicator-separator {
              display: none;
            }

            .react-select__indicator {
              color: $txt_clr3;

              svg {
                width: 16px;
                height: 16px;
              }
            }
          }

          &:focus {
            outline: none;
          }

          .react-select__menu {
            .react-select__option {
              cursor: pointer;

              div {
                @include setFont('IBM Plex Sans', 400, 0.8125rem, 1.1875rem);
                color: $txt_clr3;
                transition: background-color 200ms;
                -webkit-transition: background-color 200ms;

                &:hover {
                  transition: background-color 200ms;
                  -webkit-transition: background-color 200ms;
                }
              }
            }
          }
        }
      }

      .timeframe-block {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 45px;
        border: 1px solid $brdr_clr3;

        .timeframe-item {
          display: flex;
          flex-direction: column;
          min-width: 184px;
          max-width: 184px;
          min-height: 174px;
          width: 100%;
          padding: 11px;
          cursor: pointer;
          border: 1px solid $brdr_clr3;
          background-color: $bg_clr2;
          .date-row {
            p {
              text-align: left;
            }
          }

          &.used {
            cursor: not-allowed;
          }

          &.with-zero {
            background-color: $bg_clr0;
          }

          &.selected {
            background-color: $bg_clr13;
            border: 1px solid #3066e3;

            .date-row {
              color: $txt_clr3;
            }

            .count-row {
              color: $txt_clr4;
            }
          }

          &.coming {
            background: $bg_clr0 repeating-linear-gradient(-45deg, $bg_clr0 7%, $bg_clr2 10%, $bg_clr2 7%);

            .date-row {
              color: $txt_clr3;
            }
          }

          &:hover {
            filter: drop-shadow(0px 4px 4px rgba(119, 119, 119, 0.05)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.08));
          }

          .date-row {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            @include setFont('IBM Plex Sans', 400, 0.875rem, 1.3125rem);
            letter-spacing: -0.02em;
            margin-bottom: 3px;
            color: $txt_clr4;
            white-space: pre-wrap;
          }

          .count-row {
            @include setFont('IBM Plex Sans', 400, 0.8125rem, 1.1875rem);
            color: $txt_clr3;
          }

          .coming-row {
            @include setFont('IBM Plex Sans', 400, 0.8125rem, 1.1875rem);
            color: $txt_clr3;
            opacity: 0.75;
          }
        }
      }

      .distribution-block {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-bottom: 25px;

        .empty-amounts-block {
          display: flex;
          flex-direction: row;
          align-items: center;

          svg {
            min-width: 32px;
            min-height: 30px;
            margin-right: 18px;
          }

          .empty-amount-txt {
            display: flex;
            flex-direction: column;
            @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);

            .empty-title {
              font-weight: 500;
              color: #eb5757;
            }

            .empty-subtitle {
              color: $txt_clr3;
            }
          }
        }

        .distribution-title {
          @include setFont('IBM Plex Sans', 500, 1.0625rem, 1.5625rem);
          margin-bottom: 4px;
          min-width: 140px;
          max-width: 140px;
          margin-right: 30px;
        }

        .select-amount {
          width: 100%;

          &:focus {
            outline: none;
          }

          .react-select__control {
            height: 64px;
            background-color: $bg_clr2;
            border-radius: 8px;
            color: $txt_clr4;
            @include setFont('IBM Plex Sans', 400, 1.0625rem, 1.5625rem);
            //padding: 19px 16px;
            border: none;
            position: relative;
            overflow: hidden;

            &:after {
              content: '';
              display: block;
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: rgba(117, 133, 150, 0.25);
            }

            .react-select__placeholder {
              color: $txt_clr4;
              opacity: 0.5;
            }

            .react-select__value-container {
              .select-row {
                @include setFont('IBM Plex Sans', 400, 1.0625rem, 1.1875rem);
                color: $txt_clr4;
              }
            }
          }

          .react-select__menu {
            font-weight: 400;

            .react-select__option {
              cursor: pointer;
              height: 64px;
              display: flex;
              align-items: center;
              @include setFont('IBM Plex Sans', 400, 1.0625rem, 1.5625rem);
              color: $txt_clr4;
              border-bottom: 1px solid $brdr_clr3;

              div {
                transition: background-color 200ms;
                -webkit-transition: background-color 200ms;

                &:hover {
                  transition: background-color 200ms;
                  -webkit-transition: background-color 200ms;
                }
              }
            }
          }

          .react-select__indicator-separator {
            display: none;
          }

          .react-select__indicator {
            color: $txt_clr4;
          }
        }
      }

      .input-row-block.input-with-bg:not(:last-of-type) {
        margin-bottom: 25px;
      }

      .btn-wrapper {
        margin-top: 40px;

        .with-bg.delete {
          min-height: 45px;
          max-width: 200px;
          margin: -30px 0 0 auto;
        }

        .with-bg.blue {
          min-height: 63px;
          max-width: 558px;
          margin-left: auto;
        }
      }
    }
  }

  .control-btn.tab-item.active-btn {
    background: $bg_clr6;
    box-shadow: 0px 32px 32px rgba(52, 94, 169, 0.15), 0px 16px 16px rgba(52, 94, 169, 0.15), 0px 8px 8px rgba(52, 94, 169, 0.15),
      0px 4px 4px rgba(52, 94, 169, 0.15);
  }

  @media only screen and (max-width: $mobileScreen) {
    .wallet-content .input-row-block {
      flex-direction: column;
      align-items: initial;

      .label-block {
        .subtitle-row {
          @include setFont('IBM Plex Sans', 500, 1.0625rem, 1.5625rem);
          color: $txt_clr4;
          margin-bottom: 20px;
        }

        .title-row {
          display: none;
        }
      }
    }
    .wallet-dividends .wallet-content .input-with-btn.mint {
      display: none;
    }
    .center-content.hide-mobile-form {
      display: none;
    }
    .wallet-content .top-content {
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 0;
      border-bottom: initial;

      .toggle-tabs {
        margin: 0 0 30px;
      }

      .available-usdu {
        padding-bottom: 25px;
      }
    }
    .wallet-content .input-with-btn {
      flex-direction: column;

      .with-bg {
        width: 100%;
        max-width: initial;
        margin-top: 10px;
        margin-left: 0;
      }
    }
    .wallet-content .input-with-btn.hide-mobile-form {
      display: none;
    }
    .wallet-content .input-with-btn.mint {
      margin-bottom: 0;
    }
    .wallet-content .center-content {
      border-bottom: initial;
      padding: 0 16px;

      .title-page {
        text-transform: initial;
        @include setFont('IBM Plex Sans', 500, 1.0625rem, 1.5625rem);
      }

      .input-row-block .label-block {
        display: none;
      }

      .input-with-btn.send {
        margin-top: 20px;
      }
    }
    .wallet-content .bottom-content {
      padding-right: 15px;
      padding-left: 16px;
      border-top: 1px solid $brdr_clr1;
      margin-top: 30px;

      .distribution-block {
        flex-direction: column;
        align-items: initial;

        .distribution-title {
          margin-bottom: 16px;
          max-width: initial;
          width: 100%;
        }
      }

      .title-page {
        text-transform: initial;
        text-align: center;
      }

      .header-block .slice-title {
        display: none;
      }

      .input-row-block.input-with-bg {
        flex-direction: column;
        align-items: initial;
      }

      .timeframe-block .timeframe-item {
        padding: 9px;
        min-width: initial;
        max-width: 190px;

        &:nth-child(-n + 2) {
          border-top: 1px solid $brdr_clr3;
        }

        &:nth-child(3),
        &:nth-child(4) {
          border-top: initial;
        }
      }
    }
  }
  @media only screen and (max-width: 376px) {
    .wallet-content .bottom-content .timeframe-block .timeframe-item {
      max-width: initial;
      width: 50%;
    }
  }
  @media only screen and (max-width: 324px) {
    .wallet-content .bottom-content .timeframe-block .timeframe-item {
      max-width: initial;
      width: 100%;
      min-height: 108px;
    }
  }
}
