@import '../../../variables';

.warning-text {
  margin: 10px;
}

.convert-wcru-content {
  .row-token {
    @include setFont('IBM Plex Sans', 400, 0.688rem, 150%);
    color: $txt_clr3;

    padding: 20px;
    white-space: break-spaces;
  }
}

.wallet-convert {
  .wrapper-flex-item.wide.centered-content {
    display: flex;

    .circular__img {
      margin: 0;
    }
  }

  .cru-convert {
    display: flex;
    flex-direction: column;

    &.op-block {
      padding: 0 17px;
      max-width: 1182px;
      align-items: center;
      width: 100%;

      .unfinished-operation-content {
        text-align: center;
        width: 100%;
        max-width: 578px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
        color: $txt_clr3;

        .close-moment {
          font-weight: 500;
          color: $txt_clr4;
        }

        a {
          @include setFont('IBM Plex Sans', 400, 1.063rem, 1.563rem);
          color: $txt_clr6;
          text-decoration: underline;
        }
      }
    }
  }
}

.table-header {
  color: #2b2a34;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 1.0625rem;
  line-height: 1.5625rem;
  font-weight: 500;
  padding-left: 26px;
  display: flex;
  flex: 1;
}
.table-header.sm {
  font-size: 1rem;
  padding: 0;
  display: inline;
}

.nested-route.wallet-convert {
  @media only screen and (min-width: $desktopScreen) {
    width: initial !important;
  }

  .cru-convert {
    display: flex;
    flex-direction: column;

    .example-thumb.example-thumb-0:focus {
      outline: none;
    }

    h2 {
      @include setFont('IBM Plex Sans', 600, 1.3125rem, 2.187rem);
    }

    .converter-notice {
      padding: 19px 20px 30px 23px;
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: $bg_clr2;
      border-radius: 8px;
      margin-top: 25px;
      margin-bottom: 16px;

      svg {
        min-width: 32px;
        min-height: 32px;
        margin-right: 17px;
      }

      > div {
        display: flex;
        flex-direction: column;
        @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
        color: $txt_clr3;

        :first-child {
          color: $txt_clr4;
          @include setFont('IBM Plex Sans', 500, 1.0625rem, 1.75rem);
        }
      }
    }

    .converter-row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      &.disabled {
        .right {
          background-color: $bg_clr2;

          input {
            background-color: $bg_clr2;
          }
        }
      }

      .left {
        display: flex;
        flex-direction: column;
        padding-right: 19px;
        min-width: 244px;
        word-break: break-word;
        @include setFont('IBM Plex Sans', 400, 0.8125rem, 1.1875rem);
        color: $txt_clr3;

        :first-child {
          color: $txt_clr4;
          @include setFont('IBM Plex Sans', 500, 1.0625rem, 1.5625rem);
        }

        :last-child {
          max-width: 190px;
        }
      }

      .right {
        position: relative;
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        border: 3px solid $brdr_clr1;
        border-radius: 8px;
        padding: 9px 19px;
        width: 400px;
        min-width: 400px;

        .preloader {
          position: absolute;
          left: 0;
          top: 0;
          width: 100% !important;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: $bg_clr13;
          z-index: 2;
          @include setFont('IBM Plex Sans', 500, 1.625rem, normal);
        }

        .default-label {
          position: relative;
          width: 228px;
          @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
          color: $txt_clr3;

          input {
            @include setFont('IBM Plex Sans', 500, 1.625rem, normal);
          }
        }

        .default-label:after {
          left: initial;
          right: 0;
          top: 50%;
          margin-top: -15px;
          height: 30px;
          width: 2px;
        }

        .token-type {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          flex-grow: 1;
          @include setFont('IBM Plex Sans', 600, 1.3125rem, 2.1875rem);
          color: $txt_clr3;

          img {
            height: 42px;
            width: 42px;
            margin-left: 10px;
          }
        }
      }
    }
    .d-flex.space-between {
      display: flex;
      justify-content: space-between;
      padding-right: 36px;
    }
    .d-flex.flex-column {
      flex-direction: column;
    }
    .align-items-center {
      align-items: center;
    }
    .notice-text-wrapper {
      margin-top: 15px;
      display: flex;
      flex: 1;
      max-width: 77%;
      flex-direction: row;
      padding: 20px;
      border-radius: 20px;
      background: $gray11;
      // margin-left: 26px;
      // margin-right: 26px;
      .wrapper-flex-item {
        svg path {
          fill: red;
          width: 20px;
        }
        svg {
          margin-top: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 45px;
          width: 45px;
        }
      }
    }
    .notice-text-wrapper.top {
      margin-top: 0px;
      margin-bottom: 30px;
      max-width: 60%;
      svg path {
        fill: black;
      }
    }
    .circular__img svg {
      display: none !important;
    }
    .sub-text {
      font-size: 11px;
      word-break: break-word;
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 0.8125rem;
      line-height: 1.1875rem;
      font-weight: 400;
      color: $dark0;
      &.refresh-frozen {
        margin: 10px 0;
      }
    }
    .content {
      display: flex;
      flex-direction: row;
      position: relative;
      border: 1px solid $brdr_clr1;
      background-image: url('../../../img/bg_convert.svg');
      background-repeat: no-repeat;
      box-shadow: 0px 4px 4px $shadow_clr2;
      border-radius: 10px;
      margin-top: 19;

      .table-wrapper {
        padding: 26px;
      }

      .table-wrapper .table-content.wallet-converter-table {
        tr {
          th:nth-child(1) {
            width: 30% !important;
          }
          th:nth-child(2) {
            width: 35% !important;
          }
          th:nth-child(3) {
            width: 25% !important;
          }
          th:nth-child(4) {
            width: 10% !important;
          }
        }
      }
      .mx-auto {
        margin-right: auto;
        margin-left: auto;
      }
      .w-100 {
        max-width: 100%;
      }
      .flex-2 {
        flex: 0.2;
        margin: initial;
      }
      .flex-item {
        flex: 1;
      }
      .convert-block {
        padding: 26px 48px 26px 26px;
        width: 720px;
        max-width: 720px;
        position: relative;

        .refresh-frzn-btn {
          margin-top: 10px;
          min-height: auto;
          width: max-content;
          margin-left: 0;
          padding: 10px 50px;

          svg {
            margin: 0;
            width: 20px;
            height: 20px;
            margin-right: 10px;
            path {
              fill: $txt_clr0;
            }
          }
        }

        .empty-objects {
          @include setFont('IBM Plex Sans', 500, 13px, 1.59375rem);
          padding-top: 35px;
          margin-top: 25px;
          color: $txt_clr5;
          text-align: center;
        }

        .blue-amount {
          @include setFont('IBM Plex Sans', 500, 13px, 1.59375rem);
          color: $txt_clr5;
        }

        &:after {
          position: absolute;
          content: url('../../../img/circle_arrow.svg');
          background-repeat: no-repeat;
          top: 20%;
          right: -20px;
        }

        .choose-type {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .choose-tip {
            display: flex;
            flex-direction: column;

            > :first-child {
              @include setFont('IBM Plex Sans', 500, 1.0625rem, 1.59375rem);
              color: $txt_clr4;
            }

            > :last-child {
              @include setFont('IBM Plex Sans', 400, 0.8125rem, 1.21875rem);
              color: $txt_clr3;
            }
          }

          .choose-btns {
            display: flex;
            flex-direction: row;
            align-items: flex-start;

            .click-btn {
              color: $txt_clr3;
              box-shadow: $shadowPart_3 $shadow_clr0, $shadowPart_2 $shadow_clr0;
              text-transform: none;
              min-width: 170px;
              max-width: 127px;

              span {
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .title-btn {
                @include setFont('IBM Plex Sans', 500, 0.94rem, 1.41rem);
              }

              &.blue {
                color: $txt_clr0;
                box-shadow: $shadowPart_5 $shadow_clr1, $shadowPart_4 $shadow_clr1, $shadowPart_3 $shadow_clr1,
                  $shadowPart_2 $shadow_clr1;
                height: initial;
              }
            }

            > :first-child {
              border-radius: 12px 0 0 12px;
            }

            > :last-child {
              border-radius: 0 12px 12px 0;
              margin-left: 0;
              padding: 10px 15px;
            }
          }
        }

        .total-info {
          margin: 16px 0 20px;
          color: $txt_clr3;
          white-space: pre-wrap;
          word-break: break-word;
          text-align: right;
          @include setFont('IBM Plex Sans', 400, 0.94rem, 1.41rem);

          .count-token {
            display: inline-block;
            margin-left: 4px;
          }
        }

        .action-block {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          flex-wrap: wrap;
          align-items: center;
          margin: 25px 0 0 0;

          .unfreeze-schedule {
            display: flex;
            align-items: center;

            svg {
              @include fixSizeSquare(24px);
            }

            span {
              margin-left: 9px;
              color: $txt_clr5;
              text-decoration: underline;
              cursor: pointer;
              word-break: break-word;
              @include setFont('IBM Plex Sans', 500, 1.06rem, 1.59rem);
            }
          }

          .select-days {
            display: flex;
            flex-direction: row;
            max-height: 26px;

            > span {
              border-radius: 4px;
              padding: 2px 8px;
              @include setFont('IBM Plex Sans', 400, 0.81rem, 1.22rem);
              cursor: pointer;
              background: $bg_clr2;
              border: 1px solid $brdr_clr12;
              color: $txt_clr9;

              &.disabled {
                border: 1px solid $brdr_clr3;
                color: $txt_clr3;
              }
            }

            > :last-child {
              margin-left: 18px;
            }
          }
        }

        .frozen-schedule {
          display: flex;
          flex-direction: column;
          margin-top: 58px;

          .schedule-info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .schedule-tips,
            .schedule-date {
              display: flex;
              flex-direction: column;
            }

            .schedule-date {
              text-align: right;
            }

            .title-block-select {
              @include setFont('IBM Plex Sans', 500, 1.063rem, 150%);
              color: $txt_clr4;
              padding-bottom: 4px;
            }

            .subtitle-block-select {
              @include setFont('IBM Plex Sans', 400, 0.813rem, 150%);
              color: $txt_clr3;
            }
          }

          .horizontal-slider {
            height: 50px;

            .example-track {
              background: #4293f6;
              top: 20px;
              height: 3px;
            }

            .example-track-1 {
              background: #dce0e5;
            }

            .example-thumb {
              font-size: 0;
              background-color: white;
              cursor: pointer;
              border: 4px solid #4293f6;
              box-sizing: border-box;
              top: 10px;
              width: 21px;
              height: 21px;
              border-radius: 50%;
            }
          }
        }
      }

      .receive-block {
        display: flex;
        flex-direction: column;
        padding: 26px 16px 62px 48px;
        background-color: $bg_clr19;
        width: 462px;
        max-width: 462px;

        > :first-child {
          margin-bottom: 31px;
          margin-top: 50px;
          @include setFont('IBM Plex Sans', 500, 1.06rem, 1.41rem);
        }

        .receive-input {
          flex-direction: column;

          .left {
            margin-bottom: 28px;
          }

          .right {
            border: initial;
            background-color: $bg_clr15;

            .default-input {
              background-color: $bg_clr15;
            }
          }
        }

        .convert-info {
          width: 100%;

          .info-row {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            width: 100%;

            &.with-pad {
              padding-top: 32px;

              .left {
                font-weight: 500;
                white-space: nowrap;
                width: 60%;
              }

              .right {
                width: 40%;
                padding-left: 19px;
              }
            }

            .row-token {
              @include setFont('IBM Plex Sans', 400, 0.688rem, 150%);
              color: $txt_clr3;
              white-space: break-spaces;
            }

            &.second-color {
              padding-top: 4px;
              color: $txt_clr3;
              align-items: center;

              .left {
                display: flex;
                align-items: center;

                .tooltip {
                  position: relative;
                  width: 30px;
                  height: 30px;

                  &:hover .tooltiptext {
                    visibility: visible;
                    opacity: 1;
                    transition: opacity 300ms;
                    -webkit-transition: opacity 300ms;
                  }

                  .tooltiptext {
                    visibility: hidden;
                    opacity: 0;
                    padding: 24px;
                    padding-top: 20px;
                    position: absolute;
                    z-index: 1;
                    bottom: 90%;
                    left: -370%;
                    border: 1px solid $brdr_clr1;
                    background: $bg_clr0;
                    box-shadow: 0px 32px 32px rgba(119, 119, 119, 0.05), 0px 16px 16px rgba(119, 119, 119, 0.05),
                      0px 8px 8px rgba(119, 119, 119, 0.05), 0px 4px 4px rgba(119, 119, 119, 0.05);
                    border-radius: 8px;
                    transition: opacity 300ms;
                    -webkit-transition: opacity 300ms;

                    p {
                      margin-bottom: 20px;
                      width: 100%;
                      text-align: center;

                      .title-tooltip {
                        @include setFont('IBM Plex Sans', 500, 1.063rem, 150%);
                      }
                    }

                    .tooltip-row {
                      display: flex;
                      align-items: center;
                      justify-content: space-between;

                      &:not(:last-child) {
                        padding-bottom: 7px;
                      }

                      .row-token {
                        @include setFont('IBM Plex Sans', 400, 0.688rem, 150%);
                        color: $txt_clr3;
                      }

                      .row-usd {
                        @include setFont('IBM Plex Sans', 500, 0.688rem, 150%);
                        color: $txt_clr4;
                      }
                    }
                  }

                  svg {
                    margin-left: 5px;
                    width: 30px;
                    min-width: 30px;
                    min-height: 30px;
                    height: 30px;

                    &:hover {
                      cursor: pointer;
                    }
                  }
                }
              }
            }

            .left,
            .right {
              width: 50%;
              display: flex;
              align-items: flex-start;
              @include setFont('IBM Plex Sans', 400, 1.06rem, 1.56rem);
            }

            .left {
              position: relative;
              padding-right: 19px;
              color: $txt_clr4;

              .arrow-ic {
                top: -20px;
                position: absolute;
              }

              > svg {
                width: 32px;
                height: 32px;
              }
            }
          }
        }

        .converter-notice {
          align-items: initial;
          position: relative;
          width: 100%;
          background-color: $bg_clr15;

          svg {
            position: absolute;
            top: 18px;
            left: 20px;
          }

          > div {
            max-width: 337px;

            & :first-child {
              padding-left: 40px;
              padding-bottom: 16px;
            }
          }
        }

        .with-btn {
          margin-top: 31px;

          .msg-printer-view {
            text-align: center;
            padding-right: 30px;
            padding-left: 30px;
            margin-top: 10px;
            font-size: 1rem;
          }

          button {
            height: 63px;
          }
        }
      }
    }

    @media only screen and (max-width: 1210px) {
      .content {
        flex-direction: column;

        .convert-block:after {
          content: initial;
        }

        .receive-block {
          width: 720px;
          max-width: 720px;
          padding: 26px 48px 26px 26px;

          .receive-input {
            flex-direction: row;
          }

          .converter-notice div {
            max-width: initial;
          }

          .convert-info {
            .right {
              justify-content: flex-end;
            }
          }
        }
      }
    }

    @media only screen and (max-width: 840px) {
      > h2 {
        margin: 24px 0 15px 0;
        text-align: center;
        @include setFont('IBM Plex Sans', 600, 1.31rem, 2.2rem);
        text-transform: uppercase;
      }
      .content {
        flex-direction: column;
        border: 0;
        background: none;
        box-shadow: none;

        .converter-row {
          .right {
            width: 100%;
            min-width: initial;

            .default-label {
              flex-grow: 1;
            }

            .token-type {
              width: 131px;
              max-width: 131px;
            }
          }
        }

        .convert-block {
          padding: 0 16px;
          width: 100%;
          max-width: initial;

          .choose-type {
            justify-content: center;

            .choose-tip {
              display: none;
            }

            .choose-btns {
              width: 100%;

              .click-btn {
                min-width: initial;
                max-width: 50%;
                flex-grow: 1;
              }
            }
          }

          .total-info {
            text-align: center;
          }

          .active-input {
            flex-direction: column;
            justify-content: center;

            .left {
              margin-bottom: 12px;

              > :last-child {
                display: none;
              }
            }

            .right {
              max-width: 100%;
            }
          }
        }

        .receive-block {
          padding: 31px 16px 0 16px;
          width: 100%;
          max-width: 100%;
          background: none;

          .converter-row {
            flex-direction: column;
            margin-top: 0;
            margin-bottom: 30px;
          }

          .receive-input {
            .left {
              margin-bottom: 12px;
            }

            .right {
              max-width: 100%;
            }
          }

          .converter-notice {
            display: none;
          }
        }
      }
    }
    @media only screen and (max-width: $mobileScreen) {
      .notice-text-wrapper.top {
        max-width: 100%;
      }

      .table-header {
        margin-top: 20px;
      }
      .notice-text-wrapper {
        margin-top: 15px;
        max-width: 100%;
        flex-wrap: wrap;
        text-align: center;
        span {
          margin-top: 0px;
        }
        svg {
          margin-top: 0px;
        }
      }
      .sub-text {
        text-align: center;
      }
      .d-flex.space-between {
        margin-top: 20px;
        text-align: center;
        flex-direction: column;
        padding-right: 0px;
      }
      .content .convert-block .action-block {
        .unfreeze-schedule,
        .select-days {
          margin-top: 14px;
        }
      }
      .convert-info {
        .left {
          min-width: 173px;
          white-space: nowrap;
        }

        .right {
          justify-content: flex-start !important;
        }
      }
      .content .converter-row .right .token-type {
        min-width: 131px;
      }
    }
  }
}
