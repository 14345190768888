@import '../../../variables';

.app-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #f6f7f8;
  @include setFont('IBM Plex Sans', 400, 1.2rem, 1.375rem);

  span {
    margin-bottom: 10px;
  }
}
