@import '../../../variables';

@media only screen and (min-width: $desktopScreen) {
  .filter-history .filter-block .filter-item:hover {
    color: $txt_clr4;
    border-color: $brdr_clr5;
    transition: color 200ms, background-color 200ms, border-color 200ms;
    -webkit-transition: color 200ms, background-color 200ms, border-color 200ms;
  }
}

.filter-history {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-bottom: 16px;

  .select-filter {
    width: 150px;

    &:focus {
      outline: none;
    }

    .react-select__control {
      // height: 64px;
      // background-color: $bg_clr2;
      border-radius: 8px;
      color: $txt_clr4;
      @include setFont('IBM Plex Sans', 400, 1.0625rem, 1.5625rem);
      //padding: 19px 16px;
      border: 1px solid $brdr_clr4;
      position: relative;
      overflow: hidden;

      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: $bg_clr19;
      }

      .react-select__single-value {
        color: $txt_clr3;
      }

      .react-select__placeholder {
        color: $txt_clr4;
        opacity: 0.5;
      }

      .react-select__value-container {
        .select-row {
          @include setFont('IBM Plex Sans', 400, 1.0625rem, 1.1875rem);
          color: $txt_clr3;
        }
      }
    }

    .react-select__menu {
      font-weight: 400;

      .react-select__option {
        cursor: pointer;
        height: 34px;
        display: flex;
        align-items: center;
        @include setFont('IBM Plex Sans', 400, 1.0625rem, 1.5625rem);
        color: $txt_clr4;
        border-bottom: 1px solid $brdr_clr3;

        div {
          transition: background-color 200ms;
          -webkit-transition: background-color 200ms;

          &:hover {
            transition: background-color 200ms;
            -webkit-transition: background-color 200ms;
          }
        }
      }
    }

    .react-select__indicator-separator {
      display: none;
    }

    .react-select__indicator {
      color: $txt_clr4;
    }
  }

  .title-filter {
    @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.1875rem);
    color: $txt_clr3;
    margin-right: 23px;
    min-width: 110px;
  }

  .filter-block {
    display: flex;
    flex-direction: row;

    .filter-item {
      border: 1px solid $brdr_clr1;
      border-radius: 9px;
      @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.1875rem);
      color: $txt_clr3;
      margin-right: 4px;
      padding: 8px 12px;
      transition: color 200ms, background-color 200ms, border-color 200ms;
      -webkit-transition: color 200ms, background-color 200ms, border-color 200ms;

      cursor: pointer;

      &.active-filter {
        color: $txt_clr0;
        background-color: $bg_clr6;
        border-color: $brdr_clr7;
      }
    }
  }
}
