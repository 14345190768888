@import '../../../variables';

@keyframes InfRotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (min-width: $desktopScreen) {
  .wallet-cru .wallet-cru-block .staking-update .update-btn:hover {
    background: $bg_clr0;
    box-shadow: 0px 4px 4px rgba(119, 119, 119, 0.05);
    transition: background-color 200ms, box-shadow 200ms;
    -webkit-transition: background-color 200ms, box-shadow 200ms;
  }
}

.wallet-cru {
  .choose-type {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px;

    .choose-tip {
      display: flex;
      flex-direction: column;

      > :first-child {
        @include setFont('IBM Plex Sans', 500, 1.0625rem, 1.59375rem);
        color: $txt_clr4;
      }

      > :last-child {
        @include setFont('IBM Plex Sans', 400, 0.8125rem, 1.21875rem);
        color: $txt_clr3;
      }
    }

    .choose-btns {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      .click-btn {
        color: $txt_clr3;
        box-shadow: $shadowPart_3 $shadow_clr0, $shadowPart_2 $shadow_clr0;
        text-transform: none;
        min-width: 170px;
        max-width: 127px;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .title-btn {
          @include setFont('IBM Plex Sans', 500, 0.94rem, 1.41rem);
        }

        &.blue {
          color: $txt_clr0;
          box-shadow: $shadowPart_5 $shadow_clr1, $shadowPart_4 $shadow_clr1, $shadowPart_3 $shadow_clr1,
            $shadowPart_2 $shadow_clr1;
          height: initial;
        }
      }

      > :first-child {
        border-radius: 12px 0 0 12px;
      }

      > :last-child {
        border-radius: 0 12px 12px 0;
        margin-left: 0;
        padding: 10px 15px;
      }
    }
  }

  .wallet-cru-block {
    .staking-update {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      background: $bg_clr2;
      border-radius: 8px;
      min-height: 80px;
      padding: 16px 20px 12px 20px;
      margin-bottom: 20px;
      //margin-top: 25px;

      .click-btn {
        border: none;
      }

      .update-title {
        svg {
          margin-right: 16px;
        }

        .title-count {
          display: flex;
          align-items: center;
          flex-direction: row;

          .staking-title {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
            color: $txt_clr3;
            max-width: 190px;

            span:first-child {
              padding-right: 4px;
            }

            svg {
              min-width: 24px;
              min-height: 24px;
              margin-left: 4px;
            }
          }

          .staking-count {
            @include setFont('IBM Plex Sans', 500, 1.063rem, 1.813rem);
            color: $txt_clr4;
            opacity: 0.5;
            padding-right: 14px;
          }
        }
      }

      .update-info {
        display: flex;
        align-items: center;
        @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
        color: $txt_clr3;

        span:first-child {
          padding-right: 4px;
        }

        > svg {
          cursor: pointer;

          &.active {
            animation: 1s InfRotation infinite;
          }
        }
      }

      .update-btn {
        flex-direction: row-reverse;
        background-color: $bg_clr3;
        border-radius: 8px;
        min-height: 45px;
        width: initial;
        min-width: 150px;
        transition: background-color 200ms, box-shadow 200ms;
        -webkit-transition: background-color 200ms, box-shadow 200ms;
        text-transform: initial;

        .btn-icon {
          min-width: 24px;
          min-height: 24px;
          margin-right: 0;
        }

        .title-btn {
          @include setFont('IBM Plex Sans', 500, 1.063rem, 1.563rem);
          color: $txt_clr5;
          margin-left: 0;
        }
      }

      &.staking-claim {
        background: rgba(82, 170, 85, 0.1);

        .update-title {
          display: flex;
          align-items: center;
          flex-direction: row;

          .title-count {
            align-items: initial;
            flex-direction: column-reverse;

            .staking-count {
              opacity: 1;
            }
          }
        }

        .claim-btn {
          background: $bg_clr10;
          border-radius: 8px;
          min-width: 129px;
          width: initial;

          .title-btn {
            @include setFont('IBM Plex Sans', 500, 1.063rem, 1.563rem);
            color: $txt_clr0;
            text-transform: initial;
            margin-left: 0;
          }
        }
      }
    }

    .update-info {
      display: flex;
      align-items: center;
      @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
      color: $txt_clr3;

      span:first-child {
        padding-right: 4px;
      }
    }

    .wallet-content {
      padding: 0;
      margin-bottom: 60px;

      .top-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-bottom: 1px solid $brdr_clr1;
        padding: 25px 30px 24px 30px;

        .amount-block {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 40px;

          .cru-amount {
            .amount-title {
              @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
              color: $txt_clr3;
            }

            .count-token {
              @include setFont('IBM Plex Sans', 500, 1.75rem, 2.9375rem);
            }
          }
        }

        .cru-info-blocks {
          display: flex;
          align-items: flex-start;
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;
          margin-top: 35px;
          //min-height: 120px;

          > div {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            width: 372px;
          }

          .content-block {
            width: 100%;
            padding-top: 16px;
            padding-right: 40px;

            .token-info-block {
              align-items: baseline;
              flex-direction: row;

              .title-icon {
                margin-right: 15px;

                .info-icon {
                  min-width: 24px;
                  min-height: 24px;
                  background-image: url('../../../img/gray-info.svg');
                }
              }

              .info-block {
                margin-top: 0;

                .increase-status {
                  @include setFont('IBM Plex Sans', 400, 0.813rem, 1.188rem);
                  color: $txt_clr3;
                }
              }
            }
          }
        }
      }

      .bottom-block {
        display: flex;
        flex-direction: row;
        padding: 45px 30px 30px 30px;

        .stake-block {
          display: flex;
          flex-direction: column;
          //width: 100%;
          width: 328px;

          .title-page {
            margin-bottom: 25px;
          }

          &.unstake {
            margin-left: 64px;
          }

          .with-bg.blue {
            min-height: 63px;
          }

          .input-with-bg {
            height: 100%;

            .filled-in {
              padding-top: 9px;
              padding-bottom: 9px;
            }

            .balance-row {
              justify-content: center;
            }
          }

          .frozen-balance {
            margin: 0 auto 13px;

            .balance-row {
              .count-token,
              .token-name {
                text-decoration: underline;
              }
            }
          }
        }
      }

      .msg-printer-view {
        text-align: center;
        padding-right: 30px;
        padding-left: 30px;
        margin-bottom: 10px;
      }
    }

    .staking-history {
      .header-block {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .update-ic {
          cursor: pointer;
        }
        .title-block {
          @include setFont('IBM Plex Sans', 500, 1.75rem, 2.938rem);
          color: $txt_clr4;
        }
      }

      .staking-history-table {
        position: relative;

        .table-content {
          th,
          td {
            white-space: nowrap;
          }

          .body-row {
            height: 80px;
          }

          th:nth-child(1),
          td:nth-child(1) {
            width: 25%;
          }

          th:nth-child(2),
          td:nth-child(2) {
            width: 25%;
          }

          th:nth-child(3),
          td:nth-child(3) {
            width: 15%;
            text-align: left;
          }

          th:nth-child(4),
          td:nth-child(4) {
            width: 30%;
          }

          td:nth-child(1) {
            .tx-title {
              white-space: pre-wrap;
              @include setFont('IBM Plex Sans', 400, 0.938rem, 2rem);
              color: $txt_clr8;

              svg {
                min-height: 12px;
                min-width: 12px;
                margin-left: 6px;
              }

              .tr-id {
                max-width: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
                vertical-align: bottom;
              }
            }

            .start-date {
              @include setFont('IBM Plex Sans', 400, 0.813rem, 1.188rem);
              color: $txt_clr3;
            }
          }

          td:nth-child(2) {
            p:first-child {
              @include setFont('IBM Plex Sans', 500, 1.063rem, 1.563rem);
            }

            .stake {
              color: $txt_clr10;
            }

            .unstake {
              color: #eb5757;
            }
          }

          td:nth-child(3) {
            @include setFont('IBM Plex Sans', 400, 0.938rem, 1.375rem);
            color: $txt_clr3;
          }

          td:nth-child(4) {
            @include setFont('IBM Plex Sans', 500, 1.063rem, 1.563rem);
            color: $txt_clr4;

            span:nth-child(2) {
              opacity: 0.5;
            }
          }
        }
      }

      .no-transactions {
        @include setFont('IBM Plex Sans', 500, 1.563rem, 1.563rem);
        text-align: center;
        margin-top: 20px;
      }
    }
  }

  .d-flex.space-between.title-container {
    display: flex;
    justify-content: space-between;
  }
  .title-page {
    flex: 1;
  }
  .delay-span {
    text-align: center;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.813rem;
    line-height: 1.188rem;
    font-weight: 400;
    color: #758596;
    max-lines: 1;
    flex: 1;
  }
  @media only screen and (max-width: 1152px) {
    margin: auto;
  }

  @media only screen and (max-width: $mobileScreen) {
    .wallet-cru-block .staking-update {
      order: -1;
      padding: 16px 10px 12px 7px;
      border-radius: initial;

      .update-info {
        display: flex;
      }
      .update-info-text {
        display: none;
      }
      .update-title svg {
        margin-right: 6px;
      }

      .update-title .title-count {
        flex-direction: column-reverse;
        align-items: initial;
        white-space: nowrap;

        .staking-title {
          align-items: initial;

          .subtitle {
            display: none;
          }
        }
      }
    }

    .wallet-cru-block .wallet-content .top-block {
      border: initial;
      padding: 15px 17px;

      .cru-info-blocks .content-block .token-info-block .title-icon .info-icon {
        display: none;
      }

      .amount-block .cru-amount:last-of-type,
      .drag-line-wrapper {
        // display: none;
        margin-top: 20px;
      }
      .count-row {
        margin-left: 5px;
      }
      .cru-amount {
        text-align: center;
        flex: 1;
        .count-row:nth-child(1) {
          font-size: 16px;
        }
      }
      .amount-block {
        margin-bottom: 0;

        .cru-amount:first-of-type {
          display: flex;
          align-items: center;
          //justify-content: space-between;
          justify-content: flex-start;
          width: 100%;
          border-bottom: 1px solid $brdr_clr1;
          padding-bottom: 9px;

          .count-token {
            @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
          }
        }
      }
    }
    .wallet-cru-block .staking-history .header-block {
      display: none;
    }
    .table-wrapper.staking-history-table {
      margin-bottom: 15px;

      .table-content .body-table .body-row td {
        padding-right: 20px;
      }

      .table-content .header-table th {
        padding-right: 15px;
      }
    }
    .wallet-cru-block .wallet-content .top-block .cru-info-blocks {
      margin-top: initial;

      .content-block {
        align-items: initial;
        flex-direction: column;
        justify-content: initial;
        padding-right: 0;
        padding-top: 0;
        width: 100%;

        .token-info-block {
          justify-content: space-between;
          padding-top: 25px;
          padding-bottom: 9px;

          &:not(:last-of-type) {
            border-bottom: 1px solid $brdr_clr1;
          }

          .info-block {
            @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);

            .increase-status {
              display: none;
            }
          }
        }
      }
    }
    .wallet-cru-block .wallet-content .bottom-block {
      padding-left: 17px;
      padding-right: 17px;

      .stake-block {
        width: 100%;

        &.unstake {
          margin-left: 0;
        }

        .title-page {
          display: none;
        }

        &.unstake.hide-mobile-form {
          display: none;
        }

        &.hide-mobile-form {
          display: none;
        }
      }
    }
  }
}
