@import '../../../variables';

@keyframes show-modal-table {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.modal-schedule {
  background-color: $bg_clr0;
  border: 1px solid $brdr_clr1;
  border-radius: 8px;
  height: 100%;
  max-height: 700px;
  width: 690px;
  margin: auto;
  position: relative;
  top: 25px;
  z-index: 10;
  animation: show-modal-table 0.8s;
  padding: 23px 25px 21px 25px;
  overflow: hidden;

  .header-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    svg {
      position: absolute;
      right: 0;
      cursor: pointer;
      path {
        fill: $bg_clr5;
      }
    }
    .arrow-back {
      left: 0;
    }
    .title-modal {
      @include setFont('IBM Plex Sans', 600, 1.3125rem, 167%);
    }
  }
  .content {
    overflow-y: auto;
    height: 100%;
    max-height: 638px;
    &::-webkit-scrollbar {
      width: 0;
    }

    &::-webkit-scrollbar-thumb {
      opacity: 0.2;
      background-color: $bg_clr16;
    }
    .schedule-row {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 15px;
      padding-bottom: 14px;
      border-bottom: 1px solid $brdr_clr1;
      .left-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        .date-count-block {
          display: flex;
          align-items: center;
          white-space: nowrap;
          width: 100%;
          @include setFont('IBM Plex Sans', 400, 0.8125rem, 150%);
          opacity: 0.5;
          color: $txt_clr4;
          margin-bottom: 4px;
          .total-amount {
            display: flex;
            padding-left: 4px;
          }
          .date {
            padding-right: 2px;
            &::after {
              content: '\002F';
            }
          }
        }
        .unfreeze-count {
          display: flex;
          @include setFont('IBM Plex Sans', 500, 1.0625rem, 150%);
        }
        .unfreeze-title {
          @include setFont('IBM Plex Sans', 400, 0.8125rem, 100%);
        }
      }
      .count-token {
        padding-right: 5px;
      }
      .right-block {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;

        .info-unfreeze {
          display: flex;
          flex-direction: column;
          margin-right: 12px;
          margin-bottom: 4px;
          span:first-of-type {
            @include setFont('IBM Plex Sans', 400, 0.8125rem, 150%);
            opacity: 0.5;
            color: $txt_clr4;
          }
          span:last-of-type {
            @include setFont('IBM Plex Sans', 500, 1.0625rem, 150%);
            color: $txt_clr4;
            opacity: 1;
          }
        }
      }
    }
  }
  .details-block {
    padding-top: 15px;
    .top-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .left-block {
        .date-count {
          display: flex;
          margin-bottom: 4px;
          div {
            display: flex;
            align-items: center;

            .count-token {
              margin-bottom: initial;
              span {
                color: $txt_clr4;
                opacity: 1;
              }
            }
          }
          span {
            @include setFont('IBM Plex Sans', 400, 0.9375rem, 150%);
            color: $txt_clr4;
          }
          span:first-child {
            padding-right: 6px;
            opacity: 0.5;
          }
        }
        .count-cru {
          display: flex;
          align-items: center;
          .name-token {
            @include setFont('IBM Plex Sans', 500, 1.75rem, 167%);
            color: $txt_clr4;
            padding-left: 6px;
          }
        }
        .count-token {
          @include setFont('IBM Plex Sans', 500, 1.75rem, 167%);
          margin-bottom: 2px;
        }
      }
      .right-block {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        min-width: 115px;
        .count-days {
          @include setFont('IBM Plex Sans', 500, 1.75rem, 167%);
        }
      }
    }
    .explain-txt {
      @include setFont('IBM Plex Sans', 400, 0.8125rem, 150%);
      color: $txt_clr4;
      opacity: 0.5;
    }
    .bottom-content {
      margin-top: 16px;
      .details-table {
        display: block;
        width: 100%;
        thead,
        tbody,
        tr {
          display: table;
          width: 100%;
          table-layout: fixed;
        }
        tbody {
          display: block;
          overflow: auto;
          table-layout: fixed;
          max-height: 453px;
        }
        .header-table {
          background-color: initial;
          margin-bottom: 0;
          padding: 0;
          overflow-x: auto;
          th {
            @include setFont('IBM Plex Sans', 400, 0.75rem, 150%);
            color: $txt_clr4;
            text-transform: initial;
            white-space: nowrap;
          }
          th:nth-child(1) {
            width: 10%;
          }
          th:nth-child(2) {
            width: 25%;
            padding-left: 5px;
          }
          th:nth-child(3) {
            width: 25%;
            text-align: center;
            padding-right: 10px;
          }
          th:nth-child(4) {
            width: 17%;
            text-align: right;
          }
          th:nth-child(5) {
            width: 20%;
            text-align: right;
            padding-right: 15px;
          }
        }
        .body-table {
          border-top: 1px solid $brdr_clr5;
          .body-row {
            height: 32px;
            width: 100%;
            @include setFont('IBM Plex Sans', 400, 0.75rem, 150%);
            color: $txt_clr4;
            opacity: 1;
            border-bottom: 1px solid $brdr_clr1;
            &.paid-out {
              opacity: 0.2;
            }
            td {
              padding-top: 7px;
              padding-bottom: 7px;
            }
          }
          tr td:nth-child(1) {
            width: 10%;
          }
          tr td:nth-child(2) {
            width: 25%;
          }
          tr td:nth-child(3) {
            width: 25%;
            text-align: center;
            padding-right: 10px;
          }
          tr td:nth-child(4) {
            width: 17%;
            text-align: right;
          }
          tr td:nth-child(5) {
            width: 20%;
            text-align: right;
            padding-right: 15px;
          }
        }
      }
    }
  }
  .circle-progress.unfreeze-status {
    &.sm {
      position: relative;
      width: 100%;
      height: 24px;
      justify-content: flex-end;
      flex-direction: row;

      > svg {
        margin-right: 3px;
      }

      .circle-txt {
        flex-direction: row;
        position: absolute;
        justify-content: flex-end;
        right: 0;
        position: initial;
        span {
          font-weight: 500;
        }
        .title {
          padding-left: 3px;
        }
      }
    }
    .circle-txt span {
      @include setFont('IBM Plex Sans', 400, 0.8125rem, 150%);
      color: $txt_clr10;
    }
  }
  @media only screen and (max-width: $mobileScreen) {
    top: 0;
    height: 100%;
    width: 100%;
    padding: 0;
    .header-modal {
      padding: 23px;
      margin-bottom: initial;
      .arrow-back {
        left: 16px;
      }
      .arrow-close {
        right: 16px;
      }
    }
    .content {
      max-height: initial;
    }
    .details-block .top-content .left-block .count-cru .name-token {
      font-size: 1.3125rem;
    }
    .content .schedule-row .left-block .date-count-block {
      flex-direction: column;
      align-items: initial;
    }
    .content .schedule-row .left-block .date-count-block .total-amount {
      padding-left: initial;
    }
    .content .schedule-row .right-block .info-unfreeze {
      align-items: flex-end;
      .txt-info {
        max-width: 75px;
        text-align: right;
        margin-left: 7px;
      }
    }
    .top-content,
    .content {
      padding-left: 16px;
      padding-right: 16px;
    }
    .table-wrapper .table-content.details-table {
      th:nth-child(1) {
        width: 10%;
        padding-left: 10px;
      }
      tr td:nth-child(1) {
        width: 10%;
        padding-left: 10px;
      }
      tr td:nth-child(2) {
        padding-left: 10px;
      }
      .body-table {
        .body-row td {
          font-size: 0.75rem;
        }
      }
    }
    .content .schedule-row .left-block .date-count-block .date::after {
      content: initial;
    }
    .top-content .left-block .date-count span {
      font-size: 0.8125rem;
    }
    .top-content .left-block .count-token span,
    .top-content .right-block .count-days span {
      font-size: 1.3125rem;
    }
    .content .schedule-row .left-block .unfreeze-title-row {
      max-width: 160px;
      word-break: break-word;
    }
  }
}
