@import '../../../variables';

.drag-line-wrapper {
  position: relative;
  height: 8px;
  width: 100%;

  * {
    user-select: none;
  }

  .value-line {
    position: absolute;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-image: url('./img/default_delimiter.png');
    background-repeat: repeat;

    &.selected {
      z-index: 2;
      background-image: url('./img/active_delimiter.png');
      width: initial;

      .drag-cursor {
        position: absolute;
        right: 0;
        height: 100%;
        width: 2px;

        .active-value {
          position: absolute;
          top: -28px;
          left: -36px;
          height: 37px;
          max-height: 37px;
          width: 72px;
          max-width: 72px;
          text-align: center;
          color: $txt_clr3;
          @include setFont('IBM Plex Sans', normal, 0.9375rem, 150%);
        }

        > svg {
          position: absolute;
          left: -7px;
          top: 14px;
        }
      }
    }
  }
}
