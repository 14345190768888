@import '../../../variables';

.blockchain-block {
  padding: 0 40px 40px;

  .main-title {
    color: $txt_clr3;

    &.md {
      color: $txt_clr4;
    }
  }

  .content-block {
    display: flex;
    align-items: center;
    padding-bottom: 30px;
    padding-top: 30px;

    .statistic-row {
      display: flex;
      align-items: center;
      flex-direction: row;
      white-space: pre-wrap;
      @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.1875rem);

      padding-bottom: 8px;

      &:first-of-type {
        padding-top: 13px;
      }

      .dots {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #13c41a;
        margin: 0 6px;
      }

      .row-title {
        white-space: nowrap;
        color: $txt_clr3;
      }
    }

    .left-col {
      padding-right: 40px;
    }
  }

  .id-block {
    display: flex;
    flex-direction: column;

    .main-title {
      opacity: 0.75;
      padding-bottom: 12px;
    }

    .id-count {
      @include setFont('IBM Plex Sans', 400, 0.8125rem, 1.275rem);
      color: $txt_clr3;
      max-width: 315px;
      word-break: break-word;
    }
  }

  .lines-wrapper {
    .line-header {
      white-space: pre-wrap;
    }
  }
}
