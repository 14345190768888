@import '../../../variables';

@keyframes show-pin-modal {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.enter-pin-modal {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    @include setFont('IBM Plex Sans', 500, 1.313rem, 1.938rem);
    text-align: center;
  }

  .pin-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 45px 45px 15px;
    max-width: 415px;
    width: 100%;
    min-height: 449px;
    background-color: $bg_clr0;
    border: 1px solid $brdr_clr1;
    box-shadow: 0 4px 4px rgba(119, 119, 119, 0.05);
    border-radius: 8px;
    animation: show-pin-modal 0.8s;

    &.status-modal {
      height: initial;
    }

    .input-wrapper {
      margin-top: 20px;
      text-align: center;

      &.email {
        width: 100%;
        padding: 0 30px;
        border-bottom: 1px solid $brdr_clr1;
        padding-bottom: 20px;

        .default-label.show-status-border.error-state:after {
          background-color: red;
        }

        .email-field {
          display: flex;
          align-items: center;
          background: $bg_clr2;
          border-radius: 8px;
          height: 64px;
          width: 100%;

          .input-container {
            width: 100%;
            padding: 19px 16px;

            input {
              @include setFont('IBM Plex Sans', 400, 1.0625rem, 1.5625rem);
              background-color: $bg_clr2;
              color: $txt_clr4;
              //opacity: 0.5;
            }
          }
        }

        .default-label {
          width: 100%;
        }
      }

      .input-container input {
        opacity: 1;
      }
    }

    .msg-container {
      min-height: 50px;
      padding-top: 10px;
      padding-bottom: 20px;
    }

    .click-btn {
      width: 100%;
    }

    .btn-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 360px;

      > :last-child {
        @include setFont('IBM Plex Sans', 400, 1.063rem, 1.563rem);
        color: $txt_clr6;
        text-decoration: underline;
        cursor: pointer;
        margin-top: 25px;
      }
    }

    &.sending-content {
      padding: 44px 0 33px 0;

      .to-wrapper {
        padding: 0 34px;
        border-top: 1px solid $brdr_clr1;
        border-bottom: 1px solid $brdr_clr1;
      }
    }

    &.create-slice {
      h1 {
        padding: 0 50px;
      }
    }

    &.convert-wcru-content {
      position: relative;
      padding: 25px 0 30px 0;

      .amount-count {
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        justify-content: center;

        span:nth-child(1) {
          white-space: nowrap;
        }

        span:nth-child(1),
        .value {
          margin-right: 4px;
        }

        .convert-row {
          margin-right: 4px;

          .value-wcru {
            margin-left: 3px;
          }
        }
      }

      .convert-row {
        display: flex;
        align-items: center;
        @include setFont('IBM Plex Sans', 600, 1.3125rem, 2.1875rem);
      }

      .value-wcru,
      .value,
      .token-value {
        @include setFont('IBM Plex Sans', 600, 1.3125rem, 2.1875rem);
        color: $txt_clr5;
      }

      .user-info-block {
        display: flex;
        align-items: center;
        cursor: pointer;
        width: 100%;

        div {
          display: flex;
          flex-direction: column;
          @include setFont('IBM Plex Sans', normal, 0.9375rem, 150%);

          .subtitle {
            font-size: 0.8125rem;
            color: $txt_clr3;
          }
        }

        .user-img {
          width: 36px;
          height: 36px;
          margin-right: 11px;
          border-radius: 50%;
          background-color: $bg_clr4;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .click-btn.with-bg.blue {
        svg {
          width: 24px;
          height: 23px;
        }
      }

      .show-status-done {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 18px;
        padding-right: 18px;
        height: 100%;
        width: 100%;

        .status-icon {
          margin-bottom: 50px;
          margin-top: 20px;
        }

        .count-trx-info {
          text-align: center;
          @include setFont('IBM Plex Sans', 400, 0.938rem, 150%);
          max-width: 330px;
          padding-top: 24px;

          .count-convert {
            padding-right: 5px;
          }
        }

        .status-title {
          @include setFont('IBM Plex Sans', 600, 1.3125rem, 2.1875rem);
          max-width: 300px;
          text-align: center;
        }

        .click-btn.with-bg.blue {
          margin-top: 45px;
          max-width: 360px;
          width: 100%;
        }

        .status-subtitle {
          @include setFont('IBM Plex Sans', normal, 0.9375rem, 150%);
          text-align: center;
          padding-bottom: 70px;
        }
      }
    }
  }

  .notice-block {
    margin-bottom: 25px;
    padding-left: 27px;
    padding-right: 27px;
    text-align: center;
    display: flex;
    align-items: center;

    .circular__img {
      width: 40px;
      height: 40px;
      path {
        fill: red;
      }
    }
    .notice-info-text-wrapper {
      text-align: left;
      padding-left: 10px;

      .alert {
        color: $txt_clr14;
      }

      span {
        @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
        color: $txt_clr3;
        display: inline-block;
        padding-bottom: 10px;
      }

      & > span:first-child {
        font-weight: 700;
        color: $txt_clr4;
        padding-right: 5px;
      }
    }
  }

  .default-label {
    width: 126px;

    input {
      text-align: center;
    }
  }

  @media only screen and (max-width: $mobileScreen) {
    .pin-content {
      width: initial;
    }
    .pin-content.convert-wcru-content {
      padding: 10px 0 15px 0;

      .row-wrapper.rate {
        margin-top: 10px;
      }

      .enter-pin {
        margin-top: 10px;
      }

      .msg-container {
        padding-bottom: 10px;
      }
    }
  }

  .payment-block {
    // width: 100%;
    max-width: 580px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 17px;
    padding-left: 10px;
    background-color: $bg_clr0;
    position: absolute;
    top: -153px;
    z-index: 44;
    height: 100%;
    min-height: 749px;
    max-height: 995px;
    border-radius: 8px;
    padding: 20px 40px 10px 40px;

    .amount {
      @include setFont('IBM Plex Sans', 500, 1.5rem, 150%);
      color: $txt_clr9;
    }

    .header-payment {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      // margin-bottom: 17px;
      position: relative;

      .header-title {
        @include setFont('IBM Plex Sans', 500, 1.313rem, 150%);
      }

      .arrow-back {
        position: absolute;
        left: 0;
        padding: 17px;
        cursor: pointer;
      }
    }

    .amount-count {
      margin-top: 12px;
      @include setFont('IBM Plex Sans', 400, 0.8125rem, 150%);
      color: $txt_clr3;

      .convert-row,
      .value,
      .token-value,
      .value-wcru {
        @extend .amount-count;
        margin-top: initial;
      }
    }

    .cards-block {
      display: flex;
      min-width: 480px;
      width: 100%;
      margin-top: 20px;
      position: relative;

      .data-card {
        width: 100%;
        height: 100%;
        max-height: 220px;
        max-width: 320px;
        border-radius: 20px;
        background-color: $bg_clr17;
        padding: 26px 18px 16px 16px;
        box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.07), 0px 9px 46px rgba(0, 0, 0, 0.06), 0px 11px 15px rgba(0, 0, 0, 0.1);
        position: relative;
        z-index: 5;

        svg {
          position: absolute;
          right: 18px;
          top: 18px;
        }

        .default-label.card-number {
          width: 286px;
          border-radius: 8px;
        }

        .sm-inputs {
          display: flex;
          align-items: center;
          margin-top: 18px;

          .default-label:after {
            height: initial;
          }

          .default-label.card-thru {
            width: 87px;
            margin-right: 10px;
          }

          .default-label.card-name {
            width: 100%;
            max-width: 189px;
          }
        }
      }

      .data-cvv-card {
        max-width: 320px;
        min-width: 320px;
        max-height: 220px;
        height: 100%;
        background-color: $bg_clr17;
        border-radius: 20px;
        position: absolute;
        top: 30px;
        right: 0;
        z-index: 1;
        padding-top: 23px;

        .gray-stripe {
          min-height: 47px;
          width: 100%;
          background: $bg_clr20;
          opacity: 0.5;
        }

        .card-content {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;
          padding: 0px 23px 44px 0px;
          margin-top: 18px;

          .default-label.card-cvv {
            width: 87px;
            margin-right: 32px;
            margin-left: auto;
            border-radius: 8px;

            .input-container input {
              width: 100%;
            }
          }

          .input-subtitle {
            @include setFont('IBM Plex Sans', 400, 0.688rem, 1rem);
            letter-spacing: 0.4px;
            color: $txt_clr15;
            max-width: 118px;
            margin-top: 14px;
          }
        }
      }

      .label-input {
        padding-bottom: 8px;
        text-transform: uppercase;
        letter-spacing: 1px;
        @include setFont('IBM Plex Mono', 500, 0.688rem, 1rem);
        color: $txt_clr15;
      }

      .input-container {
        height: 48px;
        width: 100%;
        border-radius: 8px;
        border: 1px solid $brdr_clr13;
        margin-top: 8px;

        input {
          @include setFont('IBM Plex Mono', 400, 1.063rem, 1.5rem);
          height: 100%;
          border-radius: 8px;
          text-align: left;
          padding: 11px 16px;
          text-transform: uppercase;
          letter-spacing: 0.15px;

          &::placeholder {
            color: $txt_clr16;
          }
        }
      }
    }

    .inputs-container {
      margin-top: 40px;

      .custom-checkbox {
        margin-bottom: 9px;
        cursor: pointer;

        label {
          justify-content: flex-end;
        }
      }

      .with-bg.blue {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .notice-txt {
        @include setFont('IBM Plex Sans', 400, 0.688rem, 1rem);
        color: $txt_clr15;
        letter-spacing: 0.4px;
      }

      .city-zip {
        display: flex;
        align-items: center;

        .default-label.card-input.city {
          margin-right: 17px;
          max-width: 274px;
          width: 100%;
        }
      }

      .default-label.card-input {
        height: 50px;
        width: 100%;
        border-radius: 8px;
        margin-bottom: 16px;

        // background-color: $brdr_clr2;
        .input-container {
          height: 50px;
          width: 100%;
          border-radius: 8px;
          border: 1px solid $brdr_clr13;
          background-color: $brdr_clr2;

          input {
            border-radius: 8px;
            text-align: left;
            height: 100%;
            width: 100%;
            background-color: $brdr_clr2;
            padding: 19px 16px;

            &::placeholder {
              @include setFont('IBM Plex Sans', 400, 1.063rem, 150%);
              color: $txt_clr16;
            }
          }
        }
      }
    }

    .payment-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid $brdr_clr3;
      border-radius: 8px;
      width: 100%;
      padding: 20px;
      margin-bottom: 8px;
      cursor: pointer;
      transition: background-color 200ms;
      -webkit-transition: background-color 200ms;

      .count-balance {
        @include setFont('IBM Plex Sans', 400, 0.9375rem, 150%);
      }

      .left-block-txt {
        display: flex;
        align-items: center;

        .radio-btn {
          border: 2px solid $brdr_clr5;
          border-radius: 50%;
          width: 22px;
          height: 22px;
          background-color: $bg_clr0;
          transition: border 200ms, background-color 200ms;
          -webkit-transition: border 200ms, background-color 200ms;
        }

        .title-radio-btn {
          @include setFont('IBM Plex Sans', 600, 0.9375rem, 150%);
          margin-left: 12px;
        }
      }

      &.active {
        background: $bg_clr2;
        transition: background-color 200ms;
        -webkit-transition: background-color 200ms;

        .title-radio-btn {
          color: $txt_clr5;
        }

        .radio-btn {
          border: 6px solid $brdr_clr7;
          width: 22px;
          height: 22px;
          background-color: $bg_clr2;
          transition: border 200ms, background-color 200ms;
          -webkit-transition: border 200ms, background-color 200ms;
        }
      }
    }

    .user-info-block {
      justify-content: center;
      padding-bottom: 30px;

      .user-title {
        font-weight: 600;
      }
    }

    .title-payment {
      @include setFont('IBM Plex Sans', 600, 1.3125rem, 2.1875rem);
      padding-bottom: 34px;
    }
  }

  .modal-dividend-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      @include setFont('IBM Plex Sans', 600, 1.315rem, 2.138rem);
      text-align: center;
    }

    .amount-count {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      @include setFont('IBM Plex Sans', 500, 1.75rem, 2.75rem);

      span {
        padding-left: 5px;
      }

      .number-converter {
        justify-content: center;
      }
    }

    .operation {
      @include setFont('IBM Plex Sans', 600, 1.313rem, 2.138rem);
      text-align: center;
    }

    .issuing {
      color: $txt_clr10;
    }

    .burning {
      color: $txt_clr14;
    }

    .sending {
      color: $txt_clr4;
    }

    .cash {
      @include setFont('IBM Plex Sans', 500, 1.313rem, 2.138rem);
      display: flex;
      align-items: flex-end;
      text-align: center;
      color: $txt_clr4;
    }

    .enter-pin {
      @include setFont('IBM Plex Sans', normal, 0.913rem, 1.238rem);
      color: $txt_clr4;
      opacity: 0.75;
      margin-top: 30px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }

    .password-remind {
      @include setFont('IBM Plex Sans', normal, 0.913rem, 1.238rem);
      text-align: center;
      color: $txt_clr8;
    }

    .memo-wrapper,
    .to-wrapper {
      display: flex;
      flex-direction: row;
      width: 100%;
    }

    .memo-field,
    .to-field {
      @include setFont('IBM Plex Sans', normal, 0.913rem, 1.238rem);
      width: 16px;
      margin-right: 62px;
      display: flex;
      align-items: flex-end;
      color: $txt_clr3;
    }

    .memo-wrapper {
      display: flex;
      align-items: center;
      padding: 0 34px;
      min-height: 55px;
      border-bottom: 1px solid $brdr_clr1;

      .memo-info-field {
        word-break: break-word;
        @include setFont('IBM Plex Sans', normal, 0.913rem, 1.238rem);
        display: flex;
        align-items: flex-end;
        color: $txt_clr3;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    .to-wrapper {
      min-height: 55px;
      margin-top: 35px;

      .to-info-field {
        @include setFont('IBM Plex Sans', normal, 0.913rem, 1.238rem);
        color: $txt_clr4;
        word-break: break-word;
        padding-top: 10px;
        padding-bottom: 10px;
      }

      > span {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .rectangle-wrapper {
      width: 100%;
      height: 58.5px;
      background: rgba(248, 208, 79, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 35px;
      margin-top: 10px;

      .rectangle-wrapper-text {
        @include setFont('IBM Plex Sans', normal, 0.913rem, 1.238rem);
        color: $txt_clr4;
      }
    }

    .modal-header {
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 1.313rem;
      line-height: 1.938rem;
      font-weight: 500;
      text-align: center;
    }

    .row-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      width: 100%;
      min-height: 55px;
      height: 100%;
      border-bottom: 1px solid $brdr_clr1;

      &.rate {
        border-top: 1px solid $brdr_clr1;
        margin-top: 35px;
      }

      &.pay-from {
        min-height: 75px;

        svg {
          margin-left: auto;
          margin-right: 22px;
        }
      }

      p {
        display: flex;
        align-content: center;
        @include setFont('IBM Plex Sans', normal, 0.9375rem, 150%);
      }

      span.name-field {
        display: flex;
        align-content: center;
        @include setFont('IBM Plex Sans', normal, 0.913rem, 1.238rem);
        color: $txt_clr3;
        padding-left: 32px;
        min-width: 173px;
        padding-right: 30px;
        white-space: nowrap;
      }
    }
  }
  .requirement-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 80%;
  }
  .copy-field-container.blue-theme .visible-part .copy-content > span {
    max-width: 250px;
  }
  .requirement-wrapper span.name-field {
    display: flex;
    align-content: center;
    @include setFont('IBM Plex Sans', normal, 0.913rem, 1.238rem);
    color: $txt_clr3;
    padding-left: 32px;
    min-width: 173px;
    padding-right: 30px;
    text-align: center;
  }
  .copy-field-container.blue-theme .visible-part {
    cursor: pointer;
    background-color: $blue6;
    border: 2px solid $blue3;
  }
  .copy-field-container {
    margin-top: 10px;
    margin-bottom: 25px;
  }
  .copy-field-container:nth-child(2n) {
    margin-bottom: 25px;
  }
  .highlight__span {
    color: $blue0;
  }

  @media only screen and (max-width: 770px) {
    .convert-wcru-content {
      height: 100%;
      overflow: auto;
    }
    .payment-block {
      padding: 16px;
      max-width: initial;
      width: 100%;
      top: 1%;

      .inputs-container .with-bg.blue {
        margin-bottom: 6px;
      }

      .arrow-back {
        padding: 13px;
      }

      .header-title {
        margin-left: 16px;
      }

      .cards-block {
        flex-direction: column;
        min-height: 280px;
        margin-top: 7px;
        min-width: initial;

        .data-cvv-card {
          top: initial;
          bottom: -23px;

          .card-content {
            flex-direction: row-reverse;
            align-items: center;
            justify-content: flex-start;
            margin-top: 72px;
            padding-bottom: 23px;

            .input-subtitle {
              max-width: 141px;
              text-align: right;
            }

            .default-label.card-cvv {
              margin-left: 20px;
            }
          }
        }
      }

      .inputs-container {
        margin-top: 40px;
      }
    }
  }
  @media only screen and (max-width: 340px) {
    .payment-block .header-payment {
      .arrow-back {
        padding: 10px;
      }

      .header-title {
        font-size: 1.13rem;
      }
    }
    .payment-block .cards-block .input-container input {
      font-size: 0.875rem;
    }
    .payment-block .cards-block .data-cvv-card {
      min-width: initial;
    }
    .payment-block .cards-block .data-card .sm-inputs .default-label.card-thru {
      width: initial;
    }
    .payment-block .cards-block .data-card {
      .default-label.card-number {
        width: 100%;
      }
    }
  }
}
