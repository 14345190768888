@import '../../../variables';

@media only screen and (min-width: $desktopScreen) {
  .right-block-wrapper .recent-trx-block .link-history:hover {
    opacity: 1;
    transition: opacity 200ms;
    -webkit-transition: color 200ms;
  }
}
.recent-block {
  cursor: pointer;
}
.right-block-wrapper {
  width: 100%;
  margin-left: 20px;
  min-width: 324px;
  max-width: 325px;

  .recent-trx-block {
    display: flex;
    flex-direction: column;

    .block-title {
      @include setFont('IBM Plex Sans', 600, 1.3125rem, 2.1875rem);
      color: $txt_clr4;
      margin-bottom: 25px;
    }

    .trx-block-content {
      display: flex;
      flex-direction: column;
      border: 1px solid $brdr_clr1;
      border-radius: 9px;
      .msg-printer-view {
        text-align: center;
      }

      .process-msg {
        font-size: 1rem;
        padding: 20px 0px;
      }

      .no-transactions {
        font-size: 0.85rem;
      }
    }

    .link-history {
      @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
      color: $txt_clr5;
      padding: 18px;
      text-align: center;
      opacity: 0.75;
      transition: opacity 200ms;
      -webkit-transition: color 200ms;
    }
  }
  .staking-pool {
    @extend .recent-trx-block;
    margin-bottom: 39px;
    .staking-content {
      @extend .trx-block-content;
      box-shadow: 0px 4px 4px rgba(119, 119, 119, 0.05);
      padding: 17px 17px 23px 17px;
      .count-token {
        @include setFont('IBM Plex Sans', 400, 1.75rem, 2.938rem);
        color: $txt_clr4;
        text-align: center;
      }
      .lines-wrapper {
        margin-bottom: 0;
        margin-top: 0;

        .lines-container {
          max-width: initial;
        }
        .active-line {
          background-color: $bg_clr8;
        }
      }
    }
  }
  @media only screen and (max-width: 1152px) {
    width: 100%;
    max-width: 792px;
  }
  @media only screen and (max-width: $tabletScreen) {
    .recent-trx-block,
    .staking-pool {
      margin-left: 0;
      margin-top: 20px;
    }
  }
  @media only screen and (max-width: $mobileScreen) {
    width: 100%;
    margin-left: 0;
    margin-top: 15px;
    .recent-trx-block .title-page {
      text-align: center;
      text-transform: uppercase;
    }
  }
  @media only screen and (max-width: 335px) {
    .recent-trx-block,
    .staking-pool {
      min-width: 320px;
    }
  }
}
