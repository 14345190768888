@import '../../../variables';

.empty-numbers-pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 50px;
  padding-top: 40px;
}

@media only screen and (min-width: $desktopScreen) {
  .pagination-block .pagination-content {
    button:hover,
    div:hover {
      background: $bg_clr2;
      transition: color 200ms;
      -webkit-transition: color 200ms;
    }
  }
}

.pagination-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: auto;
  padding-bottom: 50px;
  padding-top: 40px;

  .count-pages {
    @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.1875rem);
    white-space: pre-wrap;

    .count-txt {
      color: $txt_clr3;
    }
  }

  .pagination-content {
    display: flex;
    @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.1875rem);

    div:first-child,
    div:last-child {
      margin: 0 10px;
    }

    div:first-child {
      margin-left: 4px;
    }

    div:not(:first-child),
    div:not(:last-child) {
      margin: 4px 3px 4px 0;
    }

    div {
      border-radius: 9px;
      padding: 6px 8px;
      cursor: pointer;
      margin: 4px 3px 4px 0;
    }

    div.active {
      background-color: $bg_clr1;
    }

    .click-btn:disabled {
      @extend .pagin-btn;
      color: $txt_clr3;
      pointer-events: none;
    }
  }

  &.top-pagination {
    margin-top: initial;
  }
}
