@import '../../../variables';

$graphItem: rgba(117, 133, 150, 0.2);
$firstItemBg: #3f86e3;

@media only screen and (min-width: $desktopScreen) {
  .vertical-graph li:hover {
    transition: color 200ms, box-shadow 200ms;
    -webkit-transition: color 200ms, box-shadow 200ms;
    background-color: $bg_clr0;
    border: 2px solid $brdr_clr8;
    box-shadow: $shadowPart_2 rgba(66, 147, 246, 0.15), $shadowPart_3 rgba(66, 147, 246, 0.15),
      $shadowPart_4 rgba(66, 147, 246, 0.15), $shadowPart_5 rgba(66, 147, 246, 0.15);

    &:hover .info-block {
      display: block;
    }
  }
}

.vertical-graph {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 50px;
  min-width: 50px;
  padding: 0;
  margin: 0;

  li {
    cursor: pointer;
    height: 8px;
    min-width: 20%;
    background-color: $graphItem;
    margin-bottom: 8px;
    position: relative;
    //transition: width 100ms linear;

    &.active {
      background-color: $firstItemBg;
    }

    .info-block {
      position: absolute;
      right: 100%;
      top: -50%;
      padding-right: 5px;
      margin-top: -10px;
      display: none;

      .content-wrapper {
        height: 32px;
        padding: 4px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: $bg_clr0;
        border: 1px solid $brdr_clr1;
        box-shadow: 0px 4px 4px rgba(119, 119, 119, 0.05), 0px 8px 8px rgba(119, 119, 119, 0.05),
          0px 16px 16px rgba(119, 119, 119, 0.05), 0px 32px 32px rgba(119, 119, 119, 0.05);
        border-radius: 5px;
        @include setFont('IBM Plex Sans', 500, 0.9375rem, 1.375rem);
      }

      .info-tx {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        padding: 4px 13px;
        white-space: nowrap;
        height: 100%;
        background: $bg_clr1;
        border-radius: 3px;
        color: $txt_clr4;
      }

      .info-number {
        margin: 0 13px;
        color: $txt_clr7;
      }
    }
  }
}
