@import '../../../variables';

.wrapper {
  width: 100%;
  height: 110px;

  .custom-tooltip {
    opacity: 0.75;
    box-shadow: $shadowPart_2 rgba(119, 119, 119, 0.05), $shadowPart_3 rgba(119, 119, 119, 0.05),
      $shadowPart_4 rgba(119, 119, 119, 0.05), $shadowPart_5 rgba(119, 119, 119, 0.05);
    min-width: 150px;
    background: $bg_clr0;
    border: 1px solid $brdr_clr1;
    border-radius: 5px;
    padding: 8px 12px;

    .row-tooltip {
      @include setFont('IBM Plex Sans', 400, 0.6875rem, 1rem);

      .label {
        color: $txt_clr3;
        margin-right: 5px;
        padding-bottom: 4px;

        &:last-child {
          margin-bottom: initial;
        }
      }

      .data-label {
        font-weight: 500;
        color: $txt_clr4;
      }
    }
  }
}
