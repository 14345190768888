@import '../../../variables';

.nested-route.wallet-staking {
  width: 1182px !important;

  .wallet-staking-block {
    .staking-content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .card-staking-wrapper {
        display: flex;
        flex-direction: column;
        background: $bg_clr0;
        border: 1px solid $brdr_clr1;
        box-shadow: 0px 4px 4px $shadow_clr2;
        border-radius: 16px;
        padding: 23px 24px 11px 23px;
        max-width: 578px;
        width: 100%;
        max-height: 364px;
        min-height: 348px;
        margin-bottom: 25px;
        &.untb-card {
          .staked-amount-card {
            margin-top: auto;
          }
        }
        &:nth-child(odd) {
          margin-right: 24px;
        }

        .subtitle-card {
          @include setFont('IBM Plex Sans', 400, 0.938rem, 1.375rem);
          color: $txt_clr3;
        }
        .header-card {
          display: flex;
          flex-direction: column;
          position: relative;
          width: 100%;
          padding-bottom: 18px;
          .title-card {
            @include setFont('IBM Plex Sans', 600, 1.5rem, 2.5rem);
          }

          .card-icon {
            max-width: 131px;
            max-height: 74px;
            position: absolute;
            right: 0;
            top: 0;
          }
        }
        .reward-card {
          padding-bottom: 35px;
          .count-reward {
            @include setFont('IBM Plex Sans', 500, 1.313rem, 1.938rem);
          }
        }
        .staked-amount-card {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .col-amount {
            display: flex;
            flex-direction: column;
          }
          .subtitle-card {
            padding-bottom: 3px;
          }
          .count-row,
          span:last-child {
            @include setFont('IBM Plex Sans', 400, 1.063rem, 1.313rem);
            color: $txt_clr4;
            opacity: 1;
          }
          .right-col {
            text-align: right;
          }
        }
        .available-row {
          display: flex;
          align-items: center;
          justify-content: center;
          @include setFont('IBM Plex Sans', 400, 0.938rem, 1.313rem);
          color: $txt_clr3;
          .count-row:nth-child(2) {
            opacity: 1;
            padding-right: 4px;
          }
          .tokne-name {
            padding-right: 4px;
          }
        }

        .card-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          background: $bg_clr6;
          border-radius: 7px;
          margin-bottom: 11px;
          margin-top: 24px;
          margin-left: 0;
          border: none;

          &:hover {
            border: none;
          }
          svg {
            width: 26px;
            height: 25px;
            path {
              stroke: $bg_clr0;
            }
          }
          .title-btn {
            @include setFont('IBM Plex Sans', 500, 1.063rem, 1.563rem);
            color: $txt_clr0;
            text-transform: initial;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    width: initial !important;

    .title-page {
      margin-bottom: 24px;
    }
    .wallet-staking-block {
      align-items: center;
      justify-content: center;
      .staking-content {
        justify-content: center;
        .card-staking-wrapper {
          margin-left: 10px;
          margin-right: 10px;
          &:nth-child(odd) {
            margin-right: 10px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: $mobileScreen) {
    .wallet-staking-block .staking-content .card-staking-wrapper {
      max-width: initial;
      max-height: initial;
      &.untb-card {
        .header-card .card-icon {
          width: 48px;
          height: 48px;
          top: -7px;
        }
        .staked-amount-card {
          .right-col {
            margin-top: 20px;
          }
        }
      }
      .reward-card {
        padding-bottom: 15px;
      }
      .staked-amount-card {
        flex-direction: column;
        align-items: initial;
        .right-col {
          text-align: initial;
          margin-top: 10px;
        }
      }
      .header-card {
        padding-bottom: 40px;
        .subtitle-card {
          display: none;
        }
        .card-icon {
          width: 85px;
          height: 48px;
          top: -7px;
        }
      }
    }
  }
  @media only screen and (max-width: 340px) {
    .wallet-staking-block .staking-content .card-staking-wrapper .header-card .title-card {
      font-size: 1.325rem;
    }
    .wallet-staking-block .staking-content .card-staking-wrapper .available-row {
      flex-wrap: wrap;
    }
    .wallet-staking-block .staking-content .card-staking-wrapper .reward-card .count-reward {
      font-size: 1.2rem;
    }
  }
}
