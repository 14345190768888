@import '../../../variables';

.tokens-table-wrapper {
  padding: 0 15px;
}

.tokens-table {
  border-collapse: collapse;

  tr {
    &.with-pointer {
      cursor: pointer;
      &:hover {
        background-color: rgb(231, 250, 250);
      }
    }

    .row-container {
      padding-right: 10px;

      .count-block,
      .title-block {
        justify-content: flex-end;
        .dot-title {
          background-color: #80d683;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          margin-left: 8px;
        }
      }
    }
  }

  td {
    padding: 10px 0;
  }
  td:nth-child(2) {
    padding: 10px;
  }

  .logo-wrapper {
    position: relative;
  }

  .selected-row-marker {
    position: absolute;
    top: 0;
    left: -10px;
    width: 5px;
    height: 100%;
    min-height: 100%;
    background-color: $brdr_clr7;
    border-radius: 2px;
  }

  .card-icon {
    display: flex;
    width: 36px;
    min-width: 36px;
    height: 36px;
    min-height: 36px;
    background-position: center;
    background-repeat: repeat;
    background-size: contain;
  }

  .tok-title {
    @include setFont('Montserrat', 600, 1.125rem, 1rem);
    text-transform: uppercase;
  }
}

.block-container {
  background-color: #f8f9fb;
  padding-top: 64px;
  max-width: 501px;

  .top-block {
    display: flex;
    flex-direction: column;
    padding-bottom: 95px;

    .title-top-block {
      margin-bottom: 30px;
      padding-left: 60px;
    }
  }

  @media only screen and (max-width: $desktopScreen) {
    .top-block {
      .title-top-block,
      .card-wrapper {
        padding-left: 20px;
        padding-right: 15px;
      }
    }

    .blockchain-block {
      padding-left: 20px;
      padding-right: 15px;
    }
  }
}
