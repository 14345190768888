@import '../../../variables';

.custom-checkbox {
  label {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
    color: $txt_clr3;

    span {
      margin-left: 13px;
    }

    input {
      display: none;
    }

    .fake-checkbox {
      width: 18px;
      min-width: 18px;
      min-height: 18px;
      height: 18px;
      border: 1px solid $brdr_clr4;
      border-radius: 4px;

      &.box-checked {
        background-color: $bg_clr7;
        border: initial;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          margin-top: -5px;
          left: 50%;
          margin-left: -6px;
          width: 9px;
          height: 4px;
          border-top: 1px solid transparent;
          border-right: 1px solid transparent;
          border-bottom: 1.5px solid $brdr_clr0;
          border-left: 1.5px solid $brdr_clr0;
          transform: rotate(-50deg);
        }
      }
    }
  }
}
