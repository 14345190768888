@import '../../../variables';

.input-row-block {
  width: 100%;

  .title-row {
    color: $txt_clr4;
  }

  .subtitle-row,
  .subtitle-input {
    color: $txt_clr3;
  }

  &.input-with-bg {
    .label-input {
      @include setFont('IBM Plex Sans', 400, 0.8125rem, 1.1875rem);
      color: $txt_clr4;
      opacity: 0.75;
    }

    .input-block {
      .default-label {
        height: 64px;
        background-color: $bg_clr2;
        border-radius: 8px;
        color: $txt_clr4;
        @include setFont('IBM Plex Sans', 400, 1.0625rem, 1.5625rem);
        padding: 19px 16px;

        .input-container input {
          @include setFont('IBM Plex Sans', 400, 1.0625rem, 1.5625rem);
          color: $txt_clr4;
          background-color: $bg_clr2;
        }
      }

      .balance-row {
        margin-top: 13px;
        margin-bottom: 13px;

        .count-token,
        .token-name {
          text-decoration: underline;
        }
      }
    }
  }
}
