@import '../../../variables';

.wallet-service {
  .wrapper-with-progress {
    position: relative;
    border-radius: 8px 8px 0 0;

    .progress-wrapper {
      position: absolute;
      top: 0;
      z-index: 11;
      margin-bottom: 0;
      width: 100%;

      .create-status-line {
        background-color: $bg_clr6;
        height: 5px;
        border-radius: 8px 0px 0px 0px;
      }
    }

    .steps-container {
      .storage-type-block {
        .storage-types-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          text-align: center;
          margin: 24px 0;

          .storage-type-container {
            padding: 15px;
            width: 100%;
            height: 180px;
            border: 1px solid $brdr_clr3;
            box-sizing: border-box;
            border-radius: 8px;
            cursor: pointer;

            &.active {
              border-color: $brdr_clr5;
              background-color: $brdr_clr0;
            }
          }
        }
      }

      .subtitle-row {
        @include setFont('IBM Plex Sans', 400, 0.9375rem, 150%);
        color: $txt_clr4;
        padding-bottom: 40px;
        padding-top: 20px;
        text-align: center;
      }

      .recovery-phrase {
        .words-wrapper {
          border: 1px solid $brdr_clr1;
          border-radius: 8px;

          &.for-choice {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;
            min-height: 134px;
            margin-bottom: 30px;
            flex-direction: row;
            flex-wrap: wrap;

            .title-block {
              @include setFont('IBM Plex Sans', 400, 1.0625rem, 150%);
              color: $txt_clr3;
              opacity: 0.5;
            }
          }

          .words-content {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            padding-top: 30px;
            padding-left: 40px;
            padding-right: 40px;

            .msg {
              margin: 0 auto 30px auto;
            }
          }

          .copy-btn {
            @include setFont('IBM Plex Sans', 500, 1.0625rem, 150%);
            text-align: center;
            color: $txt_clr5;
            padding-top: 14px;
            padding-bottom: 14px;
            border-top: 1px solid $brdr_clr1;
            cursor: pointer;
          }
        }

        .word-item {
          border-radius: 6px;
          min-height: 29px;
          padding: 3px 8px;
          margin-right: 12px;

          &.recovery-item {
            background: $bg_clr1;
            margin-bottom: 30px;
            margin-right: 15px;
            cursor: pointer;
          }

          &.choose-item {
            border: 1px solid $brdr_clr3;
            margin-bottom: 12px;
            cursor: pointer;
            transition: box-shadow 350ms;
            -webkit-transition: box-shadow 350ms;

            &:hover {
              transition: box-shadow 350ms;
              -webkit-transition: box-shadow 350ms;
              box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08), 0px 4px 4px rgba(119, 119, 119, 0.05);
            }

            &.selected {
              // for selected word
              background: $bg_clr1;
              border: 1px solid $brdr_clr1;

              .word {
                color: transparent;
              }
            }
          }

          .num-word {
            @include setFont('IBM Plex Sans', 00, 0.9375rem, 150%);
            color: $txt_clr3;
            padding-right: 4px;
          }

          .word {
            @include setFont('IBM Plex Sans', 500, 0.9375rem, 150%);
            color: $txt_clr4;
          }
        }

        .choose-words {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          max-width: 420px;
          margin: 0 auto;

          .inactive {
            background: $bg_clr4;
            pointer-events: none;
            .word {
              opacity: 0;
            }
          }

          .word-item {
          }
        }
      }

      .done-verify-phrase {
        display: flex;
        align-items: center;
        flex-direction: column;

        svg {
        }

        .checkbox-wrapper {
          display: flex;
          align-items: center;

          .label-check-box {
            @extend .checkbox-wrapper;
            flex-wrap: wrap;

            span {
              padding-right: 4px;
              @include setFont('IBM Plex Sans', 400, 0.9375rem, 150%);
            }

            .link-label {
              color: $txt_clr5;
            }
          }
        }
      }

      .inline-block {
        display: inline-block;
        width: 100%;
      }
    }
  }

  .account-input-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 52px;
    max-width: 282px;
    background-color: $bg_clr2;
    border-radius: 12px;
    padding-left: 16px;
    padding-right: 8px;
    margin-left: 90px;

    &.active-search {
      background-color: $bg_clr0;
      border: 2px solid $brdr_clr3;

      .default-label .input-container input {
        opacity: 1;
        color: $txt_clr4;
        background-color: $bg_clr0;
      }
    }

    .default-label {
      width: 100%;

      &:after {
        background-color: transparent;
      }

      .input-container {
        display: flex;
        align-items: center;
        width: 100%;

        input {
          width: 100%;
          @include setFont('Montserrat', 400, 0.9375rem, 1.0625rem);
          background-color: $bg_clr2;
          color: $txt_clr3;
          opacity: 0.5;

          //&:focus {
          //  opacity: 1;
          //  color: $txt_clr4;
          //  background-color: $bg_clr0;
          //}
        }
      }
    }

    > svg,
    .clear-icon {
      cursor: pointer;
    }
  }

  .back-btn {
    @include setFont('IBM Plex Sans', 500, 1.0625rem, 1.5625rem);
    color: $txt_clr0;
    padding-bottom: 16px;
    cursor: pointer;
    user-select: none;
  }
}

.wrapper-flex {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
}
.wrapper-flex-item {
  flex: 1;
  text-align: center;
  justify-content: center;
  align-items: center;

  margin: 0 auto;
  overflow: hidden;
}
.wrapper-flex-item.wide {
  min-width: 110px;
}
.circular__img {
  width: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  border: 1px solid #f5f0f0;
  border-radius: 50%;
}
.circular__img img {
  -webkit-filter: drop-shadow(1px 2px 4px #222);
  filter: drop-shadow(1px 2px 4px #7eb0f2);
  object-fit: contain;
  max-width: 30px;
  max-height: 30px;
}

.subtext {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.9375rem;
  line-height: 150%;
  font-weight: 700;
  margin-top: 15px;
  color: #5c5b5a;
  text-align: center;
}
.subheadline {
  margin-top: 0px;
  margin-bottom: 20px;
}
.MuiFormControl-root {
  width: 400px;
  max-width: 100%;
  border-radius: 25%;
}
.material__icon {
  margin-left: 5px;
  cursor: pointer;
}
.block-font.lg-size.third-color.privatekey {
  font-size: 13px;
}
.MuiFilledInput-root {
  border-radius: 10px;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}
.MuiInputBase-input {
  text-transform: lowercase;
}

.copy-field-container.blue-theme.light .visible-part {
  background-color: #f0fbff;
  border: 2px solid $blue3;
}

.copy-field-container.blue-theme .visible-part .copy-content > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
}

.d-flex.align-items-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.copy-field-container.blue-theme.light.short {
  max-width: 300px;
}
.wallet-service .copy-field-container:nth-child(1) {
  margin-bottom: 0px;
}

@media only screen and (max-width: 768px) {
  .wallet-service .copy-field-container {
    justify-content: center;
    display: flex;
  }
  .circular__img {
    height: 30px;
    width: 30px;
  }
  .wrapper-flex-item.wide {
    min-width: auto;
  }
  .MuiFormControl-root {
    width: 80vw;
    max-width: 100%;
  }
  .copy-field-container.blue-theme.light .visible-part {
    width: 80vw;
    max-width: 100%;
    overflow: hidden;
  }
}
