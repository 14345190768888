@import '/../../../variables';

%btn-main {
  border-radius: 7px;
  height: 39px;
  margin-bottom: 10px;
  opacity: 1;
  border: initial;
  @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
  color: $txt_clr0;
  margin-left: 10px;
  text-transform: initial;
}

button.click-btn {
  &.rel {
    position: relative;
  }

  .spinner-preloader {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -20px 0 0 -20px !important;
    width: 40px;
    height: 40px;
  }
}

@media only screen and (min-width: $desktopScreen) {
  .click-btn:hover {
    opacity: 1;
    transition: color 200ms, opacity 200ms, border-color 200ms, box-shadow 200ms;
    -webkit-transition: color 200ms, opacity 200ms, border-color 200ms, box-shadow 200ms;
    border-color: #bac2ca;

    .title-btn {
      opacity: 1 !important;
    }
  }
  .click-btn.play-btn:hover {
    background: $bg_clr7;
    box-shadow: $shadowPart_2 rgba(52, 94, 169, 0.15), $shadowPart_3 rgba(52, 94, 169, 0.15),
      $shadowPart_4 rgba(52, 94, 169, 0.15), $shadowPart_5 rgba(52, 94, 169, 0.15);
  }
  .click-btn.stake-btn:hover {
    box-shadow: 0px 4px 4px rgba(52, 148, 89, 0.2), 0px 8px 8px rgba(52, 148, 89, 0.15), 0px 16px 16px rgba(52, 148, 89, 0.15),
      0px 32px 32px rgba(52, 148, 89, 0.15);
  }
  .click-btn.send-btn:hover {
    box-shadow: $shadowPart_2 rgba(52, 94, 169, 0.15), $shadowPart_3 rgba(52, 94, 169, 0.15),
      $shadowPart_4 rgba(52, 94, 169, 0.15), $shadowPart_5 rgba(52, 94, 169, 0.15);
  }
}

.click-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  @include setFont('Montserrat', 600, 0.6875rem, 0.8125rem);
  text-transform: uppercase;
  min-height: 36px;
  width: 68px;
  border: 1px solid $brdr_clr1;
  border-radius: 9px;
  cursor: pointer;
  background-color: $bg_clr0;
  padding: 10px;
  margin-left: 10px;
  transition: color 200ms, opacity 200ms, border-color 200ms, box-shadow 200ms;
  -webkit-transition: color 200ms, opacity 200ms, border-color 200ms, box-shadow 200ms;

  &:disabled {
    opacity: 0.7;
    pointer-events: none;
  }

  .title-btn {
  }

  &.right-arrow-btn {
    background-color: $green3;
    border-radius: 50%;
    border: none;
  }

  .btn-icon {
    // margin-right: 9px;
  }

  &.more-btn {
    width: 100%;
    min-height: 48px;
    margin: 20px 0;
    opacity: 1;

    .title-btn {
      @include setFont('IBM Plex Sans', 500, 0.9375rem, 1.375rem);
      color: $txt_clr7;
      margin-left: initial;
      text-transform: initial;
    }
  }

  span {
    //margin-left: 9px;
  }

  svg {
    width: 6.5px;
    min-width: 6.5px;
    height: 11.5px;
    min-height: 11.5px;
  }

  &.pause-btn {
    width: 36px;
    height: 36px;

    svg {
      width: 17.5px;
      min-width: 17.5px;
      height: 17.5px;
      min-height: 17.5px;
      margin-right: 0;
    }
  }

  &.play-btn {
    opacity: 1;
    background: #3f86e3;
    box-shadow: $shadowPart_2 rgba(66, 147, 246, 0.15), $shadowPart_3 rgba(66, 147, 246, 0.15),
      $shadowPart_4 rgba(66, 147, 246, 0.15), $shadowPart_5 rgba(66, 147, 246, 0.15);
    width: 36px;
    height: 36px;
    border: initial;
    transition: box-shadow 200ms, color 200ms;
    -webkit-transition: box-shadow 200ms, color 200ms;

    svg {
      width: 10px;
      min-width: 10px;
      height: 12px;
      min-height: 12px;
      margin-right: initial;
    }
  }

  &.prev-btn {
    width: initial;
    &:disabled {
      pointer-events: none;

      svg {
        opacity: 0.2;
      }
    }
  }

  &.next-btn {
    width: initial;
    flex-direction: row-reverse;
    // padding-right: 4px;

    &:disabled {
      @extend .next-btn;
      pointer-events: none;

      svg {
        opacity: 0.2;
      }
    }

    span {
      margin-left: 0;
      margin-right: 9px;
    }

    svg {
      transform: rotate(180deg);
      width: 15px;
    }
  }

  &.pagin-btn {
    @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.1875rem);
    text-transform: initial;
    border: initial;
    opacity: 1;
    // width: 48px;
    width: 100%;
    padding: 8px 10px;
    margin-right: 4px;

    .title-btn {
      opacity: initial;
      margin-left: initial;
    }
  }

  &.with-bg {
    border-radius: 12px;
    color: $txt_clr0;
    border: initial;
    width: 100%;

    &.delete {
      background: $bg_clr12;
    }

    &.blue {
      background: $bg_clr6;
      height: 53px;
      text-transform: initial;
      margin-left: 0;

      .title-btn {
        @include setFont('IBM Plex Sans', 500, 1.0625rem, 1.5625rem);
        margin-left: 0;
      }
    }

    &.active {
      opacity: 1;

      .title-btn {
        opacity: 1;
      }
    }
  }

  &.circle-btn {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: $bg_clr1;
    border: initial;
    margin-left: 0;
    transition: background-color 350ms, box-shadow 350ms, stroke 350ms;
    -webkit-transition: background-color 350ms, box-shadow 350ms, stroke 350ms;

    svg path {
      transition: stroke 350ms;
      -webkit-transition: stroke 350ms;
    }

    .btn-icon {
      min-height: 25px;
      min-width: 26px;
      margin-right: 0;
    }

    .title-btn {
      opacity: 1;
    }

    &:hover {
      background-color: $bg_clr6;
      box-shadow: 0px 32px 32px rgba(52, 94, 169, 0.15), 0px 16px 16px rgba(52, 94, 169, 0.15),
        0px 8px 8px rgba(52, 94, 169, 0.15), 0px 4px 4px rgba(52, 94, 169, 0.15);
      transition: background-color 350ms, box-shadow 350ms, stroke 350ms;
      -webkit-transition: background-color 350ms, box-shadow 350ms, stroke 350ms;
      cursor: pointer;

      svg path {
        stroke: $brdr_clr0;
        transition: stroke 350ms;
        -webkit-transition: stroke 350ms;
      }
    }
  }

  //&.send-btn {
  //  @extend %btn-main;
  //  background-color: $bg_clr7;
  //  width: 125px;
  //
  //  .btn-icon {
  //    min-height: 15px;
  //    min-width: 15px;
  //  }
  //
  //  .title-btn {
  //    opacity: 1;
  //  }
  //}

  &.like-btn {
    &.favourite {
      background-color: $bg_clr10;
    }
    &:hover {
      .btn-icon {
        path {
          stroke: unset;
          fill: #fff;
        }
      }
    }
  }
}
