@import '../../../variables';

@media only screen and (min-width: $desktopScreen) {
  .home-page .centered-block .emission-block .progress-row .progress-scale:hover .info-block {
    display: flex;
    box-shadow: 0px 4px 4px rgba(119, 119, 119, 0.05), 0px 8px 8px rgba(119, 119, 119, 0.05), 0px 16px 16px rgba(119, 119, 119, 0.05), 0px 32px 32px rgba(119, 119, 119, 0.05);
    transition: color 200ms, box-shadow 200ms;
    -webkit-transition: color 200ms, box-shadow 200ms;
  }
}

.home-page {
  display: flex;
  flex-direction: row;

  .card-slider {
    display: none;

    .card-slider-wrapper {
      margin-top: 25px;
      padding-bottom: 61px;

      .card-wrapper {
        padding-left: 17px;
        padding-right: initial;

        &.active {
          border-left: initial;
        }

        .card-token {
          width: 232px;
          height: 115px;
          min-width: initial;
          padding: 20px;
          border-radius: 18px;

          .headers-title {
            align-items: initial;
            padding-top: 10px;

            .card-icon {
              background-size: cover;
              width: 42px;
              min-width: 42px;
              min-height: 42px;
              height: 42px;
            }

            .card-title {
              flex-direction: column-reverse;

              .main-title {
                font-size: 1.3125rem;
                padding-left: 0;
              }

              .full-title {
                font-size: 0.8125rem;
              }
            }
          }
        }
      }
    }
  }

  .centered-block {
    position: relative;
    padding-top: 64px;
    border-right: 1px solid $brdr_clr4;
    width: 100%;
    min-height: calc(100vh - 81px);

    .top-content {
      display: flex;
      flex-direction: column;
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 45px;
      border-bottom: 1px solid $brdr_clr4;

      .main-title {
        padding-bottom: 30px;
      }

      .data-container {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;

        .row-container:not(:last-child) {
          margin-bottom: 15px;
        }

        .circulating-row {
          .title-block .dot-title {
            background-color: #46d24b;
          }
        }

        .staked-row {
          .title-block .dot-title {
            background-color: $bg_clr8;
          }
        }
      }
    }

    .emission-block {
      padding: 45px 30px;
      border-bottom: 1px solid $brdr_clr4;

      .emission-block-content {
        display: flex;
        //align-items: center;
        justify-content: space-between;
        padding-top: 30px;

        .main-title {
          @include setFont('Montserrat', 600, 0.6875rem, 0.8125rem);
          text-transform: uppercase;
        }

        .count-emission-block {
          @include setFont('IBM Plex Sans', 500, 1.0625rem, 1.5625rem);
          margin-bottom: 10px;
          margin-top: 10px;
        }

        .info-content {
          padding-bottom: 10px;
        }

        .current-block {
          .main-title {
            color: $txt_clr10;
          }

          .title {
            min-width: 80px;
          }
        }

        .coming-block {
          color: $txt_clr3;

          .main-title {
            color: $txt_clr3;
            opacity: 0.75;
          }

          .title {
            min-width: 108px;
          }
        }
      }

      .progress-row {
        display: flex;
        align-items: center;

        p {
          span {
            @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
            text-transform: initial;
          }
        }

        .progress-scale {
          position: relative;
          display: flex;
          align-items: center;
          flex-direction: row;
          max-width: 78px;
          height: 14px;
          width: 100%;
          margin-right: 15px;
          background-color: $bg_clr3;
          background-image: linear-gradient(to right, transparent 0%, transparent 50%, #fff 50%, #fff 100%);
          background-size: 4px 100%;

          .active-scale {
            height: 100%;
            width: 2px;
            margin-right: 2px;
            background-color: $bg_clr8;
            border-radius: 1px;
          }

          &:hover .info-block {
            display: flex;
            box-shadow: 0px 4px 4px rgba(119, 119, 119, 0.05), 0px 8px 8px rgba(119, 119, 119, 0.05), 0px 16px 16px rgba(119, 119, 119, 0.05), 0px 32px 32px rgba(119, 119, 119, 0.05);
            transition: color 200ms, box-shadow 200ms;
            -webkit-transition: color 200ms, box-shadow 200ms;
          }

          .info-block {
            display: none;
            flex-direction: row;
            align-items: center;
            position: absolute;
            bottom: 10px;
            left: -25px;
            height: 36px;
            min-width: 150px;
            max-width: 200px;
            padding: 8px 12px;
            background: $bg_clr0;
            opacity: 0.85;
            border: 1px solid $brdr_clr1;
            box-shadow: 0px 4px 4px rgba(119, 119, 119, 0.05), 0px 8px 8px rgba(119, 119, 119, 0.05), 0px 16px 16px rgba(119, 119, 119, 0.05), 0px 32px 32px rgba(119, 119, 119, 0.05);
            border-radius: 5px;
            @include setFont('IBM Plex Sans', 400, 0.8125rem, 1.1875rem);
            color: $txt_clr3;
            white-space: pre-wrap;

            .info-txt {
              font-weight: 500;
              white-space: nowrap;
              color: $txt_clr4;
              margin-left: 6px;
            }
          }
        }
      }
    }

    .center-content {
      border-bottom: 1px solid $brdr_clr4;
      padding: 45px 30px;

      .data-container {
        display: flex;
        padding-top: 35px;

        .row-container {
          padding-right: 45px;
        }
      }

      .headers-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include setFont('Montserrat', 600, 0.6875rem, 0.8125rem);
        text-transform: uppercase;
        letter-spacing: 0.4px;
        margin-bottom: 20px;
        margin-top: 40px;
      }
    }

    .bottom-content {
      display: flex;
      flex-direction: column;
      padding: 45px 30px;

      .update-btn {
        margin-top: 25px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
        @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
        color: $txt_clr3;
        padding: 10px;
        border-radius: 4px;
        border: 1px solid $brdr_clr1;
        align-self: flex-end;

        &:hover {
          opacity: 0.8;
        }

        svg {
          min-width: 24px;
          min-height: 24px;
        }

        &.active {
          opacity: 0.7;
          cursor: not-allowed;

          svg {
            //animation: 1s InfRotation infinite;
          }
        }

        + .table-wrapper {
          padding-top: 25px;
        }
      }

      .header-bottom-content {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 50px;

        .title-block {
          display: flex;
          flex-direction: column;

          .subtitle {
            @include setFont('Montserrat', 600, 0.6875rem, 0.8125rem);
            color: $txt_clr3;
            opacity: 0.75;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            padding-top: 10px;
            margin-bottom: 10px;
            white-space: pre-wrap;
          }
        }
      }

      .pagination-block {
        .pagination-content,
        .pagin-btn,
        .count-pages {
          font-size: 0.75rem;
        }

        .pagination-content {
          .click-btn.pagin-btn {
            padding: 6px 8px;
            margin-left: initial;

            &:last-child {
              margin-right: initial;
            }
          }
        }
      }

      .status-line-block {
        display: flex;
        flex-direction: column;

        .line {
          display: flex;
          align-items: center;
          flex-direction: row;
          height: 12px;
          width: 100%;
          margin-bottom: 25px;

          > div {
            height: inherit;

            &:first-child {
              border-radius: 10px 0 0 10px;
            }

            &:last-child {
              border-radius: 0 10px 10px 0;
            }

            &:not(:last-child) {
              border-right: 1px solid $brdr_clr0;
            }
          }

          .first-line {
            background-color: $bg_clr7;
            width: 30%;
          }

          .second-line {
            background-color: #51a596;
            width: 19%;
          }

          .third-line {
            background-color: #78ce72;
            width: 18%;
          }

          .fourth-line {
            background-color: #bbca48;
            width: 21%;
          }

          .fifth-line {
            background-color: $bg_clr11;
            width: 12%;
          }
        }

        .data-line-block {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;

          .line-info {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            margin-bottom: 15px;

            .info-dot {
              border-radius: 45px;
              width: 7px;
              height: 7px;
              margin-right: 10px;
            }

            &:nth-child(1) {
              .info-dot {
                background-color: $bg_clr7;
              }
            }

            &:nth-child(2) {
              .info-dot {
                background-color: #51a596;
              }
            }

            &:nth-child(3) {
              .info-dot {
                background-color: #78ce72;
              }
            }

            &:nth-child(4) {
              .info-dot {
                background-color: #bbca48;
              }
            }

            &:nth-child(5) {
              .info-dot {
                background-color: $bg_clr11;
              }
            }

            .line-count {
              display: flex;
              flex-direction: column;
              @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
              color: $txt_clr3;

              span:first-child {
                @include setFont('IBM Plex Sans', 400, 0.8125rem, 1.1875rem);
                padding-bottom: 2px;
              }
            }
          }
        }
      }

      .table-wrapper {
        padding-top: 50px;

        a {
          color: $txt_clr7;
        }
      }

      .more-btn {
        display: none;
      }
    }
  }

  .right-block {
    position: relative;
    padding-top: 64px;
    padding-left: 40px;
    padding-right: 40px;
    min-width: 320px;
    //width: 60%;
    margin: 0 auto;

    .direction-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .block-header {
      padding-bottom: 30px;
      @media only screen and (max-width: 340px) {
        flex-direction: column;
        align-items: initial;
        .link-title {
          padding-top: 10px;
        }
      }
    }

    img {
      width: 100%;
    }

    .block-number {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-wrap: wrap;
      flex-direction: row;
      min-width: 117px;
      padding-top: 40px;
      padding-bottom: 30px;

      .title-block {
        display: flex;
        flex-direction: column;
        min-width: 100px;
        margin-right: 10px;

        .head-block-title {
          @include setFont('Montserrat', 600, 0.6875rem, 0.8125rem);
          color: $txt_clr3;
          opacity: 0.75;
          text-transform: uppercase;
          letter-spacing: 0.6px;
          padding-bottom: 7px;
        }

        .number-block {
          @include setFont('Montserrat', 700, 1.3125rem, 1.3125rem);
          letter-spacing: 0.03em;
        }
      }

      .btn-list {
        display: flex;
        align-items: center;
        margin-top: 10px;

        & :first-child {
          margin-left: 0;
        }

        .click-btn:disabled {
          @extend .click-btn;

          svg {
            opacity: 0.2;
          }
        }
      }
    }

    .block-info-wrapper {
      .block-info {
        .status-block {
          @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
          color: $txt_clr0;
          background-color: $bg_clr10;
          border-radius: 5px;
          max-width: 114px;
          min-width: 100px;
          text-align: center;
          padding: 0 9px;
          margin-bottom: 16px;
        }

        .info-content {
          &:nth-child(3) {
            .data {
              font-size: 0.8125rem;
              line-height: 1.1875rem;
            }
          }

          .data {
            width: 100%;
            max-width: 177px;
            word-break: break-word;
            text-align: left;

            &.data-trx {
              color: $txt_clr7;
            }
          }
        }
      }
    }
  }

  .blockchain-block {
    display: none;
  }

  @media only screen and (min-width: 1441px) {
    .right-block {
      width: 60%;
    }
  }

  @media only screen and (max-width: 1441px) {
    .right-block {
      width: 80%;
    }
  }

  @media only screen and (max-width: 1330px) {
    .centered-block {
      .top-content,
      .center-content,
      .bottom-content {
        padding-left: 17px;
        padding-right: 17px;
      }
    }
    .right-block {
      padding-left: 17px;
      padding-right: 17px;
    }
  }

  @media only screen and (max-width: $desktopScreen) {
    .centered-block {
      .block-header {
        flex-wrap: wrap;

        .main-title {
          padding-bottom: 8px;
        }
      }
    }
  }

  @media only screen and (max-width: 1350px) {
    flex-wrap: wrap;
    .centered-block {
      border-bottom: 1px solid $brdr_clr4;
      border-right: initial;
      max-width: 100%;
    }
    .right-block {
      max-width: initial;
      width: 100%;

      .wrapper {
        max-width: initial;
      }
    }
  }

  @media only screen and (max-width: $mobileScreen) {
    flex-direction: column;
    .card-slider {
      display: block;

      .card-slider-wrapper .card-wrapper.active {
        border-left: initial;
      }
    }
    .centered-block {
      padding-top: 0;
    }
    .emission-block .emission-block-content {
      flex-wrap: wrap;

      .current-block {
        margin-bottom: 18px;
      }
    }
    .centered-block .top-content {
      padding-bottom: 40px;

      .direction-container {
        display: flex;
        flex-direction: row;

        .data-container {
          display: flex;
          flex-direction: column;
          position: relative;

          .row-container:nth-child(4) {
            position: absolute;
            right: 0;
            top: 11%;
          }

          .row-container:nth-child(2) {
            order: 1;
          }

          .row-container.green {
            order: initial;
          }
        }
      }
    }
    .centered-block .main-title {
      font-size: 1.0625rem;
      line-height: 1.5625rem;
    }
    .centered-block .bottom-content {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;

      .header-bottom-content {
        padding-bottom: 0;

        .title-block {
          padding-left: 15px;
          padding-right: 15px;
        }
      }

      .pagination-block {
        display: none;
      }

      .more-wrapper-btn {
        padding: 0 16px;

        .more-btn {
          display: flex;

          .title-btn {
            opacity: 1;
          }
        }
      }

      .status-line-block {
        .data-line-block {
          max-width: 250px;
          flex-direction: column;
          width: 100%;

          .line-info {
            width: 100%;
          }
        }
      }

      .status-line-block {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .right-block {
      width: 100%;
      margin: initial;
      padding-bottom: 45px;
      border-bottom: 1px solid $brdr_clr4;

      .wrapper {
        overflow-x: auto;
      }
    }

    .centered-block .bottom-content .status-line-block .data-line-block .line-info .line-count {
      flex-direction: row;
      align-items: center;
      width: 100%;

      span:last-child {
        margin-left: auto;
      }
    }

    .blockchain-block {
      display: initial;
      padding: 40px 17px;

      .content-block {
        flex-wrap: wrap;

        .left-col {
          margin-bottom: 15px;
        }
      }
    }
    .vertical-graph {
      display: none;
    }
  }
}
.shimmer-container {
  flex-direction: column;
  padding: 25px;
  display: flex;
  align-items: start;
  justify-content: center;
  flex: 1;
  border-bottom: 1px solid #edeff1;

  .d-flex.flex-direction-row {
    display: flex;
    flex-direction: row;
  }
  .d-flex.flex-direction-column {
    display: flex;
    flex-direction: column;
  }
  .d-flex.space-between {
    max-width: 100%;
    width: 100%;
    justify-content: space-between;
  }
  .d-flex.space-between {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  .d-flex.justify-content-start {
    max-width: 100%;

    justify-content: start;
  }
  .shimmer {
    border-radius: 10px;
    margin: 10px;
    max-width: 95%;
  }
}
.d-flex.justify-content-center {
  justify-content: center;
  align-items: center;
  align-self: center;
}

.d-flex {
  display: flex;
}
