@import '../../../variables';

.wallet-vote {
  position: relative;
  display: flex;
  flex-direction: column;
  //align-items: center;
  margin: 0 auto;
  padding-right: 20px;
  padding-bottom: 60px;
  padding-left: 20px;

  .preloader-wrapper {
    background-color: rgba(255, 255, 255, 0.8);
  }

  .top-content {
    padding-bottom: 22px;
    border-bottom: 1px solid $brdr_clr1;

    .selected-block {
      @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
      color: $txt_clr3;
      margin-top: 10px;
    }

    .selected-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 10px;

      p {
        display: flex;
        align-items: center;
        @include setFont('IBM Plex Sans', 400, 0.8125rem, 1.1875rem);
        background: $bg_clr1;
        border-radius: 6px;
        min-height: 24px;
        margin-right: 10px;
        padding: 2px 8px;
        color: $txt_clr4;
        margin-bottom: 10px;

        svg {
          min-height: 12px;
          min-width: 12px;
          max-width: 12px;
          max-height: 12px;
          margin-left: 8px;
          cursor: pointer;

          path {
            fill: $bg_clr4;
          }

          &:hover {
            path {
              fill: $bg_clr5;
            }
          }
        }
      }
    }

    .with-bg {
      min-height: 63px;
      min-width: 280px;
      max-width: 280px;
      margin-top: 24px;
    }

    .msg-content {
      padding-left: 80px;
    }
  }

  .bottom-content {
    padding-top: 18px;

    .header-bottom-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 18px;

      .info-table {
        display: flex;
        align-items: center;
        flex-direction: row;

        .dot-row {
          display: flex;
          align-items: center;
          flex-direction: row;
          @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
          color: $txt_clr3;
          margin-right: 40px;

          &.green {
            .dots {
              width: 11px;
              height: 11px;
              background-color: $bg_clr10;
              border-radius: 6px;
              margin-right: 17px;
            }
          }

          &.orange {
            .dots {
              width: 11px;
              height: 11px;
              background-color: #f2994a;
              border-radius: 6px;
              margin-right: 17px;
            }
          }
        }
      }

      .search-block {
        max-width: 402px;
        width: 100%;
        position: relative;

        &.change-style {
          background-color: $bg_clr0;
          border: 2px solid $brdr_clr3;

          .default-label .input-container input {
            opacity: 1;
            color: $txt_clr4;
            background-color: $bg_clr0;
          }
        }

        .default-label:after {
          background-color: transparent;
        }

        .default-label {
          .input-container {
            display: flex;
            align-items: center;
            width: 100%;

            svg {
              margin-right: 10px;
            }

            input {
              opacity: 1;
              color: $txt_clr4;

              &::placeholder {
                color: $txt_clr3;
                opacity: 0.5;
              }
            }
          }
        }
      }
    }

    .table-wrapper {
      min-width: 1080px;

      .vote-table {
        tr,
        th {
          white-space: nowrap;
        }

        .header-table {
          th {
            padding-left: 10px;
          }

          th:nth-child(1) {
            width: 5%;
            padding-left: 15px;
          }

          th:nth-child(2) {
            width: 10%;
          }

          th:nth-child(3) {
            width: 15%;
            text-align: left;
          }

          th:nth-child(4) {
            width: 20%;
            text-align: left;
          }

          th:nth-child(5) {
            width: 15%;
          }

          th:nth-child(6) {
            width: 15%;
          }

          th:nth-child(7) {
            width: 15%;
          }

          th:nth-child(8) {
            width: 20%;
            padding-right: 10px;
          }
        }

        .body-table {
          .custom-checkbox label .fake-checkbox.box-checked {
            background-color: $bg_clr6;
            border-color: $brdr_clr7;
            transition: border-color 200ms, background-color 200ms;
          }
          .custom-checkbox label {
            justify-content: center;
          }

          .custom-checkbox label .fake-checkbox {
            border: 2px solid $brdr_clr5;
            transition: border-color 200ms, background-color 200ms;
          }

          td {
            padding-left: 10px;
          }

          td:nth-child(1) {
            width: 5%;
          }

          td:nth-child(2) {
            width: 10%;
          }

          td:nth-child(3) {
            width: 15%;
            text-align: left;
            color: $txt_clr7;
          }

          td:nth-child(4) {
            width: 20%;
            text-align: left;
            color: $txt_clr3;
          }

          td:nth-child(5) {
            width: 15%;
          }

          td:nth-child(6) {
            width: 15%;
          }

          td:nth-child(7) {
            width: 15%;
          }

          td:nth-child(8) {
            width: 20%;
            padding-right: 10px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1115px) {
    .bottom-content .table-wrapper {
      min-width: initial;
    }
  }

  @media only screen and (max-width: $tabletScreen) {
    padding-top: initial;
  }
  @media only screen and (max-width: 840px) {
    padding-top: initial;
  }
  @media only screen and (max-width: $mobileScreen) {
    padding: 24px 17px;
    .top-content {
      text-align: center;
      border: initial;

      .click-btn.with-bg {
        max-width: initial;
      }
    }
    .bottom-content {
      padding-top: 0;

      .table-wrapper {
        overflow-x: auto;
        overflow-y: hidden;
      }

      .header-bottom-content {
        flex-direction: column;
        align-items: initial;

        .search-block {
          margin-left: 0;
          max-width: initial;
        }

        .info-table {
          display: none;
        }
      }
    }
  }
}
