@import '../../../variables';

.speedometer-widget {
  width: 121px;
  height: 100px;

  .speedometer-wrapper {
    position: relative;

    .speedometer-info {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      width: 100%;
      height: 100%;

      .speedometer-arrow {
        position: absolute;
        width: 50%;
        left: 0;
        bottom: 0;
        height: 2px;
        transform-origin: right;
        transition: transform 0.3s ease-in-out;
        -webkit-transition: transform 0.3s ease-in-out;

        &:after {
          position: absolute;
          top: 0;
          left: 10px;
          content: '';
          height: 100%;
          width: 15px;
          background: black;
        }
      }

      .speedometer-value {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        @include setFont('IBM Plex Sans', 500, 1.0625rem, 1.182rem);
        color: $txt_clr4;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 67.71%, $bg_clr0 100%);
        padding-bottom: 4px;
      }
    }
  }

  .widget-title {
    display: flex;
    align-items: center;
    justify-content: center;
    @include setFont('Montserrat', 600, 0.6875rem, 0.8125rem);
    text-align: center;
    text-transform: uppercase;
    color: $txt_clr3;
    opacity: 0.75;
    margin-top: 12px;
  }

  .widget-shadow {
    $shadowW: 109px;
    $shadowH: 56px;
    position: absolute;
    display: inline-block;
    top: 5px;
    left: 6px;
    opacity: 0.4;
    width: $shadowW;
    height: $shadowH;
    border-top-left-radius: $shadowW;
    border-top-right-radius: $shadowW;
    transition: box-shadow 0.3s;
    -webkit-transition: box-shadow 0.3s;
    $shadowType: inset 0px 11px 10px;

    &.first-state {
      box-shadow: $shadowType #46d24b;
    }

    &.second-state {
      box-shadow: $shadowType #a1e481;
    }

    &.third-state {
      box-shadow: $shadowType #d2d4db;
    }

    &.fourth-state {
      box-shadow: $shadowType #91bdf3;
    }

    &.fifth-state {
      box-shadow: inset 0px 8px 16px $bg_clr8;
    }
  }
}
