@import '../../../variables';

$emptyCircle: rgba(117, 133, 150, 0.25);

.circle-progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  &.ram-class {
    circle:last-child {
      stroke: $brdr_clr11;
    }
  }

  &.cpu-class {
    circle:last-child {
      stroke: #27ae60;
    }
  }

  &.net-class {
    circle:last-child {
      stroke: $brdr_clr7;
    }
  }

  svg {
    circle {
      transition: stroke-dashoffset 0.35s;
      transform: rotate(-90deg);
      transform-origin: 50% 50%;
    }
  }

  > div {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: $txt_clr0;
    @include setFont('IBM Plex Sans', 500, 0.6875rem, 1rem);
    padding-left: 3px;
  }

  .title {
    @include setFont('IBM Plex Sans', 400, 0.5rem, 0.75rem);
  }
}
