//color variables
$white0: #ffffff;
$white1: #eceff1;

$gray0: #f5f6f7;
$gray1: #dce0e5;
$gray2: #edeff1;
$gray3: #758596;
$gray5: rgba(117, 133, 150, 0.15);
$gray6: rgba(52, 94, 169, 0.15);
$gray7: #bac2ca;
$gray8: rgba(131, 75, 75, 0.1);
$gray9: #f0f1f2;
$gray10: #f5f6f8;
$gray11: rgba(196, 196, 196, 0.1);
$gray12: rgba(123, 146, 173, 0.15);
$gray13: #777777;
$gray14: #d2d7e0;

$dark0: #2b2a34;
$dark1: rgba(119, 119, 119, 0.05);
$dark2: rgba(0, 0, 0, 0.2);
$dark3: #223c50;
$dark4: rgba(0, 0, 0, 0.6);
$dark5: rgba(0, 0, 0, 0.12);
$dark6: rgba(0, 0, 0, 0.38);
$dark7: rgba(117, 133, 150, 0.25);

$blue0: #2f66e3;
$blue1: #345ea9;
$blue2: #428bca;
$blue3: #4293f6;
$blue4: #1d263b;
$blue5: rgba(66, 147, 246, 0.1);
$blue6: #dfe8f393;

$green0: #52aa55;
$green1: #349459;
$green2: rgba(82, 170, 85, 0.1);
$green3: #eff6ee;

$yellow0: #aaa80c;
$yellow1: #f8d04f;

$red0: rgb(255, 0, 0);
$red1: #eb5757;

$orange: #ff9a1e;
//text color variables
$txt_clr0: $white0;
$txt_clr1: $gray0;
$txt_clr2: $gray1;
$txt_clr3: $gray3;
$txt_clr4: $dark0;
$txt_clr5: $blue0;
$txt_clr6: $blue1;
$txt_clr7: $blue2;
$txt_clr8: $blue3;
$txt_clr9: $blue4;
$txt_clr10: $green0;
$txt_clr11: $green1;
$txt_clr12: $yellow0;
$txt_clr13: $red0;
$txt_clr14: $red1;
$txt_clr15: $dark4;
$txt_clr16: $dark6;
$txt_clr17: $gray13;

//background-color variables
$bg_clr0: $white0;
$bg_clr1: $white1;
$bg_clr2: $gray0;
$bg_clr3: $gray1;
$bg_clr4: $gray3;
$bg_clr14: $gray8;
$bg_clr5: $dark0;
$bg_clr6: $blue0;
$bg_clr7: $blue1;
$bg_clr8: $blue3;
$bg_clr9: $blue4;
$bg_clr13: $blue5;
$bg_clr19: $dark7;
$bg_clr10: $green0;
$bg_clr18: $green2;
$bg_clr11: $yellow1;
$bg_clr12: $red1;
$bg_clr15: $gray9;
$bg_clr13: $dark2;
$bg_clr16: $dark3;
$bg_clr17: $gray10;
$bg_clr19: $gray11;
$bg_clr20: $gray14;

//border color variables
$brdr_clr0: $white0;
$brdr_clr1: $white1;
$brdr_clr2: $gray0;
$brdr_clr3: $gray1;
$brdr_clr4: $gray2;
$brdr_clr5: $gray3;
$brdr_clr12: $gray7;
$brdr_clr6: $dark0;
$brdr_clr7: $blue0;
$brdr_clr8: $blue1;
$brdr_clr9: $blue2;
$brdr_clr10: $green0;
$brdr_clr11: $yellow1;
$brdr_clr13: $dark5;
$brdr_clr14: $gray12;
$brdr_clr15: $red0;
$brdr_clr16: $yellow0;

//box-shadow color variables

//----screenPoints---
$mobileScreen: 780px;
$tabletScreen: 1024px;
$desktopScreen: 1280px;

//-------colors-------
$shadow_clr0: $gray5;
$shadow_clr1: $gray6;
$shadow_clr2: $dark1;

//-------shadow-------
$shadowPart_1: 0px 2px 2px;
$shadowPart_2: 0px 4px 4px;
$shadowPart_3: 0px 8px 8px;
$shadowPart_4: 0px 16px 16px;
$shadowPart_5: 0px 32px 32px;

//-------colors element-------

$walletBlockWidth: 780px;

@mixin fixSizeSquare($size) {
  min-width: $size;
  width: $size;
  height: $size;
  min-height: $size;
}

@mixin fixSizeCustom($sizeH, $sizeW) {
  min-width: $sizeW;
  width: $sizeW;
  height: $sizeH;
  min-height: $sizeH;
}

%centerContent {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@mixin setFont($family, $weight, $size, $lineHeight) {
  font-family: $family, sans-serif;
  font-size: $size;
  line-height: $lineHeight;
  font-weight: $weight;
}
