@import '../../../variables';

.no-match-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $txt_clr4;
  width: 100%;
}
