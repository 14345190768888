.btn-menu-wrapper {
  padding: 10px;
}
.menu-btn {
  position: relative;
  width: 24px;
  height: 14px;

  &.open {
    .btn-delimiter {
      &.top,
      &.bot {
        top: 50%;
        margin-top: -1px;
      }

      &.top {
        transform: rotate(45deg);
      }

      &.mid {
        opacity: 0;
      }

      &.bot {
        transform: rotate(-45deg);
      }
    }
  }

  .btn-delimiter {
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: $bg_clr4;
    left: 0;
    transition: transform 0.3s linear, opacity 0.3s linear;
    -webkit-transition: transform 0.3s linear, opacity 0.3s linear;

    &.top {
      top: 2px;
    }

    &.mid {
      top: 50%;
      margin-top: -1px;
    }

    &.bot {
      bottom: 2px;
    }
  }
}
