@import '../../../variables';

.copy-field-container {
  display: inline-block;
  position: relative;
  overflow: hidden;

  .visible-part {
    position: relative;
    // z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
    border: 1px solid black;
    border-radius: 3px;
    padding: 5px;
    height: 100%;
    background-color: $bg_clr0;

    .copy-content {
      display: flex;
      flex-direction: row;
      width: 100%;
      > span {
        width: 93%;
      }
    }

    svg {
      margin-left: 15px;
      height: 15px;
      width: 15px;
    }
  }

  &.only-label {
    margin: 0;
    text-align: center;

    .visible-part {
      background-color: transparent;
      border: none;
      align-items: center;
    }
  }

  &.blue-theme {
    .visible-part {
      cursor: initial;
      background-color: $bg_clr13;
      border: 2px solid $brdr_clr8;
      border-radius: 8px;
      padding: 9px 13px;

      .label {
        @include setFont('IBM Plex Sans', 400, 0.8125rem, 150%);
      }

      .copy-content {
        > span {
          @include setFont('IBM Plex Sans', 800, 0.8125rem, 150%);
        }
      }

      svg {
        position: absolute;
        top: 50%;
        height: initial;
        width: initial;
        right: 20px;
        margin: -14px 0 0 0;
        padding: 5px;
        cursor: pointer;

        g {
          opacity: 1;
        }

        rect,
        path {
          stroke: $brdr_clr8;
        }
      }
    }

    .cb-txt {
      border-radius: 8px;
      background-color: rgba(95, 129, 208, 0.6);
      @include setFont('IBM Plex Sans', 600, 0.8125rem, 150%);
    }
  }

  &.dark-blue-theme {
    .visible-part {
      cursor: initial;
      background-color: $bg_clr9;
      border: 0;
      padding: 0;

      .label {
        @include setFont('IBM Plex Sans', 400, 0.8125rem, 150%);
      }

      svg {
        height: initial;
        width: initial;
        cursor: pointer;
      }
    }

    .cb-txt {
      border-radius: 8px;
      background-color: $bg_clr5;
      opacity: 0.95;
      @include setFont('IBM Plex Sans', 600, 0.8125rem, 150%);
    }
  }

  input {
    position: absolute;
    left: 200vh;
  }

  .cb-txt {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    color: $txt_clr0;
    border-radius: 4px;
  }

  &.copied {
    .cb-txt {
      display: flex;
      z-index: 3;
    }
  }
}
