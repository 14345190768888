@import '../../../variables';

.transaction-details-page {
  position: relative;

  .page-content {
    .account-info {
      .info-content {
        max-width: 683px;
        justify-content: initial;

        &:first-child {
          .info-txt {
            @include setFont('IBM Plex Sans', 600, 0.9375rem, 1.375rem);
          }
        }

        &:nth-child(3) {
          .row-txt {
            color: $txt_clr0;

            > span {
              border-radius: 5px;
              padding: 1px 8px;
            }

            .info-txt {
              background-color: $bg_clr10;
            }

            .info-second-txt {
              background-color: $bg_clr8;
            }
          }
        }

        &:nth-child(4) {
          .info-txt {
            color: $txt_clr7;
          }
        }

        .row-txt {
          white-space: pre-wrap;

          .info-txt {
            margin-right: 6px;
          }
        }
      }
    }

    .header-block-table {
      align-items: center;
      margin-top: 50px;
      @extend .header-page;

      .control-btn:last-child {
        .count-btn {
          display: none;
        }
      }
    }

    .table-wrapper {
      .table-content {
        .header-table {
          tr {
            th {
              padding-right: 10px;
            }

            th:nth-child(1) {
              width: 20%;
            }

            th:nth-child(2) {
              width: 20%;
              text-align: center;
            }

            th:nth-child(3) {
              width: 30%;
            }

            th:nth-child(4) {
              width: 30%;
            }

            th:nth-child(n + 3) {
              text-align: left;
            }
          }
        }

        .body-table {
          .body-row {
            td {
              padding-right: 10px;
            }

            td:nth-child(1) {
              width: 20%;
            }

            td:nth-child(2) {
              text-align: center;
              width: 20%;
              .content-row {
                display: flex;
                align-items: center;
                flex-direction: row;
                padding-left: 10px;
                padding-right: 10px;

                .title {
                  @include setFont('IBM Plex Sans', 500, 1.063rem, 1.563rem);
                  color: $txt_clr4;
                }

                .table-row {
                  display: flex;
                  align-items: center;
                }

                .sent-icon {
                  margin-right: 10px;
                  min-height: 36px;
                  max-height: 36px;
                  min-width: 36px;
                  max-width: 36px;
                }
              }
            }

            td:nth-child(3) {
              width: 30%;
            }

            td:nth-child(4) {
              width: 30%;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 300px;
            }

            td:nth-child(n + 3) {
              text-align: left;
              .hex_data {
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: $mobileScreen) {
    .account-info .info-content:first-child .info-txt {
      word-break: break-word;
    }
    .header-block-table {
      flex-wrap: wrap;
      padding: 0 !important;

      .main-title {
        margin-left: 17px;
      }
    }
    .table-wrapper .table-content {
      tr,
      td {
        padding-right: 15px;
      }

      .header-table {
        tr {
          th:nth-child(2) {
            text-align: center;
          }
        }
      }

      .body-table .body-row {
        white-space: nowrap;

        td:nth-child(2) {
          padding-right: 8px;
        }

        td:nth-child(3) {
          font-size: 0.8125rem;
        }

        td:nth-child(4) {
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100px;
          width: 20%;
        }
      }
    }
  }
  @media only screen and (max-width: $tabletScreen) {
    .page-content .header-block-table {
      align-items: baseline !important;
      padding-bottom: 0 !important;

      .table-controls {
        width: initial;
      }
    }
    .table-wrapper .table-content .body-table .body-row td {
      padding-right: 10px;
    }
  }
}
