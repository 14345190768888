@import '../../../variables';

.success-pin-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  .done-title {
    @include setFont('IBM Plex Sans', 500, 1.313rem, 1.938rem);
  }

  .done-subtitle {
    @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
    padding-top: 10px;
  }

  .done-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $bg_clr6;
    border-radius: 12px;
    color: $txt_clr0;
    width: 100%;
    max-width: 409px;
    height: 53px;
    @include setFont('IBM Plex Sans', 500, 1.0625rem, 1.5625rem);
    margin-top: 70px;
  }
}
