@import '../../../variables';

@media only screen and (min-width: $desktopScreen) {
  .block-details-page .info-blocks .account-info button:hover {
    transition: color 200ms, opacity 200ms;
    -webkit-transition: color 200ms, opacity 200ms;
    border-color: $brdr_clr1;
    background-color: $bg_clr1;
  }
}

.block-details-page {
  position: relative;

  .info-blocks {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 50px;

    .account-info {
      max-width: 426px;

      .arrow-back {
        background-color: $bg_clr2;
        border: 1px solid $brdr_clr2;
        border-radius: 5px;
        width: 23px;
        height: 24px;
        min-height: initial;
      }

      .arrow-forward {
        @extend .arrow-back;
        transform: rotate(180deg);
      }

      &:first-child {
        margin-right: 70px;

        .info-content:first-child {
          justify-content: initial;

          .info-txt {
            @include setFont('IBM Plex Sans', 600, 0.9375rem, 1.375rem);
            padding: 0 15px;
            min-width: 90px;
            max-width: 120px;
            text-align: center;
          }
        }

        .info-content:nth-child(3) {
          justify-content: initial;

          .info-txt {
            color: $txt_clr0;
            background-color: $bg_clr10;
            border-radius: 5px;
            max-width: 125px;
            min-width: 95px;
            text-align: center;
            padding: 0 8px;
          }
        }
      }

      .info-content {
        max-width: initial;

        .info-txt {
          word-break: break-word;
          max-width: 292px;
          min-width: 208px;
          width: 100%;
        }
      }
    }
  }

  .header-control-table {
    padding-bottom: 50px;
    @extend .header-page;
    align-items: center !important;

    .table-controls {
      .control-btn:last-child {
        .count-btn {
          display: none;
        }
      }
    }
  }

  .table-wrapper {
    .table-content.blocks-table {
      .header-table {
        tr {
          th:nth-child(1) {
            width: 40%;
          }

          th:nth-child(4) {
            text-align: right;
            width: 8%;
          }

          th:nth-child(5) {
            padding-left: 20px;
          }
        }
      }

      .body-table {
        .body-row {
          a {
            color: $txt_clr7;
          }

          td:nth-child(1) {
            word-break: break-all;
          }

          td:nth-child(2) {
            color: $txt_clr4;
          }

          td:nth-child(4) {
            text-align: right;
            width: 8%;
            padding-right: 15px !important;
          }

          td:nth-child(5) {
            padding-left: 20px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: $tabletScreen) {
    .header-control-table {
      flex-wrap: wrap;

      .main-title {
        margin-bottom: 15px;
      }
    }
    .table-wrapper .table-content.blocks-table {
      overflow: scroll;

      .header-table {
        tr {
          white-space: nowrap;

          th:nth-child(1) {
            width: 25%;
          }
        }
      }

      .body-table .body-row {
        td {
          width: 20%;
          min-width: 0;
          flex: 1 1 100%;
          padding-right: 10px;
          word-break: initial;

          a {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 170px;
            white-space: nowrap;
          }
        }
      }
    }
  }

  @media only screen and (max-width: $mobileScreen) {
    .header-control-table {
      flex-wrap: wrap;
      padding: 0 !important;

      .main-title {
        margin-left: 17px;
      }

      .table-controls {
        margin-top: 20px;
      }
    }
    .table-wrapper .table-content.blocks-table .body-table .body-row td:nth-child(1) {
      width: 20%;
      min-width: 0;
      flex: 1 1 100%;
      word-break: initial;

      a {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 90px;
      }
    }
    .table-wrapper .table-content.blocks-table {
      th {
        white-space: nowrap;
        padding-right: 15px !important;
      }

      .header-table {
        tr {
          th:nth-child(1) {
            width: 20%;
          }
        }
      }

      .body-table {
        .body-row {
          white-space: nowrap;

          td {
            padding-right: 15px !important;
          }

          td:last-child {
            font-size: 0.8125rem;
            padding-left: 3px;
          }
        }
      }
    }
    //.info-blocks .account-info:last-of-type {
    //  display: none;
    //}
  }
}
