@import 'variables';
@import 'normalize';
//@import "./fonts/fonts";

body {
  background-color: $bg_clr0;
}
// to prevent iphone autofill yellow color
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 60px #fafafa inset !important;
  background-color: #fafafa !important;
  background-clip: content-box !important;
}
#chartjs-tooltip {
  font-family: 'IBM Plex Sans', sans-serif !important;
  // @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
  background-color: $bg_clr0;
  border: 1px solid $brdr_clr3;
  border-radius: 8px;
  padding: 0 !important;

  tr td {
    min-width: 200px;
    @media only screen and (max-width: 900px) {
      min-width: initial;
    }
  }

  tr td .header-tooltip {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    // border-bottom: 1px solid $brdr_clr3;
    padding: 12px 15px 10px 15px;
    @media only screen and (max-width: 900px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .left-header,
    .right-header {
      display: flex;
      flex-direction: column;
      font-size: 0.688rem;
      line-height: 1rem;
      white-space: nowrap;

      span {
        font-size: 0.813rem;
        line-height: 1.188rem;
        color: $txt_clr4;
      }
    }

    .left-header {
      color: $txt_clr8;
      margin-right: 15px;
    }

    .right-header {
      justify-content: flex-end;
      text-align: right;
      color: $txt_clr17;
      @media only screen and (max-width: 900px) {
        text-align: initial;
      }
    }
  }
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  .content-wrapper .block-container + div {
    flex-grow: 1;
    padding-bottom: 40px;
    //width: 100%;
  }

  > div:not(.modal-wrapper):not(.logger-body) {
    display: flex;
    flex-direction: row;
    //flex-wrap: wrap;  //for mobile
    flex-grow: 1;
    width: 100%;
    color: $txt_clr4;

    &.column {
      flex-direction: column;
    }
  }

  .main-title {
    font-family: 'Montserrat', sans-serif;
    color: $txt_clr4;
    text-transform: uppercase;

    &.lg {
      font-weight: 800;
      font-size: 1.6875rem;
      line-height: 1.6875rem;
    }

    &.md {
      font-weight: bold;
      font-size: 1.3125rem;
      line-height: 1.3125rem;
    }

    &.sm {
      font-weight: 400;
      font-size: 0.9375rem;
      line-height: 1.375rem;
    }

    &.xs {
      font-weight: 600;
      font-size: 0.8125rem;
      line-height: 1rem;
    }
  }

  .table-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 55px;
  }

  .page-content {
    display: flex;
    flex-direction: column;
    background-color: $bg_clr0;
    padding-top: 30px;
    padding-left: 50px;
    padding-right: 60px;
    width: 100%;

    > div {
      width: auto;
    }
  }

  .header-page {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 45px;
  }

  .wallet-router {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    // flex-grow: 1;
    flex-wrap: wrap;
    //width: 100%;
    padding-bottom: 50px;
    //padding-top: 50px;
    @media only screen and (max-width: 1152px) {
      .container {
        width: 100%;
      }
    }

    .nested-route {
      width: 792px;

      &.margin {
        margin: 0 auto;
      }

      > div {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .info-content {
    display: flex;
    align-items: flex-start;
    max-width: 310px;
    justify-content: space-between;
    @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
    padding-bottom: 16px;

    .title {
      color: $txt_clr3;
      min-width: 173px;
      margin-right: 15px;
    }

    .info-txt {
      width: 100%;
    }
  }

  .title-page {
    @include setFont('IBM Plex Sans', 600, 1.3125rem, 2.1875rem);
    color: $txt_clr4;
    margin-bottom: 25px;

    &.preloader-header {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      svg {
        margin-left: 12px;
        min-width: 24px;
        min-height: 24px;

        &.active {
          animation: 1s InfRotation infinite;
        }
      }
    }
  }

  .wallet-content {
    display: flex;
    flex-direction: column;
    padding: 25px 30px 30px 30px;
    border: 1px solid $brdr_clr1;
    box-shadow: 0px 4px 4px rgba(119, 119, 119, 0.05);
    border-radius: 10px;
    background-image: url('img/transfer_bg.svg');
    background-repeat: no-repeat;

    &.relative {
      position: relative;
    }

    .updating-required {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.8);
      @include setFont('IBM Plex Sans', 400, 1.8125rem, normal);
      text-align: center;
      z-index: 3;
    }
  }

  .toggle-tabs {
    display: none;
    align-items: center;
    justify-content: space-around;
    border-radius: 8px;
    margin-right: 17px;
    margin-left: 17px;
    min-height: 40px;
    max-height: 40px;
    margin-top: 20px;
  }

  .control-btn.tab-item {
    max-height: 40px;
    min-height: 40px;
  }

  .btn-block {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 21px;

    .percent-btn {
      border: 1px solid $brdr_clr3;
      border-radius: 4px;
      @include setFont('IBM Plex Sans', 400, 0.8125rem, 1.1875rem);
      color: $txt_clr3;
      opacity: 1;
      min-width: 44px;
      width: initial;
      min-height: 24px;
      padding: 2px 8px;
      margin-left: 0;
      margin-right: 4px;
      transition: color 200ms, border-color 200ms, background-color 200ms;
      -webkit-transition: color 200ms, background-color 200ms, border-color 200ms;

      .title-btn {
        margin-left: initial;
      }
    }
  }

  @media only screen and (min-width: $desktopScreen) {
    .btn-block .click-btn.percent-btn:hover {
      border-color: #bac2ca;
      transition: color 200ms, border-color 200ms, background-color 200ms;
      -webkit-transition: color 200ms, background-color 200ms, border-color 200ms;
      background-color: $bg_clr2;

      .title-btn {
        color: $txt_clr9;
      }
    }
  }

  @media only screen and (max-width: $tabletScreen) {
    .content-wrapper .block-container + div {
      width: 60%;
    }
    .page-content {
      padding-right: 20px;
      padding-left: 20px;
    }
    .table-controls {
      overflow: auto;
      height: 105px;
      width: 100%;
      margin-top: 15px;

      .control-btn {
        margin-bottom: 50px;

        &:first-child {
          margin-left: 17px;
        }

        &:last-child {
          margin-right: 10px;
        }
      }
    }
    .transaction-details-page .account-info .info-content .row-txt {
      word-break: break-word;
    }
    .wallet-router .container {
      .table-wrapper {
        min-width: initial;
      }
    }
  }
  @media only screen and (max-width: 840px) {
    .wallet-router .container {
      width: 100%;

      .nested-route {
        margin: auto;
      }
    }
  }
  @media only screen and (min-width: 1441px) {
    .content-wrapper .block-container + div {
      width: 100%;
    }
    .home-page .centered-block {
      .top-content,
      .center-content,
      .bottom-content,
      .emission-block {
        padding-left: 80px;
        padding-right: 80px;
      }
    }
    .content-wrapper .block-container .blockchain-block,
    .card-wrapper {
      padding-left: 60px;
      padding-right: 50px;
    }
    .home-page .right-block .block-info-wrapper .block-info .info-content {
      max-width: initial;

      .data {
        max-width: initial;
        margin-right: 30px;
      }
    }
  }

  @media only screen and (max-width: 1350px) {
    .content-wrapper .block-container + div {
      width: 60%;
    }
    .page-content {
      padding-left: 17px;
      padding-right: 17px;
    }
  }
  @media only screen and (max-width: 1200px) {
    .content-wrapper .block-container + div {
      width: 55%;
    }
  }
  @media only screen and (max-width: 940px) {
    .content-wrapper .block-container + div {
      width: 45%;
    }
  }
  @media only screen and (max-width: 854px) {
    .content-wrapper .block-container + div {
      width: 35%;
    }
  }

  @media only screen and (max-width: $mobileScreen) {
    flex-direction: column;
    //min-height: initial;
    .wallet-router .nested-route {
      width: 100%;

      & > div {
        margin-top: 24px;
      }

      & > .wallet-cru-block,
      .cru-block {
        margin-top: 0;
      }
    }
    .wallet-content {
      border: initial;
      box-shadow: initial;
      padding-left: 17px;
      padding-right: 17px;
      background-image: none;
    }
    .wallet-content .toggle-tabs {
      display: flex;
    }
    .header-page {
      flex-wrap: wrap;
    }
    .header-page,
    .pagination-block,
    .account-info {
      padding-left: 17px;
      padding-right: 17px;
    }
    .block-container {
      display: none;
    }
    .page-content {
      padding-left: 0;
      padding-right: 0;
    }
    .pagination-block {
      .count-pages {
        display: none;
      }
    }
    .home-page .right-block .block-number {
      flex-wrap: wrap;

      .title-block {
        margin-bottom: 15px;
      }
    }
    .wallet-router .container {
      width: 100%;
    }
    .title-page {
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 0;
    }
    .wallet-service .steps-container {
      width: initial;
      margin: 10px;
      padding: 20px;
    }
  }
}

.rotate-00 {
  transform: rotate(-180deg);
}

.process-msg {
  display: inline-block;
  width: 100%;
  text-align: center;
  color: $txt_clr4;
  @include setFont('Montserrat', 400, 1.3rem, 1.0625rem);

  &.top-pad {
    padding-top: 50px;
  }
}

.ReactModal__Overlay {
  z-index: 10;
  overflow-y: auto;

  //.ReactModal__Content {
  //  padding-top: 20px;
  //  padding-bottom: 20px;
  //}

  &:focus {
    outline: none !important;
  }
}

.ReactModal__Content {
  &:focus {
    outline: none !important;
  }
}

.wallet-service {
  flex-grow: 1;
  width: 100%;
  background-color: $bg_clr9;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;

  .logo-pic {
    margin-bottom: 20px;
  }

  .steps-container {
    margin-bottom: 16px;
    position: relative;
    width: 580px;
    max-width: 580px;
    background-color: $bg_clr0;
    border-radius: 8px;
    padding: 45px 40px;

    .next-step-btn {
      width: 100%;
      margin-top: 40px;
    }

    .block-font {
      @include setFont('IBM Plex Sans', 500, 0.9375rem, 150%);
      color: $txt_clr4;
      text-align: center;

      &.sm-weight {
        font-weight: 400;
      }

      &.md-weight {
        font-weight: 600;
      }

      &.sm-size {
        font-size: 0.8125rem;
      }

      &.lg-size {
        font-size: 1.3125rem;
      }

      &.warning-color {
        color: $txt_clr13;
      }

      &.second-color {
        color: $txt_clr3;
      }

      &.third-color {
        color: $txt_clr5;
      }

      &.success-color {
        color: $txt_clr10;
      }
    }

    .email-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .email-logo {
        width: 82px;
        height: 82px;
      }

      .input-wrapper {
        position: relative;
        width: 100%;
        margin-top: 40px;

        .lock-icon {
          position: absolute;
          width: 20px;
          height: 20px;
          top: 30%;
          right: 5%;
        }
      }

      .email-field {
        font-weight: bold;
        margin-top: 10px;
      }
    }
  }

  .copy-field-container {
    margin-top: 40px;
    margin-bottom: 48px;
    width: 100%;
  }
}
.main-title.md.title-top-block.not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: none;
  color: $gray13;
}
.flag {
  width: 21px;
  height: 15px;
  display: inline-block;
  vertical-align: top;
  background-repeat: no-repeat;
  background-position: center;

  &-cs {
    background-image: url('img/flags/icon-CZ.svg');
  }

  &-de {
    background-image: url('img/flags/icon-DE.svg');
  }

  &-en {
    background-image: url('img/flags/icon-US.svg');
  }

  &-es {
    background-image: url('img/flags/icon-ES.svg');
  }

  &-et {
    background-image: url('img/flags/icon-EE.svg');
  }

  &-hi_IN {
    background-image: url('img/flags/icon-IN.svg');
  }

  &-it {
    background-image: url('img/flags/icon-IT.svg');
  }

  &-kk {
    background-image: url('img/flags/icon-KZ.svg');
  }

  &-pt {
    background-image: url('img/flags/icon-PT.svg');
  }

  &-ro {
    background-image: url('img/flags/icon-RO.svg');
  }

  &-ru {
    background-image: url('img/flags/icon-RU.svg');
  }

  &-zh_CN {
    background-image: url('img/flags/icon-CN.svg');
  }

  &-ru {
    background-image: url('img/flags/icon-RU.svg');
  }

  &-de {
    background-image: url('img/flags/icon-DE.svg');
  }
}

.centered-txt {
  text-align: center;
}

@import 'components/mainPages/appError/index';
@import 'components/mainPages/home/index';
@import 'components/mainPages/header/index';
@import 'components/mainPages/noMatch/index';
@import 'components/mainPages/switchToken/index';
@import 'components/mainPages/blocks/index';
@import 'components/mainPages/accounts/index';
@import 'components/mainPages/transactions/index';
@import 'components/mainPages/accountDetails/index';
@import 'components/mainPages/blockDetails/index';
@import 'components/mainPages/transactionDetails/index';
@import 'components/mainPages/walletCru/index';
@import 'components/mainPages/walletUntb/index';
@import 'components/mainPages/walletHeader/index';
@import 'components/mainPages/walletAuth/index';
@import 'components/mainPages/walletConvert/index';
@import 'components/mainPages/walletDashboard/index';
@import 'components/mainPages/walletSend/index';
@import 'components/mainPages/walletRam/index';
@import 'components/mainPages/walletHistory/index';
@import 'components/mainPages/walletVote/index';
@import 'components/mainPages/walletConvertResult/index';
@import 'components/mainPages/successPin/index';
@import 'components/mainPages/walletDividends/index';
@import 'components/mainPages/walletDividendsTable/index';
@import 'components/mainPages/createWallet/index';
@import 'components/mainPages/walletStaking/index';
@import 'components/helpers/searchBox/index';
@import 'components/helpers/menuBtn/index';
@import 'components/helpers/countTxtRow/index';
@import 'components/helpers/blockHeader/index';
@import 'components/helpers/preloader/index';
@import 'components/helpers/statusLine/index';
@import 'components/helpers/speedometer/index';
@import 'components/helpers/table/index';
@import 'components/helpers/verticalGraph/index';
@import 'components/helpers/breadcrumbs/index';
@import 'components/helpers/tableСontrols/index';
@import 'components/helpers/pagination/index';
@import 'components/helpers/checkBox/index';
@import 'components/helpers/cardToken/index';
@import 'components/helpers/carousel/index';
@import 'components/helpers/blockchain/index';
@import 'components/helpers/button/index';
@import 'components/helpers/blockChart/index';
@import 'components/helpers/emptyResults/index';
@import 'components/helpers/input/index';
@import 'components/helpers/circleProgress/index';
@import 'components/helpers/trxRow/index';
@import 'components/helpers/recentTrxBlock/index';
@import 'components/helpers/progressBar/index';
@import 'components/helpers/countWithZero/index';
@import 'components/helpers/dragLine/index';
@import 'components/helpers/inputRow/index';
@import 'components/helpers/tokenInfoBlock/index';
@import 'components/helpers/dropDown/index';
@import 'components/helpers/copyField/index';
@import 'components/helpers/filter/index';
@import 'components/helpers/balanceRow/index';
@import 'components/helpers/msgPrinter/index';
@import 'components/modals/mobileMenu/index';
@import 'components/modals/stakedTable/index';
@import 'components/modals/enterPin/index';
@import 'components/modals/mobileMenuWallet/index';
@import 'components/modals/unfreezeSchedule/index.scss';
@import 'components/modals/rewardsShedule/index.scss';
@import 'components/mainPages/logger/index';
@import 'components/mainPages/cruForCruStaking/index';
