@import '../../../variables';

.row-container {
  display: flex;
  flex-direction: column;
  padding-right: 20px;

  .green {
    color: $txt_clr11;
  }

  &.green {
    .count-row {
      color: $txt_clr11;
    }
  }

  .count-block {
    display: flex;
    align-items: center;
    white-space: pre-wrap;
    @include setFont('IBM Plex Sans', 500, 1.0625rem, 1.5625rem);
    padding-bottom: 9px;

    .percent-row {
      font-weight: 400;
      color: $txt_clr3;
    }

    .dot {
      background-color: #80d683;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      margin-left: 8px;
    }
  }

  .title-block {
    display: flex;
    align-items: center;

    &.no-wrap {
      white-space: nowrap;
    }

    .title-row {
      @include setFont('Montserrat', 600, 0.6875rem, 0.8125rem);
      text-transform: uppercase;
      color: $txt_clr3;
      opacity: 0.75;
    }

    .dot-title {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      margin-right: 8px;
    }
  }
}
