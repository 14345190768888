@import '../../../variables';

@media only screen and (min-width: $desktopScreen) {
  .more-wrapper-btn {
    .more-btn:hover {
      .title-btn {
        opacity: 1;
        transition: opacity 200ms;
        -webkit-transition: opacity 200ms;
      }
    }
  }
}

.transactions-page {
  .table-wrapper .table-content.transactions-table {
    th {
      padding-right: 10px;
    }

    .body-table .body-row {
      white-space: nowrap;

      td {
        padding-right: 10px;

        a {
          max-width: 180px !important;
        }
      }
    }
  }

  @media only screen and (max-width: 1300px) {
    .table-wrapper .table-content.transactions-table .body-table .body-row {
      td {
        a {
          max-width: 140px !important;
        }
      }
    }
  }

  @media only screen and (min-width: 2000px) {
    .table-wrapper .table-content.transactions-table .body-table .body-row {
      td {
        a {
          max-width: 260px !important;
        }
      }
    }
  }
  @media only screen and (min-width: 2300px) {
    .table-wrapper .table-content.transactions-table .body-table .body-row {
      td {
        a {
          max-width: 310px !important;
        }
      }
    }
  }
  @media only screen and (max-width: $mobileScreen) {
    .header-page {
      padding-left: 0;

      .breadcrumbs {
        margin-left: 17px;
      }
    }
    .table-wrapper .table-content {
      td,
      th {
        padding-right: 15px !important;
      }

      .header-table tr {
        th:nth-child(3),
        th:nth-child(6) {
          text-align: center;
        }
      }

      .body-table .body-row {
        white-space: nowrap;

        td:nth-child(4) {
          padding-right: inherit;
        }

        td:last-child {
          font-size: 0.8125rem;
        }
      }
    }
    .page-content .more-wrapper-btn {
      padding: 0 16px;
    }
  }
  @media only screen and (max-width: $tabletScreen) {
    .header-page {
      align-items: flex-start;
      //padding-bottom: 0 !important;

      .table-controls {
        width: initial;
      }
    }
    .table-wrapper .table-content.transactions-table {
      .body-table .body-row td {
        padding-right: 10px !important;
      }

      .header-table tr {
        th {
          padding-right: 10px !important;
        }
      }
    }
  }
}
