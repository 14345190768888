@import '../../../variables';

@keyframes show-modal-table {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgba(4, 4, 15, 0.4) !important;

  .modal-table {
    background-color: $bg_clr0;
    border: 1px solid $brdr_clr1;
    border-radius: 9px;
    max-width: 898px;
    max-height: 799px;
    margin: auto;
    position: relative;
    z-index: 10;
    animation: show-modal-table 0.8s;

    .header-modal {
      padding: 35px 25px 50px 60px;
      position: relative;

      .title-table {
        @include setFont('Montserrat', 600, 1.3125rem, 1.3125rem);
        text-transform: uppercase;
      }

      svg {
        position: absolute;
        top: 25px;
        right: 25px;
        cursor: pointer;
      }
    }

    .table-content {
      .header-table {
        tr {
          th:nth-child(1) {
            width: 8%;
            padding-left: 50px;
          }

          th:nth-child(2) {
            width: 15%;
          }

          th:nth-child(3) {
            width: 15%;
            padding-right: 0;
          }

          th:nth-child(4) {
            width: 15%;
            padding-right: 0;
          }

          th:nth-child(5) {
            width: 12%;
            text-align: center;
          }

          th:nth-child(6) {
            width: 15%;
            padding-right: 50px;
          }
        }
      }

      .body-table {
        .body-row {
          height: 62px;

          td:nth-child(1) {
            width: 8%;
            padding-left: 50px;
          }

          td:nth-child(2) {
            width: 15%;
            color: $txt_clr3;
          }

          td:nth-child(3) {
            width: 15%;
            padding-right: 0;
          }

          td:nth-child(4) {
            width: 15%;
            padding-right: 0;
          }

          td:nth-child(5) {
            width: 12%;
            text-align: center;
          }

          td:nth-child(6) {
            width: 15%;
            padding-right: 50px;
            color: $txt_clr3;
            text-align: right;
          }
        }
      }
    }

    .pagination-block {
      padding-left: 50px;
      padding-right: 40px;
    }
  }
}
