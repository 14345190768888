@import '../../../variables';

.msg-printer-view {
  padding-top: 10px;
  @include setFont('IBM Plex Sans', 400, 0.8125rem, 1.1875rem);
  .msg-content {
    word-break: break-word;
  }
  &.ok-txt {
    .msg-content {
      color: $txt_clr10;
    }
  }

  &.error-txt {
    .msg-content {
      color: $txt_clr13;
    }
  }

  &.warning-txt {
    .msg-content {
      color: $txt_clr12;
    }
  }
}
