@import '../../../variables';

.blocks-page {
  position: relative;

  .table-wrapper .table-content.blocks-table {
    tr {
      cursor: pointer;
    }

    .header-table tr th:nth-child(4) {
      padding-left: 20px;
    }

    .body-table {
      .body-row {
        height: 65px;

        td {
          p:first-child {
            margin-bottom: 6px;

            a {
              @include setFont('IBM Plex Sans', 500, 0.9375rem, 0.9375rem);
            }
          }
        }

        td:nth-child(4) {
          color: $txt_clr7;
          padding-left: 20px;
        }

        &.unactive {
          // for disable row
          td {
            color: $txt_clr3;
          }

          td:nth-child(1) {
            opacity: 0.5;
          }
        }
      }
    }
  }

  .pagination-block.top-pagination {
    padding-bottom: 30px;
    padding-top: 50px;
  }

  @media only screen and (max-width: 1370px) {
    width: 70%;
    .table-wrapper .table-content.blocks-table {
      .body-table {
        .body-row {
          td:nth-child(1) {
            min-width: 0;
            flex: 1 1 100%;
            width: 20%;

            p {
              display: block;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 200px;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 980px) {
    .table-wrapper .table-content.blocks-table {
      .body-table {
        .body-row {
          td:nth-child(1) {
            min-width: 0;
            flex: 1 1 100%;
            width: 20%;

            p {
              display: block;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 210px;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1502px) {
    .table-wrapper .table-content.blocks-table .body-table {
      .body-row {
        td:nth-child(1) {
          min-width: 0;
          flex: 1 1 100%;
          width: 20%;

          p {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 360px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: $mobileScreen) {
    .pagination-block.top-pagination .pagination-content {
      display: none;
    }
    .table-wrapper .table-content.blocks-table .header-table tr th:nth-child(1) {
      width: 30%;
    }
    .blocks-table {
      .header-table {
        tr {
          th:nth-child(1) {
            width: 20%;
          }

          th:nth-child(4) {
            display: none;
          }
        }
      }

      .body-table {
        .body-row {
          td:nth-child(1) {
            min-width: 0;
            flex: 1 1 100%;
            width: 20%;

            p {
              display: block;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 100px;
            }
          }

          td:nth-child(4) {
            display: none;
          }

          td:nth-child(5) {
            font-size: 0.8125rem;
          }
        }
      }
    }
  }
}
