@import '/../../../variables';

@media only screen and (min-width: $desktopScreen) {
  .token-info-block .action-link span:hover {
    color: $txt_clr5;
    transition: color 200ms;
    -webkit-transition: color 200ms;
  }
}

.token-info-block {
  display: flex;
  flex-direction: column;

  .title-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    @include setFont('IBM Plex Sans', 400, 0.9375rem, 150%);
    color: $txt_clr3;

    .info-icon {
      display: none; // temporary
      position: relative;
      //display: inline-block;
      min-height: 24px;
      max-width: 24px;
      min-width: 24px;
      max-height: 24px;
      background-image: url('../../../img/gray-info.svg');
      background-size: cover;
      margin-left: 6px;

      .tooltip-text {
        @include setFont('IBM Plex Sans', 400, 0.8125rem, 1.1875rem);
        visibility: hidden;
        min-height: 32px;
        min-width: 70px;
        background: $bg_clr5;
        border-radius: 6px;
        color: $txt_clr2;
        text-align: center;
        position: absolute;
        z-index: 1;
        bottom: 125%;
        margin-right: -30px;
        opacity: 0;
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
        padding: 6px 8px;
        white-space: nowrap;
      }

      &:hover .tooltip-text {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
      }
    }
  }

  .info-block {
    @include setFont('IBM Plex Sans', 500, 1.0625rem, 1.8125rem);
    margin-top: 5px;
    padding-right: 5px;

    .number-converter {
      margin-left: 5px;
    }

    span:first-child {
      padding-right: 4px;
    }
  }

  .action-link {
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;

    span {
      @include setFont('IBM Plex Sans', 400, 0.9375rem, 150%);
      text-decoration: underline;
      color: $txt_clr8;
      margin-right: 6px;
      transition: color 200ms;
      -webkit-transition: color 200ms;
    }
  }
}
