@import '../../../variables';

@media only screen and (min-width: $desktopScreen) {
  .main-header .nav-bar .nav-item:hover {
    opacity: 1;
    transition: opacity 200ms;
    -webkit-transition: opacity 200ms;
  }
}

.main-header,
.modal-menu {
  .nav-bar {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    @include setFont('Montserrat', 600, 0.875rem, 1.0625rem);
    text-transform: uppercase;
    color: $txt_clr4;
    text-align: justify;

    .nav-item {
      display: flex;
      align-items: center;
      height: 100%;
      @include setFont('Montserrat', 600, 0.875rem, 1.0625rem);
      text-transform: uppercase;
      color: $txt_clr4;
      letter-spacing: 0.5px;
      opacity: 0.6;
      transition: opacity 200ms;
      -webkit-transition: opacity 200ms;

      &.disable {
        position: relative;
        pointer-events: none;
        color: #bfbfc2;

        span:last-of-type {
          position: absolute;
          content: '';
          top: 20px;
          right: -37px;
          color: $txt_clr10;
          @include setFont('Montserrat', 600, 0.625rem, 0.75rem);
          opacity: 1;
        }
      }
      &:last-of-type {
        margin-right: auto;
      }
    }

    .nav-active {
      opacity: 1;
      border-bottom: 3px solid $brdr_clr6;

      span {
        padding-top: 3px;
      }
    }
  }
  .select-lng-list {
    left: -8px;
    top: -16px;
    max-width: 50px;
    width: 100%;
  }
}
.modal-menu {
  .nav-bar {
    .select-lng-list {
      margin-left: 28px;
      order: 2;
      margin-top: 10px;
    }
  }
}
.main-header {
  height: 81px;
  width: 100%;
  //@extend %centerContent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $brdr_clr4;
  background-color: white;
  padding: 0 40px;

  .icon-block {
    .main-logo {
      height: 47px;
      margin-right: 10px;
    }
  }

  .select-lng-list {
    margin-left: initial;
    order: initial;
    margin-top: initial;
  }

  svg {
    margin-right: 10px;
    cursor: pointer;
  }

  &:first-child {
    display: flex;
    align-items: center;
  }
  .btn-menu-wrapper {
    display: none;
  }

  a {
    margin: 0 12px;
  }

  @media only screen and (max-width: 340px) {
    flex-wrap: wrap;
  }

  @media only screen and (max-width: 1160px) {
    height: initial;
    //flex-direction: column;
    // align-items: flex-start;
    //flex-wrap: wrap;
    padding: 14px 8px 0 8px;
    .icon-block {
      display: flex;
      align-items: center;
      margin-bottom: 18px;
    }
    .select-lng-list {
      display: none;
    }
    .btn-menu-wrapper {
      display: block;
      margin-right: 5px;
    }
    .nav-bar {
      display: none;
    }
    > svg {
      width: 91px;
      height: 31px;
    }
    .search-block {
      //margin-top: 18px;
      margin-bottom: 7px;
      margin-left: 0;
      min-height: 46px;
      width: 100%;
    }
  }
  @media only screen and (max-width: 854px) {
    .nav-bar .nav-item {
      font-size: 0.7rem;
    }
  }
  @media only screen and (max-width: 1276px) {
    a {
      margin-left: 0;
    }
  }
}
