@import '../../../variables';

@keyframes slideRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.mobile-modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}

.modal-menu {
  display: flex;
  flex-direction: row;
  height: 100%;
  animation: slideRight 0.4s linear forwards;

  .modal-menu-content {
    height: 100%;
    width: 100%;
    min-width: 300px;
    background-color: $bg_clr0;
    box-shadow: 8px 0px 8px rgba(0, 0, 0, 0.08), 16px 0px 16px rgba(0, 0, 0, 0.08), 32px 0px 32px rgba(0, 0, 0, 0.08);

    .header-modal {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-top: 14px;
      padding-left: 14px;
    }

    .nav-bar {
      flex-direction: column;
      align-items: flex-start;
      padding-top: 70px;

      .nav-item:not(.disable) {
        width: 100%;
      }

      .nav-item {
        height: initial;
        padding: 11px 0 11px 21px;
        margin-left: 2px;
        border-bottom: none;
        border-left: 3px solid transparent;

        &.disable {
          &:before {
            top: 0;
          }
        }
      }

      .nav-active {
        border-left-color: $brdr_clr6;

        span {
          padding-top: 0;
        }
      }
    }
  }

  .click-block {
    width: 100%;
  }
}
