@import '../../../variables';

.custom-slider {
  width: 100%;

  .swiper-container {
    z-index: initial;

    .swiper-wrapper {
      z-index: initial;

      .swiper-slide {
        max-width: 245px;
        width: 100%;
        padding-left: 17px;

        &:last-of-type {
          max-width: 267px;
        }
      }
    }
  }
}
