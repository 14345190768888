.convert-result-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  @media only screen and (max-width: $mobileScreen) {
    padding-top: 50px;
  }

  .show-status-done {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 18px;
    padding-right: 18px;
    height: 100%;
    width: 100%;

    .status-icon {
      margin-bottom: 50px;
    }

    .count-trx-info {
      text-align: center;
      @include setFont('IBM Plex Sans', 400, 0.938rem, 150%);
      max-width: 330px;
      padding-top: 24px;

      .count-convert {
        padding-right: 5px;
      }

      a {
        color: $txt_clr6;
      }
    }

    .status-title {
      @include setFont('IBM Plex Sans', 600, 1.3125rem, 2.1875rem);
      max-width: 300px;
      text-align: center;
    }

    .click-btn.with-bg.blue {
      margin-top: 45px;
      max-width: 360px;
      width: 100%;
    }

    .status-subtitle {
      @include setFont('IBM Plex Sans', normal, 0.9375rem, 150%);
      text-align: center;
      padding-bottom: 70px;
    }
  }

  .result-icon {
    width: 100px;
    height: 100px;

    &.ok {
      circle {
        stroke: $bg_clr10;
      }
    }
  }

  .msg-printer-view {
    font-size: 1.2rem;
    text-align: center;
  }
}
