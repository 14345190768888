@import '../../../variables';

.transactions-table {
  tbody {
    *:not(a) {
      color: #2b2a34;
    }
    a {
      color: #428bca;
    }
  }
}

.memo-column {
  display: flex;
  align-items: center;
  min-width: 0;
  flex: 1 1 100%;

  .memo-title {
    background: $bg_clr1;
    border-radius: 4px;
    color: $txt_clr3;
    @include setFont('IBM Plex Sans', 400, 0.688rem, 1rem);
    text-transform: uppercase;
    padding-left: 6px;
    padding-right: 6px;
    max-height: 33px;
    margin-right: 8px;
  }

  .table-row {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 220px;
  }
}

.wallet-history {
  display: flex;
  flex-direction: column;
  //align-items: center;
  margin: 0 auto;
  padding: 0px 20px 60px;
  position: relative;

  .title-page {
    margin-bottom: 38px;
  }

  .top-content {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 42px;

    .filter-content {
      display: flex;
      flex-direction: column;
      white-space: nowrap;

      .mobile-filters {
        display: none;

        .dropdown {
          display: flex;
          flex-direction: row;
          align-items: center;

          > p {
            font-style: normal;
            @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
            color: $txt_clr3;
          }

          .react-select-container {
            max-width: 242px;
            max-height: 72px;
            width: 100%;

            .label {
              color: $txt_clr3;
              @include setFont('IBM Plex Sans', 500, 0.8125rem, 1.1875rem);
            }

            .react-select__menu {
              padding: 10px;
              border-radius: 8px;

              .react-select__option--is-selected {
                background: $bg_clr6;
                border-radius: 8px;

                .label {
                  color: $txt_clr0;
                }
              }
            }

            .react-select__control {
              width: 145px;
              height: 44px;
              background: $bg_clr6;
              border: 3px solid $brdr_clr7;

              .label {
                color: $txt_clr0;
              }

              .react-select__indicator {
                > svg {
                  fill: $bg_clr0;
                }
              }

              .react-select__value-container .select-row {
                .token-icon {
                  min-height: 28px;
                  min-width: 28px;
                  max-width: 28px;
                  max-height: 28px;
                  margin-right: 8px;
                }

                .label-row .subtitle {
                  display: none;
                }
              }
            }
          }
        }

        .asset-dropdown {
          margin-left: 20px;

          .react-select-container {
            .react-select__control {
              width: 109px;
            }
          }
        }
      }
    }

    .refresh-block {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background-color: $bg_clr1;
      border-radius: 8px;
      min-width: 105px;
      margin-left: 15px;
      min-height: 40px;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 15px;
      cursor: pointer;

      svg {
        margin-left: 6px;
      }

      span {
        @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
        color: $txt_clr9;
      }
    }
  }

  .history-trx {
    display: none;
  }

  @media only screen and (max-width: $tabletScreen) {
    padding-top: initial;
    .top-content {
      flex-wrap: wrap;

      .refresh-block {
        width: initial;
        margin-left: initial;
      }
    }
  }
  @media only screen and (max-width: 840px) {
    .filter-history {
      align-items: initial;

      .filter-block {
        flex-wrap: wrap;

        .filter-item {
          margin-bottom: 10px;
        }
      }
    }
  }
  @media only screen and (max-width: $mobileScreen) {
    padding-top: 15px;
    .title-page {
      text-align: center;
      text-transform: uppercase;
    }
    .desktop-filters {
      display: none;
    }
    .history-table {
      display: none;
    }
    .history-trx {
      display: block;
    }

    .top-content {
      flex-wrap: wrap;

      .refresh-block {
        display: none;
      }

      .filter-content {
        .mobile-filters {
          display: flex;
          flex-direction: row;
        }
      }
    }
  }
  @media only screen and (min-width: 1880px) {
    .history-table .table-content .body-table .body-row td:nth-child(3) div .table-row {
      max-width: 400px;
    }
  }
  @media only screen and (max-width: 1152px) {
    .top-content {
      flex-direction: column;
      align-items: flex-start;
      .filter-content {
        width: 100%;
      }
      .refresh-block {
        margin-left: 0;
      }
    }
  }
  @media only screen and (max-width: 1000px) {
    .top-content .filter-content .filter-history {
      flex-direction: column;
      align-items: initial;
      .title-filter {
        margin-bottom: 10px;
      }
    }
  }

  @media only screen and (max-width: 400px) {
    .top-content {
      .filter-content {
        .mobile-filters {
          flex-direction: column;

          .asset-dropdown {
            margin: 20px 0 0 0;
          }
        }
      }
    }
  }
}
