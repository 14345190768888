@import '../../../variables';

.table-wrapper {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  overflow-y: hidden;

  .retry-load-table-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;

    .refresh-block {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background-color: $bg_clr1;
      border-radius: 8px;
      min-width: 105px;
      margin-left: 15px;
      min-height: 40px;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 15px;
      cursor: pointer;

      width: fit-content;

      svg {
        margin-left: 6px;
      }

      span {
        @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
        color: $txt_clr9;
      }
    }
  }

  &.relative {
    position: relative;
  }

  &.min-height {
    min-height: 200px;
  }

  &.lock-history-table {
    position: relative;
    min-height: 525px;

    tr {
      th:nth-child(1) {
        width: 16% !important;
      }

      th:nth-child(2),
      th:nth-child(3) {
        width: 10% !important;
      }

      th:nth-child(4) {
        width: 15% !important;
        text-align: center !important;
      }
    }

    td:nth-child(4) {
      text-align: center !important;
    }
  }

  .table-content {
    width: 100%;
    border-spacing: initial;
    border-collapse: collapse;

    .header-table {
      min-height: 44px;
      background-color: #f8f9fb;
      padding: 0 15px;
      margin-bottom: 11px;

      tr {
        th {
          @include setFont('Montserrat', 600, 0.6875rem, 0.8125rem);
          color: $txt_clr3;
          text-transform: uppercase;
          height: 44px;
          text-align: left;

          .header-cell-wrapper {
            display: flex;
            align-items: center;

            &.with-sort {
              cursor: pointer;
            }

            .sort-direction-icon-wrapper {
              width: 20px;
              height: 24px;
              position: relative;

              .sort-direction-icon {
                position: absolute;
                left: 0;
                width: 20px;
              }
            }
          }
        }

        th:nth-child(1) {
          width: 15%;
          padding-left: 15px;
        }

        th:nth-child(2) {
          width: 20%;
        }

        th:nth-child(3) {
          width: 40%;
          padding-right: 20px;
          text-align: right;
        }

        th:nth-child(4) {
          width: 20%;
          padding-right: 15px;
          text-align: right;
        }
      }
    }

    .body-table {
      .body-row {
        height: 48px;
        border-bottom: 1px solid $brdr_clr4;

        &.serialized-view-wrapper {
          background-color: $bg_clr2;
          width: 100%;
          //height: initial;
          min-height: 48px;
          display: none;

          &.open {
            display: table-row;

            ul li ul li {
              max-width: 1100px;
              white-space: pre-wrap;
            }
          }

          td {
            width: 100%;
            padding: 10px !important;
          }
        }

        &.warning {
          background-color: rgba(255, 0, 0, 0.3);
        }

        td {
          @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.25rem);
        }

        td:nth-child(1) {
          padding-left: 15px;
        }

        td:nth-child(3) {
          text-align: right;
          padding-right: 20px;
        }

        td:nth-child(4) {
          text-align: right;
          padding-right: 15px;
        }
      }
    }

    &.blocks-table {
      .header-table {
        tr {
          th:nth-child(1) {
            width: 35%;
            padding-left: 15px;
          }

          th:nth-child(2) {
            width: 15%;
            text-align: center;
            padding-right: 10px;
          }

          th:nth-child(3) {
            text-align: center;
            width: 10%;
            padding-right: 10px;
          }

          th:nth-child(4) {
            width: 15%;
            text-align: initial;
          }

          th:nth-child(5) {
            width: 20%;
          }
        }
      }

      .body-table {
        .body-row {
          td {
            padding-right: 0 !important;
          }

          td {
            a {
              color: $txt_clr7;
            }

            p {
              color: $txt_clr3;
            }
          }

          td:nth-child(1) {
            @include setFont('IBM Plex Sans', 400, 0.8125rem, 0.8125rem);
          }

          td:nth-child(2) {
            color: $txt_clr7;
          }

          td:nth-child(2),
          td:nth-child(3) {
            text-align: center;
          }

          td:nth-child(4) {
            text-align: left;
          }
        }
      }
    }

    &.transactions-table {
      .header-table {
        tr {
          th:nth-child(1) {
            max-width: 15%;
          }

          th:nth-child(2) {
            width: 13%;
          }

          th:nth-child(3) {
            width: 12%;
            text-align: initial;
          }

          th:nth-child(4) {
            width: 7%;
            padding-right: 0;
            text-align: initial;
          }

          th:nth-child(5) {
            width: 8%;
          }

          th:nth-child(6) {
            width: 15%;
          }

          th:nth-child(7) {
            width: 10%;
            justify-content: flex-end;
            padding-right: 20px;
          }

          th:nth-child(8) {
            width: 15%;
          }
        }
      }

      .body-table {
        .body-row {
          td:nth-child(-n + 5), // why exactly +4?? Not sure but this changes can affect other tables 
          a {
            text-align: left;
            color: $txt_clr7;
          }

          td:nth-child(1) {
            width: 20%;
            min-width: 0;
            flex: 1 1 100%;
            padding-right: 10px;
            word-break: initial;

            a {
              display: block;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 150px;
            }
          }

          td:nth-child(2) {
            width: 13%;
          }

          td:nth-child(3) {
            width: 12%;
          }

          td:nth-child(4) {
            width: 7%;
            padding-right: 0;
          }

          td:nth-child(5) {
            width: 8%;
          }

          td:nth-child(6) {
            width: 15%;
          }

          td:nth-child(7) {
            width: 10%;
            //text-align: right;
            padding-right: 20px;
          }

          td:nth-child(8) {
            width: 15%;
          }

          td:last-child {
            padding-right: 15px;
          }
        }
      }
    }
  }
}
