@import '../../../variables';

.accounts-page {
  .page-content {
    .table-wrapper {
      tr {
        th:nth-child(1) {
          width: 70%;
          padding-left: 15px;
        }

        th:nth-child(2) {
          width: 30%;
          text-align: right;
          padding-right: 100px;
        }
      }

      a {
        color: $txt_clr7;
      }

      td:nth-child(2) {
        text-align: right;
        padding-right: 15px;
      }
    }
  }

  @media only screen and (max-width: $mobileScreen) {
    .pagination-block {
      .count-pages {
        display: none;
      }
    }
  }
}
