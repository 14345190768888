.wallet-dividends {
  .process-msg {
    padding: 20px;
  }

  .wallet-dividends-table {
    td,
    th {
      padding-right: 20px !important;
      color: $txt_clr7;
    }

    th:nth-child(1),
    td:nth-child(1) {
      width: 5% !important;
    }
    th:nth-child(2),
    td:nth-child(2) {
      width: 5% !important;
    }
  }

  .username-panel {
    display: flex;
    padding: 20px;
  }
}
