@import '../../../variables';

@media only screen and (min-width: $desktopScreen) {
  .control-btn:not(.active-btn):hover {
    border-color: #bac2ca;
    //border-right: 1px solid #BAC2CA !important;
    color: $txt_clr4;
    transition: color 10ms, box-shadow 200ms, background-color 200ms;
    -webkit-transition: color 10ms, box-shadow 200ms, background-color 200ms;

    //&:last-child:hover {
    //  border-left: 1px solid $brdr_clr4 !important;
    //}
    //
    //&:first-child:hover {
    //  border-right: 1px solid $brdr_clr4 !important;
    //}

    //&:hover + div {
    //  border-left: initial !important;
    //}
  }
}

.control-btn {
  @include setFont('IBM Plex Sans', 500, 0.9375rem, 1.25rem);
  color: $txt_clr3;
  white-space: nowrap;
  cursor: pointer;
  padding: 12px;
  border: 1px solid $brdr_clr4;
  transition: color 10ms, box-shadow 200ms, background-color 200ms;
  -webkit-transition: color 10ms, box-shadow 200ms, background-color 200ms;

  &:not(:last-child) {
    border-right: initial;
  }

  &:first-child {
    border-radius: 9px 0px 0px 9px;
  }

  &:last-child {
    border-radius: 0px 9px 9px 0px;
  }

  &:active, //temporary
  &.active-btn {
    background: $bg_clr7;
    box-shadow: 0px 4px 4px rgba(52, 94, 169, 0.15), 0px 8px 8px rgba(52, 94, 169, 0.15),
      0px 16px 16px rgba(52, 94, 169, 0.15), 0px 32px 32px rgba(52, 94, 169, 0.15);
    color: $txt_clr0;
    border: initial;
    padding-top: 13px;
    padding-bottom: 13px;

    &:last-child {
      border-left: initial;
    }

    &:last-child {
      border-right: initial;
    }

    &:not(:last-child) {
      border-right: initial;
      padding-right: 13px;
    }
  }

  &.tab-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 40px;
    background-color: $bg_clr2;
    @include setFont('IBM Plex Sans', 500, 0.9375rem, 1.375rem);
    color: $txt_clr3;
    transition: color 200ms, background-color 200ms, box-shadow 200ms;
    border: initial;

    &:first-of-type {
      border-radius: 8px 0px 0px 8px;
    }

    &:last-of-type {
      border-radius: 0px 8px 8px 0px;
    }

    &.active-btn {
      color: $txt_clr0;
      background-color: $bg_clr6;
      box-shadow: 0px 4px 4px rgba(52, 94, 169, 0.15), 0px 8px 8px rgba(52, 94, 169, 0.15),
        0px 16px 16px rgba(52, 94, 169, 0.15), 0px 32px 32px rgba(52, 94, 169, 0.15);
      transition: color 200ms, background-color 200ms, box-shadow 200ms;
    }
  }
}
