@import '../../../variables';

%title {
  @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
  color: $txt_clr3;
}

.dashboard-page {
  position: relative;

  .preloader-wrapper {
    background-color: rgba(255, 255, 255, 0.4);
  }

  .dashboard-portfolio {
    .tokens-filter-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      .click-btn {
        height: 37px;
        white-space: nowrap;
        padding: 10px 20px;
        width: fit-content;
        &:hover {
          background-color: $bg_clr10;
        }
      }
    }

    .portfolio-header {
      display: flex;
      align-items: center;
      flex-direction: row;
      padding-bottom: 25px;

      .title-page {
        margin-bottom: initial;
        text-transform: none;
      }

      .MuiTab-root {
        padding: 10px 35px;

        .MuiTab-wrapper {
          flex-direction: row;
        }
      }
    }

    .refresh-block {
      @extend .portfolio-header;
      cursor: pointer;
      margin-right: 40px;
      align-items: center;
      padding-bottom: 0;

      > svg {
        min-height: 24px;
        min-width: 24px;
        cursor: pointer;
      }

      .refresh-title {
        @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
        color: $txt_clr9;
        margin-left: 5px;
        margin-right: 10px;
      }
    }

    .wallet-card-token {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      border: 1px solid $brdr_clr1;
      box-shadow: 0px 4px 4px rgba(119, 119, 119, 0.05);
      border-radius: 10px;
      padding: 30px 20px 17px 30px;
      margin-bottom: 18px;
      width: 792px;
      min-height: 177px;
      height: 100%;
      background-image: url('../../../img/bg_wallet.svg');
      background-repeat: no-repeat;
      background-position: left center;
      background-size: contain;
      position: relative;
      @media only screen and (max-width: 800px) {
        width: 100%;
      }

      &.cru-token {
        .card-icon {
          background-image: url('../../../img/new_cru.png');
        }
      }

      &.wcru-token {
        .card-icon {
          background-image: url('../../../img/new_wcru.png');
        }
      }

      &.untb-token {
        .card-icon {
          background-image: url('../../../img/new_untb.png');
        }
      }

      &.usdu-token {
        background-image: url('../../../img/usdu_bg.svg');
        background-size: cover;

        .card-icon {
          background-image: url('../../../img/new_usdu.png');
        }
      }

      .name-card {
        display: flex;
        flex-direction: column;
        // min-width: 165px;
        width: 207px;
        margin-right: 40px;

        .card-fallback-icon,
        .card-icon {
          background-size: cover;
          width: 48px;
          min-width: 48px;
          height: 48px;
          min-height: 48px;
        }

        .title-block {
          display: flex;
          flex-direction: column;
          margin-top: 10px;

          .card-title {
            @include setFont('IBM Plex Sans', 600, 1.3125rem, 2.1875rem);
            color: $txt_clr4;
            text-transform: uppercase;
          }

          .full-title {
            @extend %title;
          }
        }
      }

      .data-card {
        display: flex;
        flex-direction: column;
        position: relative;

        .card-sm-icon {
          display: none;
          position: absolute;
          right: 0;
          height: 35px;
        }

        .total-title {
          @extend %title;
        }

        .count-token {
          @include setFont('IBM Plex Sans', 500, 1.75rem, 2.9375rem);
          color: $txt_clr4;
        }

        .number-converter {
          @extend %title;
        }

        .data-row-block {
          padding-top: 17px;
          margin-top: 10px;

          .data-row-token {
            display: flex;
            align-items: center;
            flex-direction: row;

            .status-title {
              @extend %title;
              min-width: 110px;
            }

            .row-content {
              display: flex;
              justify-content: flex-end;
              padding-left: 10px;
              margin-right: 15px;
              min-width: 110px;

              .count-row {
                @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.5625rem);
                color: $txt_clr4;

                &.digits {
                  opacity: 0.5;
                }
              }
            }

            .progress-row-content {
              display: flex;
              align-items: center;

              .count-progress-line {
                @include setFont('IBM Plex Sans', 400, 0.75rem, 1.125rem);
                color: $txt_clr3;
                margin-left: 5px;
              }

              .progress-wrapper {
                height: 6px;
                width: 100px;
                border-radius: 3px;
                margin-bottom: 0;

                .lines-counts {
                  display: none;
                }

                .progress-line {
                  height: 100%;

                  &.available-line {
                    background-color: $bg_clr7;
                  }

                  &.staked-line {
                    background-color: $bg_clr10;
                  }

                  &.frozen-line {
                    background-color: $bg_clr3;
                  }
                }
              }
            }
          }
        }
      }

      .btn-list {
        display: flex;
        // align-items: flex-end;
        margin-left: auto;
        position: absolute;
        right: 20px;
        top: 30px;

        .wrapper-btn {
          display: flex;
          flex-direction: column;
          align-items: center;

          &:not(:first-of-type) {
            margin-left: 30px;
          }

          .title-btn {
            @include setFont('IBM Plex Sans', 400, 0.8125rem, 150%);
            margin-top: 8px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 800px) {
    width: 100% !important;
    .dashboard-portfolio .wallet-card-token {
      width: 100%;
    }
  }

  @media only screen and (max-width: $mobileScreen) {
    .dashboard-portfolio .wallet-card-token {
      flex-direction: column;
      width: 100%;
      padding: 10px 16px 16px 16px;

      &:not(.usdu-token) {
        background-position: right top;
        background-image: url('../../../img/right_bg.svg');
      }
      &.usdu-token {
        background-image: url('../../../img/usdu_sm_bg.svg');
        background-size: cover;
      }
      .data-card .data-row-block .data-row-token {
        .status-title {
          min-width: 84px;
        }
        .row-content {
          min-width: 100px;
          padding-left: initial;
        }
      }

      .name-card {
        display: none;
      }

      .btn-list {
        flex-direction: row;
        margin-left: initial;
        margin-top: 30px;
        position: initial;
      }
    }
    .dashboard-portfolio .portfolio-header {
      flex-direction: column;
      align-items: initial;
    }
    .dashboard-portfolio .portfolio-header .refresh-block {
      flex-direction: row;
      margin-top: 10px;
      margin-left: 16px;
      &:last-child {
        margin-top: 4px;
      }
    }
    .dashboard-portfolio .wallet-card-token .data-card .card-sm-icon {
      display: block;
    }
  }
  @media only screen and (max-width: 340px) {
    .dashboard-portfolio .wallet-card-token .data-card {
      .data-row-block .data-row-token .progress-row-content .progress-wrapper {
        width: 50px;
      }
      .data-row-block .data-row-token {
        .status-title {
          min-width: 77px;
        }
      }
    }
  }
}
