@import '../../../variables';

$shadowCru: $shadowPart_1 rgba(52, 94, 169, 0.15), $shadowPart_2 rgba(52, 94, 169, 0.15), $shadowPart_3 rgba(52, 94, 169, 0.15),
  $shadowPart_4 rgba(52, 94, 169, 0.15), $shadowPart_5 rgba(52, 94, 169, 0.15);
$shadowUntb: $shadowPart_1 rgba(30, 32, 35, 0.15), $shadowPart_2 rgba(30, 32, 35, 0.15), $shadowPart_3 rgba(30, 32, 35, 0.15),
  $shadowPart_4 rgba(30, 32, 35, 0.15), $shadowPart_5 rgba(30, 32, 35, 0.15);
$shadowUsdu: $shadowPart_1 rgba(82, 170, 85, 0.15), $shadowPart_2 rgba(82, 170, 85, 0.15), $shadowPart_3 rgba(82, 170, 85, 0.15),
  $shadowPart_4 rgba(82, 170, 85, 0.15), $shadowPart_5 rgba(82, 170, 85, 0.15);

@media only screen and (min-width: $desktopScreen) {
  .card-wrapper.untb-card .card-token:hover {
    box-shadow: $shadowUntb;
  }
  .card-wrapper.cru-card .card-token:hover {
    box-shadow: $shadowCru;
  }
  .card-wrapper.usdu-card .card-token:hover {
    box-shadow: $shadowUsdu;
  }
  .card-wrapper.wcru-card .card-token:hover {
    box-shadow: 0px 32px 32px #d8d8d8, 0px 16px 16px #d8d8d8, 0px 8px 8px #d8d8d8, 0px 4px 4px #d8d8d8;
  }
  .card-wrapper .card-token:hover {
    opacity: 1;
    transition: box-shadow 350ms ease, opacity 450ms ease;
    -webkit-transition: box-shadow 350ms ease, opacity 450ms ease;
  }
}

.card-wrapper {
  padding-left: 40px;
  margin-bottom: 24px;
  padding-right: 45px;
  cursor: pointer;
  border-left: 6px solid transparent;

  &.active {
    .card-token {
      opacity: 1;
    }
  }

  &.cru-card {
    &.active {
      border-color: $brdr_clr8;

      .card-token {
        box-shadow: $shadowCru;
      }
    }

    .card-token {
      background-image: url('../../../img/cru_background.svg');

      .headers-title {
        .card-icon {
          background-image: url('../../../img/new_cru.png');
        }
      }
    }
  }
  &.wcru-card {
    .card-token {
      background-position: right top;
    }
    &.active {
      border-color: #241659;

      .card-token {
        box-shadow: 0px 32px 32px #d8d8d8, 0px 16px 16px #d8d8d8, 0px 8px 8px #d8d8d8, 0px 4px 4px #d8d8d8;
      }
    }

    .card-token {
      background-image: url('../../../img/wcru_background.svg');

      .headers-title {
        .card-icon {
          background-image: url('../../../img/new_wcru.png');
        }
      }
    }
  }

  &.untb-card {
    &.active {
      border-color: #1c2427;

      .card-token {
        box-shadow: $shadowUntb;
      }
    }

    .card-token {
      background-image: url('../../../img/untb_background.svg');

      .card-icon {
        background-image: url('../../../img/new_untb.png');
      }
    }
  }

  &.usdu-card {
    &.active {
      border-color: $brdr_clr10;

      .card-token {
        box-shadow: $shadowUsdu;
      }
    }

    .card-token {
      background-image: url('../../../img/usdu_background.svg');

      .card-icon {
        background-image: url('../../../img/new_usdu.png');
      }

      .row-container:last-child {
        display: none;
      }
    }
  }

  .card-token {
    display: flex;
    flex-direction: column;
    border-radius: 32px;
    background-color: $bg_clr0;
    max-width: 358px;
    min-width: 340px;
    height: 198px;
    padding: 30px 30px 20px 22px;
    opacity: 0.75;
    background-position: right;
    transition: box-shadow 350ms ease, opacity 450ms ease;
    -webkit-transition: box-shadow 350ms ease, opacity 450ms ease;

    .card-icon {
      width: 55px;
      min-width: 55px;
      height: 55px;
      min-height: 55px;
      background-position: center;
      background-repeat: repeat;
      background-size: contain;
    }

    .headers-title {
      display: flex;
      align-items: center;
      flex-direction: row;
      max-width: 100%;
      border-radius: 32px;

      .card-title {
        display: flex;
        flex-direction: column;
        text-transform: uppercase;
        margin-left: 24px;

        .main-title {
          color: $txt_clr0;
        }

        .full-title {
          @include setFont('Montserrat', 600, 0.8125rem, 1rem);
          color: $txt_clr0;
          opacity: 0.75;
          padding-bottom: 8px;
        }
      }
    }

    .block-count {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: auto;

      .row-container {
        padding-right: 0;

        .count-row {
          color: $txt_clr0;
          padding-right: 10px;
        }

        .title-row {
          color: $txt_clr0;
          opacity: 0.75;
          min-width: 90px;
        }
      }
    }
  }
}
.flex-row {
  flex-direction: row;
}
.d-flex {
  display: flex;
}
.w-100 {
  width: 100%;

  .count-row {
    color: $txt_clr0;
  }
  .title-row {
    color: $txt_clr0 !important;
    opacity: 0.75;
  }
}
.space-between {
  justify-content: space-between;
}
