@import '../../../variables';

.account-details-page {
  position: relative;

  .page-content {
    .header-page {
      .title-img {
        .main-title {
          text-transform: initial;
        }
      }
    }

    .account-info {
      padding-bottom: 50px;

      .info-content {
        max-width: initial;

        .info-column {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        .info-txt {
          word-break: break-word;
        }

        &:last-child {
          .info-txt {
            color: $txt_clr7;
          }
        }
      }
    }

    .balance-block {
      padding-bottom: 60px;
      //max-width: 1225px;

      .lines-block {
        display: flex;
        align-items: flex-end;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 30px;
        border-bottom: 1px solid $brdr_clr4;
        border-left: 1px solid $brdr_clr4;
        border-right: 1px solid $brdr_clr4;
        border-radius: 0 0 9px 9px;

        .balance-line {
          margin-right: 10px;
          @media only screen and (min-width: 1450px) {
            width: 30%;
          }

          &:first-child {
            .line-title {
              background: rgba(82, 170, 85, 0.1);
              color: $txt_clr10;
            }
          }

          &:nth-child(2) {
            .line-title {
              background: $bg_clr13;
              color: $txt_clr8;
            }

            .active-line {
              background-color: $bg_clr8;
            }
          }

          &:last-child {
            .line-title {
              background: rgba(242, 153, 74, 0.1);
              color: #f2994a;
            }

            .active-line {
              background-color: #f2994a;
            }
          }

          .line-header {
            @include setFont('IBM Plex Sans', 500, 0.9375rem, 1.375rem);
            flex-wrap: wrap;

            .percent {
              padding-right: 10px;
            }

            .count-info {
              //margin-left: auto;

              .count-line {
                @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
              }
            }

            .line-title {
              text-transform: uppercase;
              border-radius: 5px;
              padding-right: 6px;
              padding-left: 6px;
              margin-right: 5px;
            }
          }

          .lines-container {
            max-width: initial;
          }
        }
      }

      .table-balance {
        position: relative;
        width: 100%;
        border: 1px solid $brdr_clr4;
        border-radius: 9px 9px 0 0;
        margin-top: 40px;
        overflow-x: auto;

        .table-content {
          width: 100%;
          border-spacing: initial;
          border-collapse: collapse;

          .header-table {
            @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
            color: $txt_clr3;
            border-bottom: 1px solid $brdr_clr4;
            height: 47px;
            padding-right: 24px;

            tr {
              border-bottom: 1px solid $brdr_clr4;
              height: 47px;

              th {
                font-weight: 400;
                text-align: right;
                padding-right: 15px;

                &:last-child {
                  padding-right: 24px;
                }
              }
            }
          }

          .body-table {
            .card-icon {
              width: 36px;
              min-width: 36px;
              height: 36px;
              margin-right: 13px;

              img {
                width: 100%;
              }
            }

            .body-row {
              border-bottom: 1px solid $brdr_clr4;
              height: 74px;

              &:last-child {
                border-bottom: initial;
              }

              td {
                @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
                padding-right: 15px;

                &:last-child {
                  padding-right: 24px;
                }
              }

              td:not(:first-child) {
                text-align: right;
              }

              td:first-child {
                padding-left: 24px;
                color: $txt_clr3;
              }

              td:nth-child(2) {
                @include setFont('IBM Plex Sans', 600, 1.3125rem, 1.875rem);
              }

              td:nth-child(4) {
                //cursor: pointer;
                //color: $txt_clr7;
                // border-bottom: 1px dashed $brdr_clr9;
              }

              td {
                div {
                  display: flex;
                  align-items: center;
                  white-space: pre-wrap;

                  svg {
                    width: 36px;
                    min-width: 36px;
                    height: 36px;
                    min-height: 36px;
                    margin-right: 13px;
                  }

                  p {
                    display: flex;
                    align-items: center;
                    @media only screen and (max-width: $tabletScreen) {
                      align-items: initial;
                    }

                    span:first-child {
                      @include setFont('IBM Plex Sans', 600, 1.3125rem, 1.875rem);
                      color: $txt_clr4;
                    }
                  }
                }
              }
            }
          }

          + p {
            @include setFont('IBM Plex Sans', 600, 0.8rem, 1.875rem);
            color: red;
            text-align: center;
          }
        }
      }
    }

    .header-block {
      @extend .header-page;
      align-items: baseline !important;
      flex-wrap: wrap;

      .main-title {
        padding-bottom: 15px;
      }
    }
  }

  @media only screen and (max-width: $mobileScreen) {
    .page-content .balance-block {
      .lines-block {
        padding-left: 17px;
        border-left: initial;
        border-right: initial;

        .balance-line {
          width: 100%;
        }
      }
    }
    .page-content .balance-block {
      .main-title {
        margin-left: 17px;
      }
    }
    .page-content .header-block {
      padding: 0 !important;

      .main-title {
        margin-left: 17px;
      }
    }
    .page-content .balance-block .table-balance {
      .table-content {
        td,
        th {
          padding-right: 15px;
        }

        .body-table .body-row {
          td:nth-child(2) {
            font-size: 1.125rem;
          }

          td {
            p {
              display: flex;
              flex-direction: column;
              padding: 7px;
            }
          }
        }
      }
    }
    .table-wrapper .table-content {
      tr,
      th {
        padding-right: 14px;
      }

      .header-table tr {
        //th:nth-child(n+4):nth-child(-n+7) {
        //  display: none;
        //}
      }

      .body-table .body-row {
        white-space: nowrap;
        //td {
        //  display: none;
        //}

        td:last-child {
          font-size: 0.8125rem;
        }
      }
    }
  }
  @media only screen and (max-width: 1246px) {
    width: 65% !important;
    .page-content .header-block .table-controls {
      //overflow: scroll;
    }
  }
  @media only screen and (min-width: 1500px) {
    .lines-wrapper .line-header .count-info {
      margin-left: auto;
    }
  }
}

@media only screen and (max-width: $tabletScreen) {
  .page-content .balance-block .table-balance .table-content {
    .body-table .body-row td:nth-child(2) {
      font-size: 1.125rem;
    }
  }
}

@media only screen and (max-width: $tabletScreen) {
  .ReactModal__Overlay.ReactModal__Overlay--after-open .modal-table {
    top: 7%;
  }
  .page-content .header-block {
    padding-bottom: 0 !important;
  }
  .page-content .balance-block .lines-block {
    flex-wrap: wrap;

    .balance-line {
      width: 100% !important;
    }
  }
}

@media only screen and (max-width: $mobileScreen) {
  .ReactModal__Overlay.ReactModal__Overlay--after-open .modal-table {
    top: 5%;

    .header-modal {
      padding: 25px 30px !important;

      svg {
        top: 15px !important;
      }
    }

    .table-wrapper {
      .header-table tr {
        th {
          padding-right: 15px !important;
          white-space: nowrap;
        }

        th:nth-child(1) {
          padding-left: 20px !important;
        }
      }

      .body-table .body-row {
        td {
          padding-right: 15px !important;
        }

        td:nth-child(1) {
          padding-left: 20px !important;
        }
      }
    }

    .pagination-block {
      padding: 25px 30px;

      .count-pages {
        display: none;
      }
    }
  }
}
