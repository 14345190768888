@import '../../../variables';

.breadcrumbs {
  list-style: none;
  margin: 0;

  .before-items {
    margin-bottom: 9px;

    .before-title {
      @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
      color: $txt_clr3;

      &:after {
        padding: 8px;
        content: '\002F';
      }
    }
  }

  .title-img {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 15px;
    }
  }
}
