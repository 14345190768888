@import '/../../../variables';

.select-lng-list {
  margin-left: 11px;
  max-height: 22px;
  margin-right: auto;

  .react-select__control {
    max-height: 22px;
    border: none;
    flex-wrap: nowrap;
    box-shadow: none;

    .react-select__indicator-separator {
      display: none;
    }

    .react-select__indicators {
      width: 10px;

      svg {
        margin-right: 0;
      }

      .react-select__indicator {
        padding-left: 0;
      }
    }

    .react-select__value-container {
      min-width: 22px;
      box-sizing: content-box;
      padding-right: 0;

      .select-row {
        display: flex;
        align-items: center;
        min-width: 22px;

        .flag {
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
      }
    }
  }

  .react-select__menu {
    border-radius: 8px;
    min-width: 150px;
    margin-top: 10px;
    padding-top: 6px;
    overflow-y: auto;
    text-transform: initial;
    z-index: 4;

    .react-select__menu-list {
      height: 100%;
      min-height: 392px;
      @media only screen and (max-width: $mobileScreen) {
        max-height: 195px;
        min-height: initial;
        height: 100%;
      }
      @media only screen and (max-width: 340px) {
        max-height: 130px;
      }

      &::-webkit-scrollbar {
        width: 0;
      }

      &::-webkit-scrollbar-thumb {
        opacity: 0.2;
        background-color: $bg_clr16;
      }

      .react-select__option {
        cursor: pointer;
        height: 28px;
        display: flex;
        @include setFont('IBM Plex Sans', 400, 0.875rem, 1rem);
        color: $txt_clr4;
        background-color: initial;
        margin-bottom: 4px;
        padding-bottom: 4px;
        padding-top: 4px;

        div {
          display: flex;
          align-items: center;

          div {
            min-width: 22px;
          }

          span:last-child {
            margin-left: 6px;
          }
        }
      }
    }
  }
}

.react-select-container {
  //width: 242px;
  max-height: 84px;
  margin-left: 6px;

  .react-select__control {
    @include setFont('IBM Plex Sans', 400, 0.8125rem, 1.1875rem);
    background: $bg_clr2;
    border: 3px solid $brdr_clr2;
    border-radius: 8px;
    //padding: 15px;
    height: 84px;
    width: 242px;
    cursor: pointer;

    &:hover {
      border: 3px solid $brdr_clr2;
      box-shadow: initial;
      --swiper-theme-color: none;
    }

    &:focus {
      outline: initial !important;
      box-shadow: initial;
      --swiper-theme-color: none;
    }

    .react-select__value-container {
      width: 100%;
      height: 100%;

      .select-row {
        display: flex;
        flex-direction: row;
        align-items: center;

        .token-icon {
          min-width: 42px;
          max-width: 42px;
          min-height: 42px;
          max-height: 42px;
          object-fit: contain;
          margin-right: 16px;
        }

        .label-row {
          display: flex;
          flex-direction: column;

          .label {
            @include setFont('IBM Plex Sans', 600, 1.3125rem, 2.1875rem);
          }

          .subtitle {
            color: $txt_clr3;
            font-weight: normal;
          }
        }
      }
    }

    .react-select__indicator-separator {
      display: none;
    }

    .react-select__indicator {
      color: $txt_clr3;
    }
  }

  .react-select__menu {
    .react-select__option {
      cursor: pointer;

      img {
        width: 30px;
        min-width: 30px;
        height: 30px;
        margin-right: 3px;
      }

      div {
        @include setFont('IBM Plex Sans', 400, 0.875rem, 1rem);
        color: $txt_clr4;
        display: flex;
        align-items: center;
        flex-direction: row;
        white-space: pre-wrap;

        div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          span:first-child {
            font-weight: 600;
          }

          span:last-child {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            font-size: 0.8125rem;
            line-height: 1.1875rem;
            color: $txt_clr3;
            font-weight: 400;
            margin-left: 1px;
          }
        }

        &:hover {
          transition: background-color 200ms;
          -webkit-transition: background-color 200ms;
        }
      }
    }
  }
}
