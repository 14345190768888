@import '../../../variables';

.d-flex.row-column {
  flex-direction: column;
}
.warning-text {
  color: $red1;
}
.hidden {
  display: none;
}

.staking-objects-table {
  margin-bottom: 40px;
  tr {
    th,
    td {
      width: 18% !important;
      padding: 0 8px !important;
      text-align: left !important;
      &:last-child {
        width: 10%;
      }

      .date-label {
        @include setFont('Montserrat', 500, 0.9rem, 1rem);
        color: $txt_clr4;
        opacity: 0.75;
        padding-top: 8px;
      }
      .date-value {
        color: $txt_clr5;
      }
    }

    td {
      color: $txt_clr5;
    }
  }

  .return-reward-btn {
    @include setFont('Montserrat', 500, 0.8rem, 0.8rem);
    margin-top: 5px;
    border-color: pink;
    color: pink;
  }

  .stackClosedIcon {
    width: 20px;
    height: 20px;
    path {
      fill: green;
    }
  }
}

.cru-block {
  .back-btn {
    @include setFont('IBM Plex Sans', 500, 1.0625rem, 1.59rem);
    color: $txt_clr4;
    margin-bottom: 4px;
    cursor: pointer;
    user-select: none;
  }

  .page-title {
    @include setFont('IBM Plex Sans', 600, 1.3125rem, 2.19rem);
    color: $txt_clr4;
    margin-bottom: 37px;

    > svg {
      display: none;
    }
  }

  .staking-update {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    background: $bg_clr2;
    border-radius: 8px;
    min-height: 80px;
    padding: 16px 20px 12px 20px;
    margin-bottom: 20px;
    //margin-top: 25px;

    .click-btn {
      border: none;
    }

    .update-title {
      svg {
        margin-right: 16px;
      }

      .title-count {
        display: flex;
        align-items: center;
        flex-direction: row;

        .staking-title {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
          color: $txt_clr3;
          max-width: 190px;

          span:first-child {
            padding-right: 4px;
          }

          svg {
            min-width: 24px;
            min-height: 24px;
            margin-left: 4px;
          }
        }

        .staking-count {
          @include setFont('IBM Plex Sans', 500, 1.063rem, 1.813rem);
          color: $txt_clr4;
          opacity: 0.5;
          padding-right: 14px;
        }
      }
    }

    .update-info {
      display: flex;
      align-items: center;
      @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
      color: $txt_clr3;

      span:first-child {
        padding-right: 4px;
      }

      > svg {
        cursor: pointer;

        &.active {
          animation: 1s InfRotation infinite;
        }
        &.disabled {
          pointer-events: none;
          cursor: not-allowed;
        }
      }
    }

    .update-btn {
      flex-direction: row-reverse;
      background-color: $bg_clr3;
      border-radius: 8px;
      min-height: 45px;
      width: initial;
      min-width: 150px;
      transition: background-color 200ms, box-shadow 200ms;
      -webkit-transition: background-color 200ms, box-shadow 200ms;
      text-transform: initial;

      .btn-icon {
        min-width: 24px;
        min-height: 24px;
        margin-right: 0;
      }

      .title-btn {
        @include setFont('IBM Plex Sans', 500, 1.063rem, 1.563rem);
        color: $txt_clr5;
        margin-left: 0;
      }
    }

    &.staking-claim {
      background: $bg_clr18;

      .update-title {
        display: flex;
        align-items: center;
        flex-direction: row;

        .title-count {
          align-items: initial;
          flex-direction: column-reverse;

          .staking-count {
            opacity: 1;
          }
        }
      }

      .claim-btn {
        background: $bg_clr10;
        border-radius: 8px;
        min-width: 129px;
        width: initial;

        .title-btn {
          @include setFont('IBM Plex Sans', 500, 1.063rem, 1.563rem);
          color: $txt_clr0;
          text-transform: initial;
          margin-left: 0;
        }
      }
    }
  }

  .wallet-content {
    .staking-input-block {
      display: flex;
      flex-direction: row;
      margin-bottom: 70px;

      .input-title {
        @include setFont('IBM Plex Sans', 500, 1.0625rem, 1.59rem);
        color: $txt_clr4;
        flex-grow: 1;
        padding-right: 85px;

        > :last-child {
          display: none;
        }
      }

      .input-row-block {
        height: 100%;
        min-width: 480px;
        max-width: 480px;

        .filled-in {
          padding-top: 9px;
          padding-bottom: 9px;
        }

        .additional-info {
          display: flex;
          flex-direction: row;

          .btn-block {
            margin: 0 0 0 30px;
          }
        }
      }
    }

    .staking-plan-block {
      display: flex;
      flex-direction: row;

      .dropdown-description {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding-right: 50px;

        > :first-child {
          @include setFont('IBM Plex Sans', 500, 1.0625rem, 1.59rem);
          color: $txt_clr4;
        }

        > :last-child {
          @include setFont('IBM Plex Sans', 400, 0.8125rem, 1.25rem);
          color: $txt_clr3;
        }
      }

      .plan-select {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-width: 480px;
        max-width: 480px;

        .select-amount {
          width: 100%;

          &:focus {
            outline: none;
          }

          .react-select__control {
            height: 64px;
            background-color: $bg_clr2;
            border-radius: 8px;
            color: $txt_clr4;
            @include setFont('IBM Plex Sans', 400, 1.0625rem, 1.5625rem);
            //padding: 19px 16px;
            border: none;
            position: relative;
            overflow: hidden;

            &:after {
              content: '';
              display: block;
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: $bg_clr19;
            }

            .react-select__placeholder {
              color: $txt_clr4;
              opacity: 0.5;
            }

            .react-select__value-container {
              .select-row {
                @include setFont('IBM Plex Sans', 400, 1.0625rem, 1.1875rem);
                color: $txt_clr4;
              }
            }
          }

          .react-select__menu {
            font-weight: 400;

            .react-select__option {
              cursor: pointer;
              height: 64px;
              display: flex;
              align-items: center;
              @include setFont('IBM Plex Sans', 400, 1.0625rem, 1.5625rem);
              color: $txt_clr4;
              border-bottom: 1px solid $brdr_clr3;

              div {
                transition: background-color 200ms;
                -webkit-transition: background-color 200ms;

                &:hover {
                  transition: background-color 200ms;
                  -webkit-transition: background-color 200ms;
                }
              }
            }
          }

          .react-select__indicator-separator {
            display: none;
          }

          .react-select__indicator {
            color: $txt_clr4;
          }
        }

        .plan-description {
          display: flex;
          flex-direction: row;
          padding: 34px 0 20px;
          border-bottom: 1px solid $brdr_clr1;

          .mobile {
            display: none;
          }

          .duration,
          .reward,
          .time-period {
            display: flex;
            flex-direction: column;

            > span {
              @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.41rem);
              color: $txt_clr3;
            }

            > :last-child {
              @include setFont('IBM Plex Sans', 500, 1.0625rem, 1.59rem);
              color: $txt_clr4;
              padding-top: 3px;
            }
          }

          > :first-child {
            margin-right: 30px;
          }

          .time-period {
            margin-left: auto;

            > :first-child {
              text-align: end;
            }

            > :last-child {
              color: $txt_clr5;
              border-bottom: 1px dashed $brdr_clr7;
              cursor: pointer;
              padding-top: 3px;
            }
          }
        }

        .description-text {
          padding: 20px 0;
          @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.41rem);
          color: $txt_clr3;
          border-bottom: 1px solid $brdr_clr1;
        }

        .profit-block {
          display: flex;
          flex-direction: column;
          padding: 18px 0 11px;
          border-bottom: 1px solid $brdr_clr1;

          > :first-child {
            @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.41rem);
            color: $txt_clr3;
          }

          > :last-child {
            @include setFont('IBM Plex Sans', 500, 1.75rem, 2.94rem);
            color: $txt_clr4;
          }
        }

        .btn-block {
          display: flex;
          flex-direction: column;
          padding-top: 12px;

          > :first-child {
            @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.41rem);
            color: $txt_clr3;
            margin: 0 auto 27px 0;
          }

          .click-btn {
            height: 63px;
          }
        }
      }
    }
  }

  .staking-history {
    margin-top: 50px;
    @media only screen and (max-width: $mobileScreen) {
      margin-top: 0;
    }

    .header-block {
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .update-ic {
        cursor: pointer;
      }

      .title-block {
        @include setFont('IBM Plex Sans', 500, 1.75rem, 2.938rem);
        color: $txt_clr4;
        @media only screen and (max-width: $mobileScreen) {
          padding-left: 18px;
          font-size: 1.3125rem;
        }
      }
    }

    .staking-history-table {
      position: relative;

      .table-content {
        th,
        td {
          white-space: nowrap;
        }

        .body-row {
          height: 80px;
        }

        th:nth-child(1),
        td:nth-child(1) {
          width: 25%;
        }

        th:nth-child(2),
        td:nth-child(2) {
          width: 25%;
        }

        th:nth-child(3),
        td:nth-child(3) {
          width: 15%;
          text-align: left;
        }

        th:nth-child(4),
        td:nth-child(4) {
          width: 30%;
        }

        td:nth-child(1) {
          .tx-title {
            white-space: pre-wrap;
            @include setFont('IBM Plex Sans', 400, 0.938rem, 2rem);
            color: $txt_clr8;

            svg {
              min-height: 12px;
              min-width: 12px;
              margin-left: 6px;
            }

            .tr-id {
              max-width: 100px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: inline-block;
              vertical-align: bottom;
            }
          }

          .start-date {
            @include setFont('IBM Plex Sans', 400, 0.813rem, 1.188rem);
            color: $txt_clr3;
          }
        }

        td:nth-child(2) {
          p:first-child {
            @include setFont('IBM Plex Sans', 500, 1.063rem, 1.563rem);
          }

          .stake {
            color: $txt_clr10;
          }

          .unstake {
            color: #eb5757;
          }
        }

        td:nth-child(3) {
          @include setFont('IBM Plex Sans', 400, 0.938rem, 1.375rem);
          color: $txt_clr3;
        }

        td:nth-child(4) {
          @include setFont('IBM Plex Sans', 500, 1.063rem, 1.563rem);
          color: $txt_clr4;

          span:nth-child(2) {
            opacity: 0.5;
          }
        }
      }
    }

    .no-transactions {
      @include setFont('IBM Plex Sans', 500, 1.563rem, 1.563rem);
      text-align: center;
      margin-top: 20px;
    }
  }
}

@media only screen and (max-width: $mobileScreen) {
  .cru-block {
    .page-title {
      margin: 0 0 0 24px;

      > svg {
        display: inline;
      }

      > span {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .back-btn {
      display: none;
    }

    .staking-update {
      order: -1;
      padding: 16px 10px 12px 7px;
      border-radius: initial;

      .update-info-text {
        display: none;
      }

      .update-title svg {
        margin-right: 6px;
      }

      .update-title .title-count {
        flex-direction: column-reverse;
        align-items: initial;
        white-space: nowrap;

        .staking-title {
          align-items: initial;

          .subtitle {
            display: none;
          }
        }
      }
    }

    .wallet-content {
      .staking-input-block {
        flex-direction: column;
        margin-bottom: 38px;

        .input-title {
          padding: 0;

          .mobile-view {
            display: flex;
            flex-direction: column;
            margin: 15px 0 10px 0;

            > span {
              @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
              // margin-left: 6px;
              text-decoration: underline;
            }
          }
        }

        .input-row-block {
          min-width: 100%;
          max-width: 100%;

          .additional-info {
            .balance-row {
              display: none;
            }

            .btn-block {
              margin: 13px 0 0 auto;
            }
          }
        }
      }

      .staking-plan-block {
        flex-direction: column;

        .dropdown-description {
          padding: 0;

          > :first-child {
            margin-bottom: 13px;
          }

          > :last-child {
            display: none;
          }
        }

        .plan-select {
          min-width: 100%;
          max-width: 100%;

          .plan-description {
            flex-direction: column;

            .mobile {
              display: inline;
            }

            .duration,
            .reward,
            .time-period {
              margin: 0;
              flex-direction: row;

              > :first-child {
                display: none;
              }
            }

            .duration {
              > :last-child {
                margin-left: 13px;
              }
            }

            .reward {
              > :last-child {
                margin-left: 20px;
              }
            }

            .time-period {
              > :last-child {
                margin-left: 28px;
              }
            }
          }

          .description-text {
            display: none;
          }
        }
      }
    }
  }
}
