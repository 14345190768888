@import '../../../variables';

.producers-page {
  .title-page {
    text-transform: none;
    margin: 0;
  }

  .nav-bar {
    margin-bottom: 30px;

    .MuiTab-root {
      text-transform: none;
      min-width: 100px;
      padding: 10px 35px;
    }
  }

  .table-wrapper {
    flex-direction: row;
    @media only screen and (min-width: 1152px) {
      overflow: hidden;
    }

    .table-content {
      width: auto;

      td,
      th {
        padding: 10px 30px 10px 0;
        text-align: left !important;
      }

      td:first-child {
        color: $txt_clr5;
      }
    }
  }

  .producers-table {
    th {
      white-space: nowrap;
    }

    .status-icon {
      display: block;
      height: 30px;
    }
    .link-btn {
      @include setFont('IBM Plex Sans', 500, 0.9375rem, 1.375rem);
      display: flex;
      align-items: center;
      justify-content: center;
      color: $txt_clr0;
      padding: 8px 10px;
      border-radius: 10px;
      background-color: $bg_clr6;
    }
  }

  .candidates-table {
    .status-icon {
      display: block;
      height: 30px;
    }
  }
}
