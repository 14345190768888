@import '../../../variables';

.wallet-ram {
  min-height: 792px;
  position: relative;

  .wallet-ram-block {
    .wallet-content {
      padding: 0;

      .top-content {
        padding: 25px 30px;
        border-bottom: 1px solid $brdr_clr1;

        .token-info-block {
          margin-bottom: 5px;

          .count-subtitle {
            color: $txt_clr3;
          }
        }
      }

      .center-content {
        display: flex;
        flex-direction: column;
        padding: 30px 25px 25px 30px;
        border-bottom: 1px solid $brdr_clr1;

        .title-page {
          margin-bottom: 26px;
        }
      }

      .bottom-content {
        border-bottom: 1px solid $brdr_clr1;
        padding: 30px;

        .info-blocks {
          display: flex;
          align-items: flex-start;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 30px;
          margin-top: 25px;

          .info-block {
            display: flex;
            align-items: center;
            white-space: nowrap;
            .number-converter {
              @include setFont('IBM Plex Sans', 400, 0.9375rem, 167%);
              color: $txt_clr3;
            }
          }
        }
      }

      .input-with-btn {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        > div {
          width: 100%;
        }
        .number-converter {
          @include setFont('IBM Plex Sans', 400, 0.9375rem, 150%);
          color: $txt_clr3;
        }

        .default-label {
          height: 64px;
          //width: 347px;
          width: 100%;
          background-color: $bg_clr2;
          border-radius: 8px;
          color: $txt_clr4;
          @include setFont('IBM Plex Sans', 400, 1.0625rem, 1.5625rem);
          padding: 19px 16px;
          margin-bottom: 15px;

          .label-input {
            @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
            opacity: 0.75;
          }

          .input-container input {
            @include setFont('IBM Plex Sans', 400, 1.0625rem, 1.5625rem);
            color: $txt_clr4;
            background-color: $bg_clr2;

            &::placeholder {
              opacity: 0.5;
            }
          }

          &.filled-in {
            padding-top: 9px;
            padding-bottom: 9px;
          }
        }

        .with-bg {
          min-height: 63px;
          //max-width: 355px;
          margin-left: 20px;
          width: 100%;
        }
      }
    }
  }

  .msg-printer-view {
    text-align: center;
    padding-right: 30px;
    padding-left: 30px;
  }

  @media only screen and (max-width: $mobileScreen) {
    height: 100%;
    min-height: initial;

    .wallet-ram-block .wallet-content {
      .title-page {
        display: none;
      }

      .top-content {
        border: initial;
      }

      .bottom-content,
      .center-content {
        padding: 30px 17px;
        margin-bottom: 20px;
      }
    }
    .wallet-ram-block .wallet-content .input-with-btn {
      flex-direction: column;

      .default-label {
        width: 100%;
      }

      .click-btn.with-bg {
        margin-left: 0;
        margin-top: 20px;
        width: 100%;
      }
    }
    .wallet-ram-block .wallet-content .bottom-content .info-blocks,
    .wallet-ram-block .wallet-content .center-content.hide-mobile-form,
    .bottom-content.hide-mobile-form {
      display: none;
    }
  }
}
