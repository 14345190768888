@import '../../../variables';

@media only screen and (min-width: $desktopScreen) {
  .block-header .link-title:hover {
    color: $txt_clr6;
    text-decoration: none;
    transition: color 200ms;
    -webkit-transition: color 200ms;
  }
}

.block-header {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  .link-title {
    @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
    text-decoration: underline;
  }
}
