@import '../../../variables';

@media only screen and (min-width: $desktopScreen) {
  .trx-row-container:hover {
    box-shadow: $shadowPart_2 rgba(119, 119, 119, 0.05), $shadowPart_3 rgba(119, 119, 119, 0.05),
      $shadowPart_4 rgba(119, 119, 119, 0.05), $shadowPart_5 rgba(119, 119, 119, 0.05);
    transition: box-shadow 200ms;
    -webkit-transition: box-shadow 200ms;
  }
}

.trx-row-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 15px 18px 17px 18px;
  border-bottom: 1px solid $brdr_clr1;
  transition: box-shadow 200ms;
  -webkit-transition: box-shadow 200ms;
  cursor: pointer;

  .row-icon {
    width: 36px;
    min-width: 36px;
    height: 36px;
    min-height: 36px;
    margin-right: 14px;

    > svg {
      max-width: 36px;
      max-height: 36px;
    }
  }

  .title-row {
    @include setFont('IBM Plex Sans', 500, 1.0625rem, 1.5625rem);
    color: $txt_clr4;

    .data-row {
      display: flex;
      align-items: center;
      flex-direction: row;
      white-space: pre-wrap;
    }

    .time-in-hours,
    .data-row {
      @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
      color: $txt_clr3;
    }
  }
}
