@import '../../../variables';

.wallet-send {
  .msg-printer-view {
    width: 556px;
  }

  .transfer-block {
    .wallet-content {
      align-items: flex-end;
      //max-width: 780px;

      .inputs-block {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        position: relative;
        padding-bottom: 30px;
        margin-bottom: 30px;

        .input-send {
          display: flex;
          //align-items: center;
          flex-direction: row;
          justify-content: space-between;

          .label-block {
            padding-top: 21px;
          }

          .input-block {
            .default-label {
              border: 3px solid $brdr_clr2;
              border-radius: 8px;
              //height: 84px;
              width: 307px;
              margin-bottom: 16px;
              padding: 10px 20px;

              &:after {
                background-color: transparent;
              }

              .label-input {
                @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
                color: $txt_clr3;
              }

              input {
                @include setFont('IBM Plex Sans', 500, 1.75rem, 2.35rem);
                color: $txt_clr4;
                background-color: $bg_clr0;
                height: initial;
                padding: initial;

                &::placeholder {
                  color: $txt_clr4;
                  opacity: 1;
                }
              }
            }

            .balance-row {
              position: absolute;

              .link-see-all {
                color: $txt_clr4;
                text-decoration: underline;
                cursor: pointer;
                margin-left: 6px;
              }
            }
          }
        }

        .custom-select {
          padding-left: 6px;
        }
      }

      .input-row-block .title-row {
        @include setFont('IBM Plex Sans', 500, 1.0625rem, 1.5625rem);
      }

      .input-transfer {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        //flex-wrap: wrap;
        margin-bottom: 30px;
        width: 100%;

        .label-block {
          display: flex;
          flex-direction: column;
          margin-right: 25px;

          .subtitle-row {
            @include setFont('IBM Plex Sans', 400, 0.8125rem, 1.1875rem);
          }
        }

        .input-block {
          .default-label {
            height: 64px;
            background-color: $bg_clr2;
            border-radius: 8px;
            padding: 19px 16px;
            color: $txt_clr4;
            width: 555px;
            margin-left: auto;
            margin-bottom: 4px;
            @include setFont('IBM Plex Sans', 400, 1.0625rem, 1.5625rem);

            .input-container input {
              background-color: $bg_clr2;

              &::placeholder {
                opacity: 0.5;
              }
            }
          }

          .subtitle-input {
            @include setFont('IBM Plex Sans', 400, 0.8125rem, 1.1875rem);
            padding-left: 16px;

            &.change-color {
              color: $txt_clr5;
            }
          }
        }
      }
    }
  }

  .transfer-block .wallet-content .click-btn {
    @extend .auth-btn;
    max-width: 556px;
    min-height: 63px;
  }

  @media only screen and (max-width: $mobileScreen) {
    .transfer-block .wallet-content .input-transfer {
      flex-direction: column;
      align-items: initial;
    }
    .transfer-block .wallet-content .input-transfer .input-block .default-label {
      margin-left: initial;
      width: 100%;
    }
    .transfer-block .wallet-content .input-transfer .label-block {
      margin-bottom: 4px;

      .subtitle-row {
        display: none;
      }
    }
    .transfer-block .wallet-content .input-transfer .input-block .subtitle-input {
      display: none;
    }
    .transfer-block .wallet-content .msg-printer-view {
      width: initial;
      padding-left: 17px;
    }
    .transfer-block .wallet-content .inputs-block {
      align-items: flex-end;

      .input-row-block.input-send {
        flex-direction: column;

        .label-block {
          padding-top: 0;
        }

        .input-block .default-label {
          max-width: 307px;
          width: 100%;
          margin-bottom: initial;
        }

        .input-block .balance-row {
          bottom: -5px;
        }
      }

      .react-select-container {
        max-width: 242px;
        max-height: 72px;
        width: 100%;
        margin-bottom: 15px;

        .react-select__control {
          width: 100%;

          .react-select__value-container .select-row {
            .token-icon {
              min-height: 28px;
              min-width: 28px;
              max-width: 28px;
              max-height: 28px;
              margin-right: 8px;
            }

            .label-row .subtitle {
              display: none;
            }
          }
        }
      }
    }
  }
}
