@import '../../../variables';

@media only screen and (min-width: $desktopScreen) {
  .wallet-auth-page .auth-content .cheers-btn:hover {
    opacity: 1;
    transition: opacity 200ms;
    -webkit-transition: opacity 200ms;

    span {
      opacity: 1;
    }
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.wallet-auth-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-grow: 1;
  padding-bottom: 100px;

  .auth-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid $brdr_clr1;
    box-shadow: 0px 4px 4px rgba(119, 119, 119, 0.05);
    border-radius: 9px;
    max-width: 694px;
    padding: 60px 100px 30px;
    margin-top: 100px;
    position: relative;

    .pin-form {
      display: flex;
      flex-direction: column;
      align-items: center;

      .default-label.filled-in.show-status-border.error-state:after {
        background-color: red;
      }
    }

    .auth-icon {
      width: 100px;
      height: 100px;
      margin-right: 10px;
    }

    .auth-title {
      @include setFont('IBM Plex Sans', 400, 1.3125rem, 1.9375rem);
      text-align: center;
      color: $txt_clr9;
      padding-top: 30px;
      padding-bottom: 40px;

      &.safety {
        display: flex;
        flex-direction: column;
        padding-top: 10px;
        padding-bottom: 30px;

        span:first-child {
          @include setFont('IBM Plex Sans', 500, 1.313rem, 1.938rem);
          margin-bottom: 12px;
        }

        span:last-child {
          @include setFont('IBM Plex Sans', 400, 0.938rem, 1.375rem);
        }
      }
    }

    .input-list {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;

      .label-input {
        @include setFont('IBM Plex Sans', 400, 0.938rem, 1.375rem);
        color: $txt_clr4;
        opacity: 0.75;

        &:last-of-type {
          margin-top: 20px;
        }
      }

      .pin-field {
        @extend .input-list;

        &:first-child {
          margin-bottom: 25px;
        }
      }

      .default-label {
        background: $bg_clr2;
        border-radius: 8px;
        max-height: 64px;
        max-width: 124px;
        @include setFont('IBM Plex Sans', 400, 1.3125rem, 1.9375rem);
        color: $txt_clr4;
        opacity: 1;
        width: 100%;
        padding: 16px;
        margin-top: 10px;

        input {
          @include setFont('IBM Plex Sans', 400, 1 1.3125rem, 1.9375rem);
          background-color: $bg_clr2;
          color: $txt_clr4;
          opacity: 1;
          text-align: center;

          &::placeholder {
            color: $txt_clr4;
          }
        }
      }
    }

    .filled-in {
      input {
        opacity: 1;
        background-color: $bg_clr2;
      }
    }

    .with-bg {
      margin-bottom: 20px;
      width: 100%;
      max-width: 409px;
      margin-top: 45px;
      opacity: 1;

      .title-btn {
        opacity: 1;
      }

      &:disabled {
        opacity: 0.7;
      }
    }

    .input-block {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      max-width: 409px;

      .auth-btn {
        margin-top: 20px;
        margin-bottom: 24px;
        opacity: 1;

        .title-btn {
          opacity: 1;
        }

        &:disabled {
          opacity: 0.7;
        }
      }

      .filled-in {
        input {
          opacity: 1;
          background-color: $bg_clr2;
        }
      }
    }

    .link-lost-key {
      @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
      color: $txt_clr5;
      text-align: center;
      padding-top: 30px;
      position: absolute;
      bottom: -50px;
      white-space: nowrap;
    }
  }

  @media only screen and (max-width: $mobileScreen) {
    align-items: initial;
    padding-bottom: 0;
    flex-grow: initial;
    .auth-content {
      margin-top: 0;
      padding: 25px 10px 16px 10px;
      box-shadow: initial;
      border: initial;

      .auth-title {
        margin-right: 25px;
        margin-left: 25px;
        max-width: 300px;

        &.safety {
          padding-bottom: 15px;
          padding-top: 0;
        }
      }

      .pin-form {
        .click-btn.with-bg.blue {
          margin-top: 25px;
        }
      }
    }

    .auth-content .input-list .default-label.pin-field {
      max-width: 126px;
      padding: 10px;
    }
  }
}
