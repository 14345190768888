@import '../../../variables';

@keyframes slideLeft {
  0% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(0%);
  }
}
.mobile-modal-overlay-wallet {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 80px;
  left: 0;
  z-index: 100;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open.mobile-modal-overlay-wallet {
  background-color: initial !important;
}

.modal-menu-wallet {
  display: flex;
  flex-direction: row-reverse;
  height: 100%;
  animation: slideLeft 0.4s ease-in-out;

  .modal-menu-content {
    height: 100%;
    width: 100%;
    min-width: 270px;
    background: $bg_clr9;
    padding-top: 11px;

    .header-modal {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      @include setFont('IBM Plex Sans', 400, 0.938rem, 1.25rem);
      color: rgba(245, 246, 247, 0.5);
      padding-top: 15px;
      padding-left: 14px;
      margin-right: 12px;

      span {
        margin-right: 10px;
      }

      .btn-menu-wrapper {
        .menu-btn div {
          background-color: $bg_clr0;
        }
      }
    }

    .nav-bar {
      flex-direction: column;
      align-items: flex-start;
      padding-top: 14px;

      .nav-item:not(.disable) {
        width: 100%;
      }

      .nav-item {
        height: initial;
        padding: 11px 0 11px 21px;
        margin-left: 2px;
        border-bottom: none;
        border-right: 4px solid transparent;

        &.disable {
          &:before {
            top: 0;
          }
        }
      }

      .nav-active {
        background-color: rgba(255, 255, 255, 0.05);
        border-right-color: $brdr_clr0;

        span {
          padding-top: 0;
          opacity: 1;
        }
      }
    }
  }

  .click-block {
    width: 100%;
  }
}
