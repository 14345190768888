@import '../../../variables';

.empty-result {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-top: 60px;
  svg {
    max-width: 142px;
    max-height: 142px;
  }

  .result-txt {
    text-align: center;
    padding: 40px 15px 15px;

    .sorry-txt,
    .search-value {
      @include setFont('IBM Plex Sans', 600, 1.3125rem, 2.1875rem);
      color: $txt_clr4;
      padding-bottom: 24px;
      word-break: break-word;
    }
    .search-value {
      padding-left: 6px;
    }

    .subtitle {
      @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
      color: $txt_clr3;
    }
  }
}
