@import '../../../variables';

@media only screen and (min-width: $desktopScreen) {
  .wallet-header .nav-bar .nav-item:hover {
    transition: border-color 200ms;
    -webkit-transition: border-color 200ms;

    span {
      opacity: 0.5;
      transition: opacity 200ms;
      -webkit-transition: opacity 200ms;
    }
  }
}

.wallet-header,
.modal-menu-wallet {
  .nav-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 43px;
    width: 100%;

    .nav-item {
      @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
      color: $txt_clr0;
      margin-right: 32px;
      padding-bottom: 17px;
      transition: opacity 200ms;
      -webkit-transition: opacity 200ms;
      border-bottom: 3px solid transparent;
      white-space: nowrap;

      &:last-of-type {
        margin-right: 0;
      }
    }

    .nav-active {
      border-color: $brdr_clr0;

      span {
        opacity: 0.5;
      }
    }

    .logout-btn {
      margin-left: 32px;
      cursor: pointer;
    }
  }
}

.wallet-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  //height: 100%;
  background: $bg_clr9;
  min-height: 227px;
  padding-top: 37px;
  margin-bottom: 50px;
  //overflow: auto;

  .header-content {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;

    .user-info-block {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-right: 100px;

      .qr-code {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $bg_clr0;
        width: 80px;
        height: 80px;
        margin-right: 24px;

        svg {
          width: 70px;
          height: 70px;
        }
      }

      .name-date {
        .name-row {
          display: flex;
          align-items: center;
          flex-direction: row;
          @include setFont('IBM Plex Sans', 500, 1.75rem, 2.9375rem);
          color: $txt_clr0;

          .copy-icon {
            background-image: url('../../../img/icon-copy.svg');
            display: inline-block;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            min-width: 24px;
            min-height: 24px;
            margin-left: 8px;
            cursor: pointer;
          }
        }

        .date-row {
          @include setFont('IBM Plex Sans', 400, 0.8125rem, 1.1875rem);
          color: $txt_clr3;
        }

        .sign-out-btn {
          display: none;
          @include setFont('IBM Plex Sans', 500, 1.063rem, 1.563rem);
          color: $txt_clr0;
          cursor: pointer;
        }
      }
    }

    .resources-block {
      display: flex;
      align-items: center;
      flex-direction: row;

      .resources-stat {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 30px;

        .statistic-content {
          display: flex;
          flex-direction: column;
          @include setFont('IBM Plex Sans', 400, 0.75rem, 1.125rem);
          color: $txt_clr0;
          white-space: nowrap;
          margin-left: 13px;

          .link-title {
            color: $txt_clr3;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .dropdown {
    transition: opacity 200ms;
    -webkit-transition: opacity 200ms;
    cursor: pointer;
    position: relative;

    .dots-icon {
      padding-left: 31px;
      height: 100%;
      padding-bottom: 17px;
      padding-right: 24px;
      transition: opacity 200ms;
      -webkit-transition: opacity 200ms;
    }

    &:hover .dots-icon,
    .nav-item:not(.nav-active):hover {
      opacity: 0.5;
      transition: opacity 200ms;
      -webkit-transition: opacity 200ms;
    }

    .nav-item.logout-btn {
      margin-left: initial;
    }

    .dropdown-content {
      display: none;
      visibility: hidden;
      opacity: 0;
      flex-direction: column;
      position: absolute;
      top: 30px;
      right: -7px;
      min-width: 220px;
      background-color: $bg_clr9;
      z-index: 199;
      transition: all 0.5s ease;
      padding-top: 20px;

      .nav-item {
        color: $txt_clr0;
        margin-right: initial;
        padding-left: 20px;
      }

      .nav-item.nav-active {
        border-bottom: initial;
      }
    }

    &:hover .dropdown-content {
      display: flex;
      visibility: visible;
      opacity: 1;
      transition: all 0.5s ease;
    }
  }

  .btn-menu-wrapper {
    //display: none;
    .menu-btn div {
      background-color: $bg_clr0;
    }
  }

  .mobile-header {
    display: none;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 13px;
    padding-bottom: 13px;

    .user-dropdown {
      display: flex;
      align-items: center;
      background: $bg_clr3;
      border-radius: 8px;
      padding: 6px 10px 6px 17px;

      span {
        @include setFont('IBM Plex Sans', 500, 1.063rem, 1.563rem);
        color: $txt_clr9;
        margin-right: 5px;
      }

      .arrow-icon {
        background-image: url('../../../img/arrow.svg');
        background-repeat: no-repeat;
        background-size: auto;
        background-position: center;
        transform: rotate(90deg);
        width: 7px;
        height: 10px;
        padding: 15px;
      }
    }

    .menu-block {
      display: flex;
      align-items: center;

      span {
        @include setFont('IBM Plex Sans', 400, 0.938rem, 1.25rem);
        color: $txt_clr1;
        opacity: 0.5;
        margin-right: 6px;
      }
    }
  }

  .nav-bar-wrapper {
    display: flex;
    .menu-block {
      display: none;
    }
  }

  @media only screen and (min-width: $mobileScreen) and (max-width: 1650px) {
    .nav-bar-wrapper {
      justify-content: space-between;
      padding: 0 30px;
      width: 100%;

      .nav-bar {
        justify-content: flex-start;
        overflow: hidden;
        width: calc(100% - 70px);
      }
      .menu-block {
        display: flex;
      }
    }
  }

  @media only screen and (max-width: $mobileScreen) {
    padding-left: 17px;
    padding-right: 17px;
    padding-top: initial;
    flex-direction: column;
    min-height: 64px;
    margin-bottom: 0;

    .nav-bar-wrapper {
      display: none;
    }

    .header-content {
      justify-content: initial;
      padding-top: 18px;

      .user-info-block {
        width: 100%;
        margin-right: initial;
        align-items: initial;
      }
    }
    .header-content .user-info-block .qr-code {
      width: 72px;
      height: 72px;
    }
    .header-content .user-info-block .name-date .sign-out-btn {
      display: block;
    }
    .hide-mobile-status {
      display: none;
    }

    .header-content .user-info-block .name-date {
      align-items: initial !important;
      flex-direction: column !important;
    }

    .header-content .user-info-block .name-date,
    .date-row {
      display: flex;
      flex-direction: row;
      white-space: nowrap;
      align-items: center;
      @include setFont('IBM Plex Sans', 400, 0.813rem, 1.188rem);
    }
    .header-content .user-info-block .name-date .name-row .copy-icon {
      min-width: 17px;
      min-height: 17px;
    }
    .header-content .user-info-block .name-date .name-row .user-name {
      @include setFont('IBM Plex Sans', 500, 1.063rem, 1.563rem);
    }
    .header-content .resources-block {
      flex-direction: column;
      align-items: initial;
      width: 100%;
      padding-top: 10px;

      .resources-stat {
        padding-bottom: 12px;
        border-bottom: 1px solid rgba(245, 246, 247, 0.2);
        margin-right: 0;
        padding-top: 16px;

        .statistic-content {
          width: 100%;
          position: relative;

          .data-row span:first-child {
            font-weight: bold;
          }

          .link-title {
            position: absolute;
            right: 0;
            top: 10px;
          }
        }
      }
    }
    .mobile-header {
      display: flex;
    }
  }

  @media only screen and (max-width: $tabletScreen) {
    .header-content .user-info-block {
      margin-right: 10px;
    }
  }

  @media only screen and (max-width: 927px) {
    padding-top: 10px;
    .header-content {
      justify-content: initial;
      max-width: 765px;
      margin: auto;

      .user-info-block {
        padding-bottom: 10px;
      }
    }
  }
}

@media only screen and (max-width: $mobileScreen) {
  .wallet-header .dropdown,
  .dots-icon,
  .dropdown-content {
    // display: none;
  }
  .dots-icon {
    display: none;
  }
}
