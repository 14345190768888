@import '../../../variables';

.lines-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 40px;

  .line-header {
    display: flex;
    align-items: center;
    flex-direction: row;
    @include setFont('IBM Plex Sans', 600, 1.3125rem, 1.6875rem);
    color: #1d1f23;
    margin-bottom: 12px;

    .count-info {
      display: flex;
      align-items: center;
      flex-direction: row;
      white-space: pre-wrap;

      .count-line {
        @include setFont('IBM Plex Sans', 400, 1.0625rem, 1.375rem);
        color: $txt_clr3;
      }
    }
  }

  .lines-container {
    height: 4px;
    width: 100%;
    max-width: 382px;
    position: relative;

    .inactive-line {
      background-color: #dee2e7;
      height: 100%;
      width: 100%;
      position: relative;
    }

    .active-line {
      background-color: #13c41a;
      height: 100%;
      position: absolute;
      top: 0;
      transition: width 0.8s;
      -webkit-transition: width 0.8s;
    }
  }
}
