@import '../../../variables';

@keyframes show-modal {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.modal-rewards {
  position: relative;
  top: 50px;
  z-index: 10;
  animation: show-modal 0.8s;
  background-color: $bg_clr0;
  border: 1px solid $brdr_clr1;
  color: $txt_clr4;
  box-shadow: 0px 32px 32px rgba(119, 119, 119, 0.05), 0px 16px 16px rgba(119, 119, 119, 0.05), 0px 8px 8px rgba(119, 119, 119, 0.05), 0px 4px 4px rgba(119, 119, 119, 0.05);
  border-radius: 8px;
  max-width: 570px;
  width: 100%;
  max-height: 644px;
  margin: auto;
  padding-bottom: 35px;
  @media only screen and (max-width: $mobileScreen) {
    top: 0;
    height: 100%;
    max-height: initial;
  }

  .header-modal {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    border-bottom: 2px solid $brdr_clr1;
    padding: 20px;
    padding-right: 35px;
    padding-top: 27px;
    position: relative;
    @media only screen and (max-width: $mobileScreen) {
      flex-direction: column;
    }
    .title-modal {
      @include setFont('IBM Plex Sans', 600, 1.5rem, 2.5rem);
    }
    .info-row {
      display: flex;
      align-items: center;
      @include setFont('IBM Plex Sans', 400, 1.063rem, 1.563rem);
      .count-token {
        padding-left: 7px;
        padding-right: 5px;
      }
      .time {
        padding-left: 7px;
      }
    }
    .right-header {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      @media only screen and (max-width: $mobileScreen) {
        align-items: initial;
      }
      .info-row:nth-child(1) {
        margin-bottom: 2px;
      }
    }
    svg {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 22px;
      height: 22px;
      cursor: pointer;
    }
  }
  .body-modal {
    padding: 18px 20px 0px 20px;
    @media only screen and (max-width: $mobileScreen) {
      padding-left: 0px;
      padding-right: 0px;
    }
    .header-table {
      @include setFont('IBM Plex Sans', 500, 1.063rem, 1.563rem);
      @media only screen and (max-width: $mobileScreen) {
        padding-left: 18px;
      }
    }
    .table-wrapper {
      margin-top: 17px;
      table {
        width: 100%;
        border-bottom: 1px solid $brdr_clr14;
      }

      thead,
      tbody,
      tr {
        display: table;
        width: 100%;
        table-layout: fixed;
      }

      tbody {
        display: block;
        overflow: auto;
        table-layout: fixed;
        max-height: 330px;
        @media only screen and (max-width: $mobileScreen) {
          max-height: 360px;
        }
        @media only screen and (max-width: 340px) {
          max-height: 280px;
        }
      }
      .header-table {
        overflow-x: auto;
        margin-bottom: 0;
        th {
          white-space: nowrap;
        }
        th:nth-child(1) {
          width: 30%;
        }
        th:nth-child(2) {
          width: 15%;
        }
        th:nth-child(3) {
          width: 15%;
        }
        th:nth-child(4) {
          width: 20%;
        }
      }
      .body-table {
        tr {
          td {
            color: $txt_clr4;
            @include setFont('IBM Plex Sans', 400, 0.938rem, 1.375rem);
          }
          td:nth-child(1) {
            width: 30%;
          }
          td:nth-child(2) {
            width: 15%;
          }
          td:nth-child(3) {
            width: 15%;
          }
          td:nth-child(4) {
            width: 20%;
          }
        }
      }
      .table-content .body-table .body-row {
        border-bottom: initial;
      }
    }
  }
}
